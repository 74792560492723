import React, { useEffect, useRef, useState } from "react";
import { DataTable, InputField } from "../../components";
import useApi from "../../utils/useApi";
import dayjs from "dayjs";
import {
  Edit,
  GalleryHorizontalEnd,
  PlusCircle,
  SquarePen,
  Trash,
} from "lucide-react";
import usePermissions from "../../utils/userPermission";
import toast, { Toaster } from "react-hot-toast";

export default function HostingProvider() {
  const [hostingProvider, setHostingProvider] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const { isLoading, error, request } = useApi();
  const dropdownRef = useRef(null);
  const deleteRef = useRef({});
  const updateRef = useRef({});

  const getHostingProvider = async () => {
    try {
      const data = await request({
        method: "get",
        url: "masters/hosting_provider",
      });
      setHostingProvider(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getHostingProvider();
  }, []);

  const addHostingProvider = async (e) => {
    e.preventDefault();
    let value = e.currentTarget.input.value;
    try {
      const res = await request({
        method: "post",
        url: "masters/hosting_provider",
        data: { name: value },
      });
      if (res.status) {
        updateRecord(res.data);
        toast.success(`${value} hosting provider added.`);
        setDropdown(false);
      } else {
        toast.error(
          error || res?.message || `${value} provider coludn't be added.`
        );
      }
    } catch (err) {
      console.log("🚀 ~ addHostingProvider ~ err:", err);
      toast.error(
        err?.response?.data?.message || `${value} provider coludn't be added.`
      );
      console.error(err);
    }
  };

  const [deleteInd, setDelete] = useState(null);
  const deleteHostingProvider = async (provider) => {
    try {
      const res = await request({
        method: "delete",
        url: `masters/hosting_provider/${provider._id}`,
      });
      if (res.status) {
        updateRecord(res.data);
        toast.success(`${provider.name} hosting provider removed.`);
        setDelete(null);
      } else {
        toast.error(error || `${provider.name} provider coludn't be removed.`);
      }
    } catch (err) {
      toast.error(error || `${provider.name} provider coludn't be removed.`);
      console.error(err);
    }
  };
  const updateRecord = (newRecord) => {
    const newData = [...hostingProvider];
    const index = newData.findIndex((item) => item._id === newRecord._id);
    if (index !== -1 && !newRecord.deleted) {
      newData[index] = newRecord;
    } else if (index !== -1 && newRecord.deleted) {
      newData.splice(index, 1);
    } else {
      newData.push(newRecord);
    }
    setHostingProvider(newData);
  };

  const [updateIndex, setUpdate] = useState(null);
  const updateHostingProvider = async (e, provider) => {
    e.preventDefault();
    const newValue = e.target.elements.update.value;
    try {
      const res = await request({
        method: "put",
        url: `masters/hosting_provider/${provider._id}`,
        data: { name: newValue },
      });
      if (res.status) {
        updateRecord(res.data);
        toast.success(`${provider.name} hosting provider updated.`);
        setUpdate(null);
      } else {
        toast.error(
          error ||
            res?.message ||
            `${provider.name} provider coludn't be updated.`
        );
      }
      // getHostingProvider();
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          `${provider.name} provider coludn't be updated.`
      );
      console.error(err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setDelete(null);
        }
      });
      Object.entries(updateRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setUpdate(null);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const form = updateRef.current[updateIndex];
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [updateIndex]);

  const { hasPermission } = usePermissions();

  return (
    hasPermission("Hosting Provider") && (
      <div>
        <Toaster />

        <DataTable
          searchBox
          title={
            <span className="flex items-center gap-2">
              <GalleryHorizontalEnd className="w-6 h-6" />
              Hosting Provider
            </span>
          }
          isLoading={isLoading}
          heads={[
            "Sr#",
            ...(hasPermission("Edit Hosting Provider") ||
            hasPermission("Delete Hosting Provider")
              ? ["Actions"]
              : []),
            "Name",
            "Created At",
            "Updated At",
          ]}
          items={hostingProvider?.map((provider, index) => {
            const actions = (
              <div className="flex items-center gap-2">
                {hasPermission("Delete Hosting Provider") && (
                  <div className="relative">
                    <Trash
                      onClick={() => setDelete(index)}
                      className="deleteIcon"
                    />
                    {deleteInd === index && (
                      <div
                        ref={(el) => (deleteRef.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
                      >
                        <p className="font-medium">
                          Please confirm to delete {provider.name} hosting
                          provider
                        </p>
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setDelete(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => deleteHostingProvider(provider)}
                            className="btnPrimary bg-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {hasPermission("Edit Hosting Provider") && (
                  <div className="relative">
                    <Edit
                      onClick={() => setUpdate(index)}
                      className="editIcon"
                    />
                    {updateIndex === index && (
                      <form
                        ref={(el) => (updateRef.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-xl border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50 w-80"
                        onSubmit={(e) => updateHostingProvider(e, provider)}
                      >
                        <InputField
                          name="update"
                          label="Hosting Provider"
                          placeholder="Update provider name"
                          defaultValue={provider.name}
                        />
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setUpdate(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btnPrimary bg-black">
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>
            );

            const item = {
              serial: index + 1,
              ...(hasPermission("Edit Hosting Provider") ||
              hasPermission("Delete Hosting Provider")
                ? { actions }
                : {}),
              name: provider.name,
              createdAt: dayjs(provider.createdAt)?.format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              updatedAt: dayjs(provider.updatedAt)?.format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };

            return item;
          })}
          extras={
            hasPermission("Add Hosting Provider") && (
              <div className="relative text-black dark:text-white/80">
                {dropdown && (
                  <form
                    onSubmit={addHostingProvider}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
                  >
                    <InputField
                      name="input"
                      label="Provider Name"
                      placeholder="Enter new provider name"
                    />
                    <div className="flex items-center justify-end mt-3">
                      <button type="submit" className="btnPrimary bg-black">
                        Add
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdown(!dropdown)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add Hosting Provider
                </button>
              </div>
            )
          }
        />
      </div>
    )
  );
}
