import { Camera, X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { ComboBox, InputField, ListBox } from "../../components";

export default function AddUpdateTemplate({
  sidebar,
  toggleSidebar,
  industries,
  industry,
  setIndustry,
  tempType,
  setTempType,
  tempTypes,
  fileInfo,
  handleFileChange,
  repo_url,
  setRepoURL,
  addTemplate,
  port,
  setPort,
  template_name,
  setTemplateName,
  modalType,
  updateTemplate,
  active,
  setActive,
  activeStatus,
}) {
  const addFromRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addFromRef.current && !addFromRef.current.contains(event.target)) {
        if (sidebar) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar, toggleSidebar]);

  return (
    <form
      onSubmit={
        (modalType === "new" && addTemplate) ||
        (modalType === "edit" && updateTemplate)
      }
      ref={addFromRef}
      className={`flex flex-col gap-5 w-[600px] h-screen z-50 fixed right-0 top-0 p-5 bg-white dark:bg-gray-800 capitalize ${
        sidebar && "shadow-l shadow-black/20 dark:shadow-gray-600/40"
      }`}
      style={{ transform: sidebar ? "translateX(0)" : "translateX(100%)" }}
    >
      <div className="flex items-center justify-between">
        <h3>
          {modalType === "new" ? "Add New Template" : "Update Template Info"}
        </h3>
        <X
          className="w-6 text-red-500 dark:text-red-400 cursor-pointer"
          onClick={toggleSidebar}
        />
      </div>
      <div className="overflow-hidden relative h-48 w-full rounded-md bg-gray-300 dark:bg-gray-500 border border-white/20 flex items-center justify-end">
        <input
          type="file"
          onChange={handleFileChange}
          id="imageUpload"
          className="hidden"
          accept="image/*"
        />
        <label
          htmlFor="imageUpload"
          className="btnWhite py-1 px-3 cursor-pointer flex items-center gap-2 z-50 m-2 absolute right-0 top-0 h-fit"
        >
          Add Thumbnail
          <Camera className="w-4" />
        </label>
        <img
          title="Template Cover"
          src={fileInfo?.preview}
          loading="lazy"
          alt="No Thumbnail Yet"
          className={`w-full h-full object-cover absolute top-0 ${
            fileInfo ? "scale-100" : "scale-125"
          }`}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <InputField
          name="input"
          label="template Name"
          placeholder="Enter new template name"
          value={template_name}
          onChange={(e) => setTemplateName(e.target.value)}
        />
        <ListBox
          label="Template Category"
          name="industry"
          options={industries?.map((industry) => ({
            _id: industry._id,
            name: industry.industry_name,
          }))}
          selectedOption={industry}
          setSelectedOption={setIndustry}
          placeholder="Select Category"
        />
      </div>
      <InputField
        name="repo_url"
        label="Git Repo"
        placeholder="Enter repo_url"
        value={repo_url}
        onChange={(e) => setRepoURL(e.target.value)}
      />
      <ListBox
        label="Select Template Type"
        selectedOption={tempType}
        setSelectedOption={setTempType}
        options={tempTypes}
        placeholder="Selecte Template Type"
      />
      {tempType.name === "NEXT-14" && (
        <InputField
          name="port"
          label="Port"
          placeholder="Port Name between 6000 - 7000"
          value={port}
          onChange={(e) => setPort(e.target.value)}
        />
      )}
      {modalType === "edit" && (
        <ListBox
          placeholder="Selecte Template Status"
          label="Template Status"
          selectedOption={active}
          setSelectedOption={setActive}
          options={activeStatus}
        />
      )}
      <div className="flex items-center justify-end gap-3 mt-3">
        <button
          type="button"
          onClick={toggleSidebar}
          className="btnPrimary bg-red-400 dark:bg-red-500/40"
        >
          Cancel
        </button>
        <button type="submit" className="btnPrimary bg-black">
          {modalType === "new" ? "Create" : "Update"} Template
        </button>
      </div>

      <style jsx>{`
        form {
          transition: transform 0.3s ease;
        }
      `}</style>
    </form>
  );
}
