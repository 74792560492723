import React, { useState } from "react";
import DarkSwitch from "../components/common/DarkSwitch";
import { InputField } from "../components";
import { CircleX } from "lucide-react";
import useAuth from "../utils/useAuth";
import axios from "axios";
import PageSeo from "../components/common/PageSeo";

const Login = () => {
  const { loginUser } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const validateForm = () => {
    setErrors({
      username: "",
      password: "",
    });
    let isValid = true;
    let errors = { username: "", password: "" };

    if (!username.trim()) {
      errors.username = "Username is required";
      isValid = false;
    }
    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    axios
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/login`, {
        username,
        password,
      })
      .then((res) => {
        const { user, accessToken, refreshToken, menu_permissions } =
          res.data.data;
        loginUser(user, { accessToken, refreshToken }, menu_permissions);
        window.location.reload();
      })
      .catch((err) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Username or password incorrect.",
        }));
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  return (
    <div className="w-full min-h-screen grid grid-cols-login">
      <PageSeo title="SiteBuilderz | Login" />
      <div
        style={{ backgroundImage: "url(banner.png)" }}
        className="bg-cover bg-center relative text-white flex flex-col items-center justify-center p-10 text-center"
      >
        <div className="flex flex-col items-center justify-center w-full">
          <div className="flex flex-col items-center gap-3">
            <h1 className="font-extrabold text-6xl">
              Site Building Automation
            </h1>
            <p className="text-2xl">Tailored For SEO Experts</p>
          </div>
          <img src="/bannerIllustration.png" className="w-8/12 mt-12" alt="" />
        </div>
        <p className="absolute bottom-0 mx-auto mb-12">
          © Site Builderz, All Rights Reserved.
        </p>
      </div>
      <div className="dark:bg-gray-900 bg-lightGray dark:text-white px-7 py-5 flex flex-col">
        <div className="flex items-center justify-between">
          {/* <p className="text-2xl font-bold text-primary text-center">
          Site Builderz
        </p> */}
          <p className="text-lg font-medium w-full text-gray-600">
            Login with your account.
          </p>
          <DarkSwitch bgSun="bg-gray-300" />
        </div>
        <div className="flex flex-col flex-1">
          <img src="/logo.png" alt="" className="h-32 mx-auto my-14" />
          <InputField
            name="username"
            label="Username"
            type="username"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required={true}
            error={errors.username}
            onKeyDown={handleKeyDown}
            className="mb-5"
          />
          <InputField
            name="password"
            label="Password"
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            required={true}
            error={errors.password}
          />
          <div className="flex items-center justify-end mb-7 mt-2">
            <button
              disabled
              className="text-secondary w-fit text-sm text-end underline"
            >
              Forgot Password?
            </button>
          </div>
          {errors.general && (
            <p className="error-message flex items-center justify-center gap-1 text-red-600 bg-red-100 text-center rounded py-1 text-sm font-medium">
              <CircleX />
              {errors.general}
            </p>
          )}
          <button
            type="button"
            title="login"
            className="btnPrimary w-full text-base shadow-xl shadow-primary/20"
            onClick={handleLogin}
          >
            Login Now
          </button>
          <div className="flex items-center justify-center w-full gap-5 mt-8 mb-1">
            <div className="h-[1px] bg-gray-300 dark:bg-white/20 flex-1"></div>
            <p className="text-center text-gray-400 my-2">Or</p>
            <div className="h-[1px] bg-gray-300 dark:bg-white/20 flex-1"></div>
          </div>
          <button
            disabled
            className="btnPrimary cursor-not-allowed w-full mt-7 border border-primary bg-white shadow-none text-primary"
            type="button"
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
