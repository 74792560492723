import {
  AudioWaveform,
  BarChartBig,
  Boxes,
  GalleryHorizontalEnd,
  Gem,
  Group,
  Package,
  Share2,
  SquareLibrary,
  Ungroup,
  Users,
  Home,
  NotepadText,
  Proportions,
  Box,
  Settings,
  CloudCog,
  UserRoundCog,
} from "lucide-react";

export const menu = [
  { Icon: Home, title: "Dashboard", to: "/home", permission: "" },
  { Icon: Boxes, title: "Projects", to: "/projects", permission: "Projects" },
  {
    Icon: Box,
    title: "Domains",
    to: "/domains",
    permission: "Domains",
    children: [
      {
        Icon: Box,
        title: "My Domains",
        to: "/domains",
        permission: "View Domains",
      },
      {
        Icon: SquareLibrary,
        title: "Registrar",
        to: "/registrar",
        permission: "View Registrars",
      },
      {
        Icon: GalleryHorizontalEnd,
        title: "Hosting Provider",
        to: "/hosting-provider",
        permission: "View Hosting Providers",
      },
      {
        Icon: AudioWaveform,
        title: "Buy Type",
        to: "/buy-type",
        permission: "View Buy Types",
      },
      {
        Icon: Share2,
        title: "Associations",
        to: "/associations",
        permission: "View Associations",
      },
      {
        Icon: Ungroup,
        title: "Niche",
        to: "/niche",
        permission: "View Niches",
      },
      {
        Icon: Package,
        title: "Domain Type",
        to: "/domain-type",
        permission: "View Domain Types",
      },
      {
        Icon: BarChartBig,
        title: "Domain Status",
        to: "/domain-status",
        permission: "View Domain Status",
      },
    ],
  },
  {
    Icon: NotepadText,
    title: "Templates",
    to: "/templates",
    permission: "View Templates",
  },
  // { Icon: NotepadText, title: "Templates Manager", to: "/templates-manager" },
  {
    Icon: Proportions,
    title: "Reports",
    to: "/reports",
    permission: "Reports",
  },
  {
    Icon: Settings,
    title: "Settings",
    to: "/settings",
    permission: "Settings",
    children: [
      {
        Icon: Gem,
        title: "Merchants",
        to: "/merchants",
        permission: "View Merchants",
      },
      { Icon: Users, title: "Users", to: "/users", permission: "View Users" },
      {
        Icon: UserRoundCog,
        title: "Permissions",
        to: "/permissions",
        permission: "View Permissions",
      },
      { Icon: Group, title: "Roles", to: "/roles", permission: "view roles" },
      {
        Icon: Boxes,
        title: "Template Categories",
        to: "/categories",
        permission: "View Template Categories",
      },
      { Icon: CloudCog, title: "CDN", to: "/cdn", permission: "cdn" },
    ],
  },
];
