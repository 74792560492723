import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import useApi from "../../utils/useApi";
import { Edit, PlusCircle, Share2, Trash } from "lucide-react";
import { DataTable, InputField } from "../../components";
import usePermissions from "../../utils/userPermission";
import toast, { Toaster } from "react-hot-toast";

export default function Associations() {
  const [associations, setAssociations] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [updateIndex, setUpdateIndex] = useState(null);
  const dropdownRef = useRef(null);
  const deleteRefs = useRef({});
  const updateRefs = useRef({});
  const { isLoading, error, request } = useApi();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const fetchAssociations = async () => {
      try {
        const data = await request({
          method: "get",
          url: "masters/association",
        });
        setAssociations(data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAssociations();
  }, []);

  const handleAddAssociation = async (e) => {
    e.preventDefault();
    const value = e.target.input.value;
    try {
      const res = await request({
        method: "post",
        url: "masters/association",
        data: { name: value },
      });
      updateAssociationsList(res.data);
      toast.success(`${value} Association added.`);
      setDropdownVisible(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || `Couldn't add ${value} association.`
      );
    }
  };

  const handleDeleteAssociation = async (type) => {
    try {
      const res = await request({
        method: "delete",
        url: `masters/association/${type._id}`,
      });
      if (res.status) {
        updateAssociationsList(res.data);
        toast.success(`${type.name} Association removed.`);
        setDeleteIndex(null);
      } else {
        toast.error(error || `Couldn't remove ${type.name} association.`);
      }
    } catch (err) {
      toast.error(error || `Couldn't remove ${type.name} association.`);
    }
  };

  const handleUpdateAssociation = async (e, type) => {
    e.preventDefault();
    const newValue = e.target.elements.update.value;
    try {
      const res = await request({
        method: "put",
        url: `masters/association/${type._id}`,
        data: { name: newValue },
      });
      updateAssociationsList(res.data);
      toast.success(`${type.name} Association updated.`);
      setUpdateIndex(null);
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          `Couldn't update ${type.name} association.`
      );
    }
  };

  const updateAssociationsList = (newRecord) => {
    setAssociations((prevAssociations) => {
      const newData = [...prevAssociations];
      const index = newData.findIndex((item) => item._id === newRecord._id);
      if (index !== -1) {
        newRecord.deleted
          ? newData.splice(index, 1)
          : (newData[index] = newRecord);
      } else {
        newData.push(newRecord);
      }
      return newData;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
      Object.entries(deleteRefs.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setDeleteIndex(null);
        }
      });
      Object.entries(updateRefs.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setUpdateIndex(null);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const form = updateRefs.current[updateIndex];
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [updateIndex]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const form = updateRefs.current[updateIndex];
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [updateIndex]);

  return (
    hasPermission("Associations") && (
      <div>
        <Toaster />
        <DataTable
          searchBox
          title={
            <span className="flex items-center gap-2">
              <Share2 className="w-6 h-6" />
              Associations
            </span>
          }
          isLoading={isLoading}
          heads={[
            "Sr#",
            ...(hasPermission("Edit Association") ||
            hasPermission("Delete Association")
              ? ["Actions"]
              : []),
            "Name",
            "Created At",
            "Updated At",
          ]}
          items={associations.map((type, index) => {
            const actions = (
              <div className="flex items-center gap-2">
                {hasPermission("Delete Association") && (
                  <div className="relative">
                    <Trash
                      onClick={() => setDeleteIndex(index)}
                      className="deleteIcon"
                    />
                    {deleteIndex === index && (
                      <div
                        ref={(el) => (deleteRefs.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
                      >
                        <p className="font-medium">
                          Please confirm to delete {type.name} Association
                        </p>
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setDeleteIndex(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => handleDeleteAssociation(type)}
                            className="btnPrimary bg-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {hasPermission("Edit Association") && (
                  <div className="relative">
                    <Edit
                      onClick={() => setUpdateIndex(index)}
                      className="editIcon"
                    />
                    {updateIndex === index && (
                      <form
                        ref={(el) => (updateRefs.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-xl border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50 w-80"
                        onSubmit={(e) => handleUpdateAssociation(e, type)}
                      >
                        <InputField
                          name="update"
                          label="Name"
                          defaultValue={type.name}
                        />
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setUpdateIndex(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btnPrimary bg-black">
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>
            );

            const item = {
              serial: index + 1,
              ...(hasPermission("Edit Association") ||
              hasPermission("Delete Association")
                ? { actions }
                : {}),
              name: type.name,
              createdAt: dayjs(type.createdAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              updatedAt: dayjs(type.updatedAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };

            return item;
          })}
          extras={
            hasPermission("Add Association") && (
              <div className="relative text-black dark:text-white/80">
                {dropdownVisible && (
                  <form
                    onSubmit={handleAddAssociation}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md shadow-xl"
                  >
                    <InputField name="input" label="Name" />
                    <div className="flex items-center justify-end mt-3">
                      <button type="submit" className="btnPrimary bg-black">
                        Add
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add Association
                </button>
              </div>
            )
          }
        />
      </div>
    )
  );
}
