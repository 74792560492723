import React from "react";
import ManageArticle from "./ManageArticle";
import { ArticleProvider } from "../ArticleContext";
import ArticlePreview from "./ArticlePreview";

export default function WriteArticle() {
  return (
    <ArticleProvider>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-7 h-[calc(100vh-6rem)] overflow-y-scroll p-8">
        <ManageArticle />
        <ArticlePreview />
      </div>
    </ArticleProvider>
  );
}
