import React, { useState, useEffect, useRef } from "react";
import { useArticleContext } from "./ArticleContext";
import {
  ChevronLeft,
  ChevronRight,
  FileWarning,
  Grid2X2,
  List,
  LoaderCircle,
  RefreshCw,
  Trash,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../../utils/userPermission";
import Checkbox from "../../../../components/common/CheckBox";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../utils/useApi";

const GridBox = ({ grid, setGrid }) => (
  <div className="bg-white dark:bg-gray-800 p-1 rounded-md border border-gray-300 dark:border-white/30 flex items-center justify-center">
    <button
      onClick={() => setGrid(true)}
      className={`cursor-pointer rounded hover:bg-gray-200 dark:hover:bg-gray-900 py-1 px-4 ${
        grid ? "bg-gray-200 dark:bg-gray-900" : ""
      }`}
    >
      <Grid2X2 className="h-5 w-5" />
    </button>
    <button
      onClick={() => setGrid(false)}
      className={`cursor-pointer rounded hover:bg-gray-200 dark:hover:bg-gray-900 py-1 px-4 ${
        !grid ? "bg-gray-200 dark:bg-gray-900" : ""
      }`}
    >
      <List className="h-5 w-5" />
    </button>
  </div>
);

const AddArticleButton = ({ onClick }) => (
  <button className="btnPrimary" onClick={onClick}>
    Add Article
  </button>
);

const Pagination = ({ currentPage, totalPages, paginate }) => (
  <div className="flex justify-center items-center">
    <button
      disabled={currentPage === 1}
      className="h-8 w-8 rounded-full hover:bg-white hover:text-black transition-all flex items-center justify-center"
      onClick={() => paginate(currentPage - 1)}
    >
      <ChevronLeft className="w-5 h-5" />
    </button>
    {[...Array(totalPages).keys()].map((number) => (
      <button
        key={number}
        onClick={() => paginate(number + 1)}
        className={`h-8 w-8 rounded-full ${
          currentPage === number + 1
            ? "bg-primary text-white"
            : "hover:bg-white hover:text-black"
        }`}
      >
        {number + 1}
      </button>
    ))}
    <button
      disabled={currentPage === totalPages}
      className="h-8 w-8 rounded-full hover:bg-white hover:text-black transition-all flex items-center justify-center"
      onClick={() => paginate(currentPage + 1)}
    >
      <ChevronRight className="w-5 h-5" />
    </button>
  </div>
);

const SelectButtons = ({
  select,
  setSelect,
  onSelectAll,
  filteredArticles,
  selectedData,
  action,
  handleDelete,
}) => (
  <div className="flex items-center justify-center gap-2">
    {select ? (
      <>
        <button className="btnPrimary bg-red-500" onClick={handleDelete}>
          Delete
        </button>
        <button
          className="btnWhite text-red-700"
          onClick={() => setSelect(false)}
        >
          Cancel Select
        </button>
        <Checkbox
          className="btnWhite"
          label="Select All"
          onChange={(e) => onSelectAll(e.target.checked)}
          checked={
            filteredArticles?.length &&
            filteredArticles?.length === selectedData?.length
          }
        />
      </>
    ) : (
      <button
        className="btnWhite w-fit border dark:border-white/20 shadow-sm border-gray-300"
        onClick={() => setSelect(true)}
      >
        Select Articles
      </button>
    )}
  </div>
);

const Header = ({
  searchTerm,
  setSearchTerm,
  filterCategory,
  setFilterCategory,
  currentPage,
  paginate,
  totalPages,
  select,
  setSelect,
  onSelectAll,
  selectedData,
  filteredArticles,
}) => {
  const navigate = useNavigate();
  const {
    getBlogsList,
    isLoading,
    grid,
    setGrid,
    setArticle,
    setFileInfo,
    projectToManage,
    categories,
    project_id,
    blogsList,
  } = useArticleContext();
  const { hasPermission } = usePermissions();
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const writeArticle = () => {
    navigate(
      `/projects/${projectToManage?.project_name
        ?.toLowerCase()
        ?.replaceAll(" ", "-")}/articles/write`
    );
  };

  const handleAddArticle = () => {
    setArticle({
      title: "",
      tagline: "",
      author: "",
      published_at: "",
      imageTitle: "",
      image: "",
      articleContent: "",
    });
    setFileInfo(null);
    writeArticle();
  };

  const { request } = useApi();
  const [action, setAction] = useState(false);

  const confirmRemoveArticles = async (event) => {
    event.preventDefault();
    setAction(true);
    console.log("Previous Blogs", blogsList);

    const idsToRemove = new Set(selectedData.map((item) => item._id));
    const updatedBlogsList = blogsList.filter(
      (item) => !idsToRemove.has(item._id)
    );

    console.log("Updated Blogs List", updatedBlogsList);

    try {
      const formData = new FormData();
      formData.append("key", "blog_list");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedBlogsList));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setShowPopup(false);
      getBlogsList();
      toast.success("Selected blogs removed.");
    } catch (err) {
      toast.error("Error deleting articles.");
    }

    setAction(false);
  };

  const handleDelete = () => {
    setShowPopup(true);
  };

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  return (
    <div className="flex flex-col xl:flex-row lg:items-center gap-3 lg:gap-2 w-full">
      <Toaster />
      <div className="flex items-center flex-wrap md:flex-nowrap w-full lg:w-auto">
        <div className="flex items-center justify-between">
          <p className="text-2xl font-bold whitespace-nowrap">Articles </p>
          <button onClick={getBlogsList}>
            <RefreshCw
              className={`w-5 cursor-pointer dark:text-white/80 mx-3 ${
                isLoading && "animate-spin"
              }`}
            />
          </button>
          {!select && (
            <>
              <p className="mx-2 whitespace-nowrap">
                Page {currentPage} of {totalPages}
              </p>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
              />
            </>
          )}
        </div>
        <div className="flex lg:hidden items-center justify-end gap-2">
          {!select && <GridBox grid={grid} setGrid={setGrid} />}
          <SelectButtons
            select={select}
            setSelect={setSelect}
            onSelectAll={onSelectAll}
            filteredArticles={filteredArticles}
            selectedData={selectedData}
            handleDelete={handleDelete}
            action={action}
          />
          {hasPermission("Add Project Article") && (
            <AddArticleButton onClick={handleAddArticle} />
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-2 lg:flex-1 w-full">
        {!select && (
          <div className="flex items-center border dark:border-white/20 gap-3 bg-white dark:bg-gray-800 w-full rounded-md">
            <select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              className="bg-transparent py-2 px-3 w-fit"
            >
              <option value="">All Categories</option>
              {categories?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
              <option value="Must Read">Must Read</option>
              <option value="Popular">Popular</option>
              <option value="Featured">Featured</option>
            </select>
            <input
              type="text"
              className="bg-transparent flex-1 outline-none border-l dark:border-white/20 px-5 py-1"
              placeholder="Search article.."
              value={searchTerm}
              onChange={setSearchTerm}
            />
          </div>
        )}
        <div className="hidden lg:flex items-center justify-end gap-2">
          {!select && <GridBox grid={grid} setGrid={setGrid} />}
          <SelectButtons
            select={select}
            setSelect={setSelect}
            onSelectAll={onSelectAll}
            filteredArticles={filteredArticles}
            selectedData={selectedData}
            handleDelete={handleDelete}
            action={action}
          />
          {!select && hasPermission("Add Project Article") && (
            <AddArticleButton onClick={handleAddArticle} />
          )}
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div
            ref={popupRef}
            className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg"
          >
            <div className="flex items-center text-primary gap-2 mb-2">
              <FileWarning className="w-5 h-5" />
              <h2 className="text-lg font-bold">Confirmation</h2>
            </div>
            <p className="mb-6">
              Are you sure you want to delete these articles?
            </p>
            <div className="flex justify-end space-x-4">
              <button onClick={() => setShowPopup(false)} className="btnWhite">
                Cancel
              </button>
              <button
                onClick={confirmRemoveArticles}
                className="btnPrimary bg-red-500"
              >
                {action ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {action ? <p>Deleting</p> : <p>Confirm Delete</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
