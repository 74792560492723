import React, { useEffect, useRef, useState } from "react";
import { useProjectContext } from "../ProjectContext";
import { ComboBox, InputField, Modal } from "../../../../components";
import {
  CheckCheck,
  CloudOff,
  Copy,
  LoaderCircle,
  ShieldAlertIcon,
} from "lucide-react";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../../../utils/userPermission";

export default function ManageProjectDomain() {
  const { request } = useApi();
  const { projectToManage, projectLoading } = useProjectContext();
  const [domain, setDomain] = useState(null);
  const [domains, setDomains] = useState([]);

  const getDomains = async () => {
    if (projectToManage?.domain_id) return;
    try {
      const res = await request({
        method: "post",
        url: "domains/report",
        data: {
          page: "",
          size: "",
          filters: "",
          sort_field: "",
          sort_by: "",
        },
      });
      setDomains(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getDomains();
  }, []);

  const connectDomain = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: `projects/${projectToManage?._id}/connect_domain`,
        data: { domain_id: domain._id },
      });
      toast.success("Your Domain Is Connected");
      window.location.reload();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const [confirmDisconnect, setConfirmDisconnect] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const confirmDisconnectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        confirmDisconnectRef.current &&
        !confirmDisconnectRef.current.contains(event.target)
      ) {
        setConfirmDisconnect(false);
      }
    };

    if (confirmDisconnect) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [confirmDisconnect]);

  const disConnectDomain = async (e) => {
    e.preventDefault();
    setDisconnecting(true);
    try {
      await request({
        method: "post",
        url: `projects/${projectToManage?._id}/disconnect_domain`,
      });
      toast.success("Domain Disconnected");
      window.location.reload();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setDisconnecting(false);
  };

  const [isCopied, setIsCopied] = useState(null);
  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const { hasPermission } = usePermissions();

  return (
    hasPermission("Manage Domain") && (
      <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 py-6 flex flex-col items-center">
        <Toaster />

        <div className="max-w-4xl">
          <h3>My Domains</h3>
          <p className="dark:text-white/80 text-gray-500 mt-2">
            These domains are assigned to your Production Deployments.
            Optionally, a different Git branch or a redirection to another
            domain can be configured for each one.
          </p>
        </div>

        {projectLoading ? (
          <div className="flex flex-col items-center w-full p-24 gap-5">
            <LoaderCircle className="h-12 w-12 animate-spin text-primary" />
            <p>Loading...</p>
          </div>
        ) : (
          <div className="max-w-4xl w-full">
            {projectToManage?.domain_id ? (
              <div className="bg-white dark:bg-gray-800 p-5 rounded-lg my-6">
                <div className="flex items-center justify-between">
                  <p className="text-lg font-bold mb-1">Domain Connected</p>
                  {hasPermission("disconnect domain") && (
                    <button
                      title="disconnect domain"
                      type="button"
                      onClick={() => setConfirmDisconnect(true)}
                      className="btnPrimary bg-red-500 rounded-full"
                    >
                      Disconnect Domain
                    </button>
                  )}
                </div>
                <div className="flex gap-2">
                  <p className="underline">
                    {projectToManage?.domain_id?.domain}
                  </p>
                  <button
                    type="button"
                    onClick={
                      projectToManage?.domain_id?.cloudflair_details?.status ===
                        "pending" && handleModal
                    }
                    className={`px-4 py-1 text-sm rounded-full whitespace-nowrap font-medium capitalize ${
                      projectToManage?.domain_id?.cloudflair_details?.status ===
                      "pending"
                        ? "bg-red-100 text-red-500"
                        : "bg-green-100 text-green-600"
                    }`}
                  >
                    {projectToManage?.domain_id?.cloudflair_details?.status ===
                    "pending"
                      ? "pending DNS"
                      : "active"}
                  </button>
                </div>
                {projectToManage?.domain_id?.cloudflair_details?.status ===
                  "pending" && (
                  <>
                    <p className="mt-3 mb-1 text-gray-500 text-lg font-bold">
                      Nameservers
                    </p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                      {projectToManage?.domain_id?.cloudflair_details?.name_servers.map(
                        (item, index) => (
                          <div
                            className="bg-white dark:bg-gray-900 dark:border-white/20 shadow-md border rounded-md py-2 px-4 flex items-center gap-2 relative"
                            key={index}
                          >
                            <p className="flex-1">{item}</p>
                            {isCopied === index ? (
                              <CheckCheck className="w-4 text-green-500" />
                            ) : (
                              <Copy
                                className="w-4 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition-all"
                                onClick={() => copyToClipboard(item, index)}
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>

                    <p className="text-gray-400 mt-3">
                      Replace these nameservers in your domain provider
                    </p>
                    <button
                      onClick={
                        projectToManage?.domain_id?.cloudflair_details
                          ?.status === "pending" && handleModal
                      }
                      className=" text-blue-600 hover:text-blue-800 transition-all underline"
                    >
                      Click here for instruction how to point your domain from
                      your registrar.
                    </button>
                  </>
                )}
              </div>
            ) : (
              <div className="bg-white dark:bg-gray-800 p-5 rounded-lg my-6">
                <p className="text-lg font-bold mb-4">
                  Assign Domain To Project
                </p>
                {hasPermission("connect domain") ? (
                  <div className="flex items-end gap-2">
                    <ComboBox
                      label="Select An Existing Domain"
                      placeholder="Select"
                      selectedOption={domain}
                      setSelectedOption={setDomain}
                      options={domains?.map((item) => ({
                        _id: item._id,
                        name: item.domain,
                      }))}
                      className="flex-1"
                    />
                    <button
                      disabled={!domain}
                      onClick={connectDomain}
                      className="btnPrimary text-base disabled:cursor-not-allowed"
                    >
                      Add
                    </button>
                  </div>
                ) : (
                  "You aren't authorized to assign domain to this project."
                )}
              </div>
            )}

            <div className="bg-white dark:bg-gray-800 p-5 rounded-lg">
              <p className="text-lg font-bold mb-1">Free Domain</p>
              <p className="mt-1">
                <a
                  href={`http://${projectToManage?.industry_template_id?.url}?project_id=${projectToManage?._id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="underline hover:text-primary transition-all cursor-pointer"
                >
                  {projectToManage?.industry_template_id?.url}?project_id=
                  {projectToManage?._id}
                </a>
              </p>
            </div>
          </div>
        )}

        {confirmDisconnect && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 dark:bg-black/40 bg-opacity-50 z-50">
            <div
              ref={confirmDisconnectRef}
              className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg max-w-md"
            >
              <div className="flex gap-3">
                <ShieldAlertIcon className="h-12 w-10 text-yellow-400 mb-3" />
                <p className="flex-1">
                  Are you sure? You want to disconnect domain{" "}
                  <span className="underline text-primary">
                    {projectToManage?.domain_id?.domain}
                  </span>{" "}
                  from project {projectToManage?.project_name}.
                </p>
              </div>
              <div className="flex items-center justify-end gap-6 mt-6">
                <button onClick={() => setConfirmDisconnect(false)}>
                  Cancel
                </button>
                <button
                  onClick={disConnectDomain}
                  className="btnPrimary bg-red-500"
                >
                  {disconnecting ? (
                    <LoaderCircle className="w-4 h-4 animate-spin" />
                  ) : (
                    <CloudOff className="w-4 h-4" />
                  )}
                  {disconnecting ? <p>Disconnecting</p> : <p>Disconnect</p>}
                </button>
              </div>
            </div>
          </div>
        )}

        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-3xl"
          title={
            <span className="text-2xl">Steps to point domain to registrar</span>
          }
        >
          <div className="flex items-end gap-2">
            <InputField
              label="Your Domain"
              value={projectToManage?.domain_id?.domain}
              disabled
              className="flex-1"
            />
            <div
              className={`px-4 py-2 rounded whitespace-nowrap font-medium ${
                projectToManage?.domain_id?.cloudflair_details?.status ===
                  "pending" && "bg-red-100 text-red-500"
              }`}
            >
              Pending DNS
            </div>
          </div>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              1. Log in to your domain registrar account:
            </b>
            Visit the website where you registered your domain name and log in
            to your account using your username and password.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              2. Locate your domain settings:
            </b>{" "}
            Once logged in, navigate to the domain management section. This
            might be labeled as "Domain Management," "My Domains," or something
            similar.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              3. Find the nameserver settings:
            </b>{" "}
            Look for an option like "Nameservers," "DNS Management," or "DNS
            Settings" within the domain management interface. Click on it to
            access your domain's DNS settings.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              4. View current nameservers:
            </b>{" "}
            Before making any changes, take note of the current nameservers
            listed for your domain. This information might be useful in case you
            need to revert the changes later.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              5. Edit nameserver settings:
            </b>
            Now, you should see fields where you can enter the new nameserver
            information. Delete the existing nameservers and replace them with
            the ones we provided you.
          </p>
          <img src="/img/cloudflare.png" className="w-44 my-6" alt="" />
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
            {projectToManage?.domain_id?.cloudflair_details?.name_servers.map(
              (item, index) => (
                <div
                  className="bg-white dark:bg-gray-900 dark:border-white/20 shadow-md border rounded-md py-2 px-4 flex items-center gap-2 relative"
                  key={index}
                >
                  <p className="flex-1">{item}</p>
                  {isCopied === index ? (
                    <CheckCheck className="w-4 text-green-500" />
                  ) : (
                    <Copy
                      className="w-4 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition-all"
                      onClick={() => copyToClipboard(item, index)}
                    />
                  )}
                </div>
              )
            )}
          </div>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">6. Save changes:</b>
            After entering the new nameserver information, look for a "Save" or
            "Update" button and click on it to save your changes.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">7. Confirm changes:</b>
            Some registrars may require you to confirm the changes via email or
            another method. Follow any prompts or instructions to confirm the
            nameserver update.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">
              8. Wait for propagation:
            </b>
            DNS changes can take some time to propagate across the internet.
            This process typically takes a few hours to up to 48 hours, although
            it's often much quicker.
          </p>
          <p className="text-sm mt-5 text-gray-500 dark:text-white/80">
            <b className="text-gray-800 dark:text-white">9. Verify changes:</b>
            Once the propagation is complete, we will notify you via email also
            you can verify that the nameserver change was successful by using
            online tools like DNS lookup or by visiting your website to ensure
            it loads correctly.
          </p>
        </Modal>
      </div>
    )
  );
}
