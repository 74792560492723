import React from "react";
import { useTemplateContext } from "../TemplateContext";
import { Skeleton } from "../../../../components";
import usePermissions from "../../../../utils/userPermission";

export default function TemplatePreview() {
  const { isPhoneView, temp, isLoading } = useTemplateContext();
  const { hasPermission } = usePermissions();
  return (
    <div className="pt-7 pl-8 pr-7 flex flex-col items-center">
      {isLoading ? (
        <Skeleton />
      ) : temp?.url ? (
        hasPermission("template Preview") && (
          <iframe
            title="Website Preview"
            src={`https://${temp?.url}`}
            className={`h-[calc(100vh-7.8rem)] ${
              isPhoneView ? "w-80 max-h-[680px]" : "w-full"
            }`}
          />
        )
      ) : (
        <div className="py-20 flex flex-col items-center gap-3 text-center text-gray-400">
          <img src="/empty.png" className="w-64" alt="" />
          Template Has Not Been Deployed Yet
        </div>
      )}
    </div>
  );
}
