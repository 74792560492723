import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Camera,
  CheckCheck,
  Copy,
  Info,
  LoaderCircle,
  Save,
  X,
} from "lucide-react";
import { formatFileSize } from "../../ProjectGallery";
import useApi from "../../../../../utils/useApi";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { InputField } from "../../../../../components";

// Component for rendering individual image items
const ImageItem = ({ item, image, copyToClipboard, isCopied }) => (
  <div className="relative">
    <div className="relative overflow-hidden bg-black rounded-md h-28">
      <img
        alt="preview"
        className="object-fill absolute m-auto min-w-full min-h-full"
        src={image}
      />
    </div>
    <button
      className="btnWhite absolute bottom-0 right-0 m-2"
      onClick={(e) => copyToClipboard(item, e)}
    >
      {isCopied === item.key ? (
        <CheckCheck className="w-4 h-4 text-green-500" />
      ) : (
        <Copy className="w-4 h-4 text-gray-500 dark:text-white/80" />
      )}
      Copy
    </button>
  </div>
);

export default function ProjectGallery({
  project_id,
  handleModal,
  sidebar,
  toggleSidebar,
}) {
  const { request } = useApi();
  const [images, setImages] = useState([]);
  const [isCopied, setIsCopied] = useState(null);
  const [galleryFile, setGalleryFile] = useState(null);
  const [image, setImage] = useState({});

  // Fetch gallery images
  const getGallery = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/gallery`,
      });
      setImages(res.data[0].value);
    } catch (err) {
      console.error("Failed to fetch gallery:", err);
      toast.error("Failed to fetch gallery.");
    }
  }, [project_id, request]);

  useEffect(() => {
    getGallery();
  }, [project_id]);

  // Handle copying image URL to clipboard
  const copyToClipboard = useCallback(
    (item, e) => {
      e.preventDefault();
      navigator.clipboard
        .writeText(
          `![${item.imageAltText}](${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image} "${item.imageTitle}")`
        )
        .then(() => {
          setIsCopied(item.key);
          setTimeout(() => setIsCopied(null), 2000);
          toast.success("Image URL Copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error("Failed to copy to clipboard");
        });
    },
    [project_id, handleModal]
  );

  // Handle file selection for image upload and get image dimensions
  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image(); // Create a new Image object to get dimensions
        img.src = reader.result;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          setGalleryFile({ file, preview: reader.result });
          setImage((prevImage) => ({
            ...prevImage,
            fileSize: formatFileSize(file.size),
            fileType: file.type,
            dimensions: `${width}x${height}`, // Set image dimensions
          }));
        };
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleChange = (e) => {
    setImage((prevImage) => ({
      ...prevImage,
      [e.target.name]: e.target.value,
    }));
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const createUpdate = useCallback(
    async (event) => {
      event.preventDefault();
      setIsUpdating(true);

      if (!image.imageTitle || !image.imageAltText) {
        toast.error("Image title and alt text cannot be empty.");
        setIsUpdating(false);
        return;
      }

      const uniqueKey = image.key || `image-${uuidv4()}`;
      const updatedImage = { ...image, key: uniqueKey };

      try {
        const formData = new FormData();
        formData.append("key", uniqueKey);
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedImage));
        if (galleryFile?.file) {
          formData.append("file", galleryFile.file);
        }

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (res.status) {
          const imageWithResponseData = {
            ...updatedImage,
            _id: res.data._id,
            key: res.data.key,
            image: res.data.file_name,
            createdAt: res.data.createdAt,
            updatedAt: res.data.updatedAt,
          };

          const updatedImagesList = [...images];
          const imgIndex = updatedImagesList.findIndex(
            (img) => img.key === uniqueKey
          );

          if (imgIndex !== -1) {
            updatedImagesList[imgIndex] = imageWithResponseData;
          } else {
            updatedImagesList.push(imageWithResponseData);
          }

          const updatedFormData = new FormData();
          updatedFormData.append("key", "gallery");
          updatedFormData.append("value_type", "JSON");
          updatedFormData.append("value", JSON.stringify(updatedImagesList));

          await request({
            method: "post",
            url: `projects/${project_id}/data`,
            data: updatedFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          toast.success("Image updated.");
          getGallery();
        }
        setGalleryFile(null);
        setImage({});
      } catch (err) {
        console.error("Failed to add/update image:", err);
        toast.error("Failed to add/update image.");
      } finally {
        setIsUpdating(false);
      }
    },
    [image, galleryFile, images, getGallery, project_id, request]
  );

  const addFromRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addFromRef.current && !addFromRef.current.contains(event.target)) {
        if (sidebar) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar, toggleSidebar]);

  return (
    <div
      ref={addFromRef}
      className={`flex flex-col w-[600px] h-screen overflow-y-scroll z-50 fixed right-0 top-0 p-6 bg-white dark:bg-gray-800 ${
        sidebar ? "shadow-l shadow-black/20 dark:shadow-gray-600/40" : ""
      }`}
      style={{ transform: sidebar ? "translateX(0)" : "translateX(100%)" }}
    >
      <div className="flex items-center justify-between">
        <h3>Gallery</h3>
        <div className="flex items-center justify-end gap-2">
          <label className="btnWhite cursor-pointer">
            <Camera className="w-4 h-4" />
            Add Image
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden" // Hide the default file input
            />
          </label>
          <button className="btnPrimary bg-red-500" onClick={toggleSidebar}>
            Close
          </button>
        </div>
      </div>

      <p className="text-secondary text-sm mb-5 mt-2 whitespace-nowrap flex items-center gap-1">
        <Info className="w-4 h-4" /> Copy image URL from here and paste in image
        editor.
      </p>

      {/* Gallery Display */}
      {galleryFile ? (
        <div className="mb-4">
          <p className="text-lg">Selected Image Preview:</p>
          <img
            src={galleryFile.preview}
            alt="Selected Preview"
            className="w-full h-auto my-2 rounded-md"
          />
          <div className="flex items-center gap-3">
            {image.fileSize && (
              <p>
                <span className="font-semibold">File Size: </span>
                {image.fileSize}
              </p>
            )}
            {image.fileType && (
              <p>
                <span className="font-semibold">File Type: </span>
                {image.fileType}
              </p>
            )}
            {image.dimensions && (
              <p>
                <span className="font-semibold">Dimensions: </span>
                {image.dimensions}
              </p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4 mt-4">
            <InputField
              label="Image Title"
              name="imageTitle"
              placeholder="image title"
              value={image?.imageTitle || ""}
              onChange={handleChange}
            />
            <InputField
              label="Alt Text"
              name="imageAltText"
              placeholder="image alt text"
              value={image?.imageAltText || ""}
              onChange={handleChange}
            />
          </div>
          <button
            title="Save & Update"
            onClick={createUpdate}
            className={`btnPrimary bg-secondary px-3 ml-auto mt-5 ${
              isUpdating && "cursor-wait"
            }`}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <LoaderCircle className="w-4 animate-spin" />
            ) : (
              <Save className="w-4 h-4" />
            )}
            {isUpdating ? <p>Updating Banner</p> : <p>Save & Update</p>}
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-5">
          {images.map((item, index) => (
            <ImageItem
              key={index}
              item={item}
              isCopied={isCopied}
              project_id={project_id}
              copyToClipboard={copyToClipboard}
              image={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${item.image}`}
            />
          ))}
        </div>
      )}
    </div>
  );
}
