import React, { createContext, useContext, useEffect, useState } from "react";
import { useTemplateContext } from "../TemplateContext";
import useApi from "../../../../utils/useApi";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const ArticleContext = createContext();

export const useArticleContext = () => useContext(ArticleContext);

export const ArticleProvider = ({ children }) => {
  const { request, isLoading } = useApi();
  const { template_id, industry_id } = useTemplateContext();
  const [blogsList, setBlogsList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fileInfo, setFileInfo] = useState(null);
  const [article, setArticle] = useState({});
  const [grid, setGrid] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [deletingArticle, setDeletingArticle] = useState(false);

  const navigate = useNavigate();
  const { template } = useParams();

  // Fetch categories data
  const getCategories = async () => {
    if (!template_id || !industry_id) return;

    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/categories`,
      });
      setCategories(res?.data[0]?.value);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  // Fetch blogs list data
  const getBlogsList = async () => {
    if (!template_id || !industry_id) return;

    setLoadingArticles(true);
    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/blog_list`,
      });
      setBlogsList(res?.data[0]?.value);
    } catch (err) {
      console.error("Error fetching blogs list:", err);
    } finally {
      setLoadingArticles(false);
    }
  };

  // Fetch tags list data
  const getTagList = async () => {
    if (!industry_id || !template_id) return;

    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/tag_list`,
      });
      setTagList(res?.data[0]?.value);
    } catch (err) {
      console.error("Error fetching tags list:", err);
    }
  };

  useEffect(() => {
    getBlogsList();
    getTagList();
    getCategories();
  }, [industry_id, template_id]);

  // Handle blog deletion
  const deleteBlog = (blog) => {
    setDeleteConfirmation(true);
    setKeyToDelete(`blog_${blog.title.replaceAll(" ", "_").toLowerCase()}`);
  };

  const deleteFromList = async (e) => {
    e.preventDefault();
    setDeletingArticle(true);

    const index = blogsList.findIndex(
      (blog) =>
        `blog_${blog.title.replaceAll(" ", "_").toLowerCase()}` === keyToDelete
    );

    if (index !== -1) {
      const updatedBlogsList = [...blogsList];
      updatedBlogsList.splice(index, 1);
      setBlogsList(updatedBlogsList);

      try {
        const formData = new FormData();
        formData.append("key", "blog_list");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedBlogsList));

        await request({
          method: "post",
          url: `industries/${industry_id}/templates/${template_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Article Removed.");
        setDeleteConfirmation(false);
      } catch (err) {
        console.error("Error deleting blog:", err);
      }
    }

    setDeletingArticle(false);
  };

  // Fetch a single blog data
  const getBlog = async (articleKey) => {
    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/${articleKey}`,
      });

      const blog = res?.data[0]?.value;
      const file = res?.data[0]?.file_name;
      setFileInfo({
        file: file,
        preview: `${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template_id}/${file}`,
      });
      setArticle(blog);
    } catch (err) {
      console.error("Error fetching blog:", err);
    }
  };

  // Navigate to blog update page
  const handleUpdateBlog = (blog) => {
    navigate(`/templates/${template}/articles/${blog.key}`);
  };

  // Handle file change event
  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFileInfo({
      file: file,
      preview: URL.createObjectURL(file),
    });
  };

  return (
    <ArticleContext.Provider
      value={{
        grid,
        setGrid,
        blogsList,
        setBlogsList,
        getBlogsList,
        deleteFromList,
        template_id,
        industry_id,
        deleteBlog,
        categories,
        setCategories,
        handleUpdateBlog,
        deleteConfirmation,
        setDeleteConfirmation,
        isLoading,
        setArticle,
        setFileInfo,
        article,
        fileInfo,
        handleFileChange,
        getBlog,
        tagList,
        deletingArticle,
        loadingArticles,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};
