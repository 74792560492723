import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Link2,
  LoaderCircle,
  RefreshCw,
  SlidersHorizontal,
} from "lucide-react";
import useApi from "../../../utils/useApi";
import { Breadcrumbs, SearchBox } from "../../../components";
import Pagination from "../../../components/common/Pagination";
import ReportFilters from "./ReportFilters";

export default function BacklinkReport() {
  const { request, isLoading } = useApi();
  const location = useLocation();
  const { domain_id } = location.state;

  const [backlinks, setBacklinks] = useState([]);
  const getBacklinksReport = async () => {
    try {
      const res = await request({
        method: "post",
        url: "domains/backlinks/report",
        data: {
          filters: "",
          domain_id,
        },
      });
      setBacklinks(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getBacklinksReport();
  }, [domain_id]);

  const [page, setpage] = useState(1);
  const itemsPerPage = 9;
  const totalPages = Math.ceil(backlinks?.length / itemsPerPage);
  const currentTemplates = backlinks?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
  };

  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(page * itemsPerPage, backlinks?.length);
  const totalItems = backlinks?.length;

  const renderDetailItem = (label, value, className) => (
    <p className={className}>
      <b className="text-gray-600 dark:text-white capitalize">
        {label.replaceAll("_", " ")}:
      </b>{" "}
      <span className="wrap-text">{value}</span>
    </p>
  );

  const [searchQuery, setSearchQuery] = useState();
  const handleSearch = () => {};

  const [showReportFilters, setShowReportFilters] = useState(false);

  return (
    <div>
      {/* Head */}
      <div className="bg-warmGray dark:bg-gray-800 px-6 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex items-center text-white">
            <Link2 className="h-6 w-6" />
            <h4 className="ml-2 mr-3 whitespace-nowrap">Backlink Report</h4>
          </div>
          <SearchBox
            placeholder="Search."
            className="border-white/20 bg-white text-black"
            inputPadding="py-1"
            inputClass="min-w-44"
            name="domain"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <div className="flex items-center justify-end gap-2">
          <button className="mr-3 ml-1" onClick={() => getBacklinksReport("")}>
            <RefreshCw
              className={`w-5 cursor-pointer text-white dark:text-white/80 ${
                isLoading && "animate-spin"
              }`}
            />
          </button>
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            handlePageChange={handlePageChange}
          />
          <button
            type="button"
            onClick={() => setShowReportFilters(true)}
            className="btnOutlined py-[6px]"
          >
            <SlidersHorizontal className="h-4 w-4 text-gray-100 dark:text-gray-300" />
            Filters
          </button>
        </div>
      </div>
      
      {/* Breadcrumbs */}
      <div className="flex items-center justify-between w-full px-6 mt-2">
        <div>
          <Breadcrumbs />
        </div>
        <p className="text-gray-700 dark:text-gray-400 whitespace-nowrap">
          Showing {startItem} to {endItem} of {totalItems} backlinks
        </p>
      </div>

      {showReportFilters && <ReportFilters />}

      {/* Table */}
      <div className="px-6 py-2">
        {isLoading ? (
          <div className="flex justify-center items-center text-center h-64 flex-col gap-3">
            <LoaderCircle className="animate-spin h-12 w-12 text-primary" />
            Loading Report..
          </div>
        ) : backlinks.length === 0 ? (
          <div className="text-center text-gray-600 dark:text-gray-300">
            No backlinks available.
          </div>
        ) : (
          backlinks?.map((item, index) => (
            <div
              key={index}
              className="grid grid-cols-3 gap-x-5 bg-white dark:bg-gray-800 last:border-none shadow-md shadow-black/20 dark:border-white/20 mb-4 py-4 px-6"
            >
              {renderDetailItem("url_from", item.backlink_info.url_from)}
              {renderDetailItem("title", item.backlink_info.title)}
              {renderDetailItem("languages", item.backlink_info.languages)}
              {renderDetailItem("powered by", item.backlink_info.powered_by)}
              {renderDetailItem("link type", item.backlink_info.link_type)}
              {renderDetailItem(
                "redirect code",
                item.backlink_info.redirect_code
              )}
              {renderDetailItem(
                "first seen link",
                item.backlink_info.first_seen_link
              )}
              {renderDetailItem("lost_reason", item.backlink_info.lost_reason)}
              {renderDetailItem("drop_reason", item.backlink_info.drop_reason)}
              {renderDetailItem("http_code", item.backlink_info.http_code)}
              {renderDetailItem(
                "discovered_status",
                item.backlink_info.discovered_status
              )}
              {renderDetailItem(
                "source page author",
                item.backlink_info.source_page_author
              )}
              {renderDetailItem("is dofollow", item.backlink_info.is_dofollow)}
              {renderDetailItem("is nfollow", item.backlink_info.is_nofollow)}
              {renderDetailItem("is ugc", item.backlink_info.is_ugc)}
              {renderDetailItem(
                "is_sponsored",
                item.backlink_info.is_sponsored
              )}
              {renderDetailItem("is_content", item.backlink_info.is_content)}
              {renderDetailItem(
                "domain_rating_source",
                item.backlink_info.domain_rating_source
              )}
              {renderDetailItem(
                "traffic_domain",
                item.backlink_info.traffic_domain
              )}
              {renderDetailItem(
                "is_root_source",
                item.backlink_info.is_root_source
              )}
              {renderDetailItem(
                "root_name_source",
                item.backlink_info.root_name_source
              )}
              {renderDetailItem("traffic", item.backlink_info.traffic)}
              {renderDetailItem("positions", item.backlink_info.positions)}
              {renderDetailItem(
                "links_external",
                item.backlink_info.links_external
              )}
              {renderDetailItem(
                "url_rating_source",
                item.backlink_info.url_rating_source
              )}
              {renderDetailItem(
                "last_visited",
                item.backlink_info.last_visited
              )}
              {renderDetailItem(
                "refdomains_source",
                item.backlink_info.refdomains_source
              )}
              {renderDetailItem(
                "linked_domains_source_page",
                item.backlink_info.linked_domains_source_page
              )}
              {renderDetailItem(
                "snippet_left",
                item.backlink_info.snippet_left
              )}
              {renderDetailItem("anchor", item.backlink_info.anchor)}
              {renderDetailItem("url_to", item.backlink_info.url_to)}
              {renderDetailItem("js_crawl", item.backlink_info.js_crawl)}
              {renderDetailItem("http_crawl", item.backlink_info.http_crawl)}
              {renderDetailItem(
                "redirect_kind",
                item.backlink_info.redirect_kind
              )}
              {renderDetailItem(
                "url_redirect",
                item.backlink_info.url_redirect
              )}
              {renderDetailItem(
                "broken_redirect_source",
                item.backlink_info.broken_redirect_source
              )}
              {renderDetailItem(
                "broken_redirect_new_target",
                item.backlink_info.broken_redirect_new_target
              )}
              {renderDetailItem(
                "broken_redirect_reason",
                item.backlink_info.broken_redirect_reason
              )}
              {renderDetailItem("last_seen", item.backlink_info.last_seen)}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
