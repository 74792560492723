import React, { useEffect, useState } from "react";
import useApi from "../../../../utils/useApi";
import { useProjectContext } from "../ProjectContext";
import toast, { Toaster } from "react-hot-toast";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import ReactMarkdown from "react-markdown";
import { LoaderCircle, Save } from "lucide-react";

export default function ProjectTerms() {
  const { request } = useApi();
  const { project_id } = useProjectContext();
  const [terms, setTerms] = useState("");
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const res = await request({
          method: "get",
          url: `projects/${project_id}/data/policy`,
        });
        setTerms(res.data[0].value);
      } catch (err) {
        console.error(err);
      }
    };

    fetchTerms();
  }, [project_id]);

  const updateTerms = async (event) => {
    event.preventDefault();
    setUpdating(true);

    try {
      const formData = new FormData();
      formData.append("key", "policy");
      formData.append("value", terms);
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Project privacy policy updated.");
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          "Operation could not be performed, some error occurred."
      );
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 pt-6">
      <Toaster />
      <div className="flex items-end justify-between mb-5">
        <div>
          <h2 className="font-bold">Privacy Policy</h2>
          <p className="text-gray-500">Set this site's privacy policy.</p>
        </div>
        <button
          type="button"
          onClick={updateTerms}
          className="btnPrimary bg-secondary px-3"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          {isUpdating ? <p>Updating Policy</p> : <p>Save & Update</p>}
        </button>
      </div>
      <MdEditor
        value={terms}
        onChange={({ text }) => setTerms(text)}
        renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
        className="w-full min-h-[calc(100vh-14rem)]"
        placeholder="# This is a markdown editor"
      />
    </div>
  );
}
