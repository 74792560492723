import { ChevronUp, Edit, Trash } from "lucide-react";
import React, { useRef, useState } from "react";
import useApi from "../../utils/useApi";

export default function SubPermission({
  name,
  item,
  isOpen,
  setIsOpen,
  handleUpdatePermissions,
  index,
  getPermissions,
  toast,
}) {
  const { request } = useApi();
  const deleteRef = useRef({});
  const [deleteRl, setDelete] = useState(null);
  const [openSub, setOpenSub] = useState({});

  const deletePermission = async (permission) => {
    try {
      await request({
        method: "delete",
        url: "permissions",
        data: { permission_id: permission._id },
      });
      getPermissions();
      setDelete(null);
      toast.success(`Permission removed.`);
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const toggleSubPermission = (index) => {
    setOpenSub((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const renderSubPermissions = (permissions, level = 0) => {
    return permissions.map((permission, index) => (
      <div
        key={index}
        className={`ml-5 border-t dark:border-white/20 py-1 ${
          level > 0 && "mt-1"
        }`}
      >
        <div
          className={`flex items-center justify-between cursor-pointer`}
          onClick={() =>
            permission?.sub_permissions?.length > 0
              ? toggleSubPermission(index)
              : () => {}
          }
        >
          <p className="capitalize ml-1">{permission?.name}</p>
          {permission?.sub_permissions?.length > 0 && (
            <ChevronUp
              className={`w-5 transition-transform transform text-gray-400 ${
                openSub[index] && "rotate-180"
              }`}
            />
          )}
        </div>
        {openSub[index] && permission?.sub_permissions?.length > 0 && (
          <div
            className={`overflow-hidden pl-5 transition-max-height duration-500 ease-in-out ${
              openSub[index] ? "max-h-screen" : "max-h-0"
            }`}
          >
            {renderSubPermissions(permission.sub_permissions, level + 1)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div
      className={`bg-white dark:bg-gray-800 rounded h-auto px-4 mb-2 ${
        isOpen && "pb-1"
      }`}
    >
      <div
        className={`flex items-center justify-between cursor-pointer py-2`}
        onClick={item?.sub_permissions?.length > 0 ? setIsOpen : () => {}}
      >
        <div className="flex items-center gap-2">
          <div className="relative">
            <Trash onClick={() => setDelete(index)} className="deleteIcon" />
            {deleteRl === index && (
              <div
                ref={(el) => (deleteRef.current[index] = el)}
                className="p-5 w-[400px] absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
              >
                <p className="font-medium">
                  Please confirm to delete permission.
                </p>
                <div className="flex items-center justify-end mt-4">
                  <button onClick={() => setDelete(null)} className="px-6">
                    Cancel
                  </button>
                  <button
                    onClick={() => deletePermission(item)}
                    className="btnPrimary bg-red-500"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </div>
          <Edit
            key={`${name}-action`}
            onClick={() =>
              handleUpdatePermissions({
                _id: item?._id,
                name,
                sub_permissions: item.sub_permissions,
              })
            }
            className="editIcon"
          />
          <p className="capitalize ml-1">{item?.name}</p>
        </div>
        {item?.sub_permissions?.length > 0 && (
          <ChevronUp
            className={`w-5 transition-transform transform text-gray-400 ${
              isOpen && "rotate-180"
            }`}
          />
        )}
      </div>
      <div
        className={`overflow-hidden pl-5 transition-max-height duration-500 ease-in-out ${
          isOpen ? "max-h-screen" : "max-h-0"
        }`}
      >
        {renderSubPermissions(item.sub_permissions)}
      </div>
    </div>
  );
}
