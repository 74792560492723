import React, { useEffect, useState } from "react";
import useApi from "../../utils/useApi";
import SubPermission from "./SubPermission";
import toast, { Toaster } from "react-hot-toast";
import AddUpdatePermission from "./AddUpdatePermission";
import { BadgeCheck, LoaderCircle, Minimize, PlusCircle } from "lucide-react";

export default function Permissions() {
  const { isLoading, request } = useApi();
  const [permissions, setPermissions] = useState([]);

  const getPermissions = async () => {
    try {
      const res = await request({ method: "get", url: "permissions" });
      setPermissions(res.data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const [permission, setPermission] = useState("");
  const [permission_id, setPermission_id] = useState(null);
  const [sub_permissions, setSubPermissions] = useState([]);
  const [addUpdatePermission, showAddUpdatePermission] = useState(false);

  const [modalType, setModalType] = useState("");
  const handleUpdatePermissions = (data) => {
    setPermission(data?.name);
    setSubPermissions(data?.sub_permissions);
    setPermission_id(data?._id);
    showAddUpdatePermission(true);
    setModalType("edit");
  };
  const handleAddPermissions = () => {
    setModalType("Add");
    setPermission("");
    setSubPermissions([]);
    setPermission_id(null);
    showAddUpdatePermission(true);
  };
  const onCloseUpdatePermissions = () => {
    setPermission("");
    setSubPermissions([]);
    setPermission_id(null);
    showAddUpdatePermission(false);
  };

  const [expandedCards, setExpandedCards] = useState({});
  const [isAllExpanded, setIsAllExpanded] = useState(true);

  const handleCollapseAll = () => {
    const collapsedState = {};
    Object.keys(expandedCards).forEach((key) => {
      collapsedState[key] = false;
    });
    setExpandedCards(collapsedState);
    setIsAllExpanded(false);
  };

  const handleExpandAll = () => {
    const expandedState = {};
    Object.keys(expandedCards).forEach((key) => {
      expandedState[key] = true;
    });
    setExpandedCards(expandedState);
    setIsAllExpanded(true);
  };

  const toggleExpandCollapse = () => {
    if (isAllExpanded) {
      handleCollapseAll();
    } else {
      handleExpandAll();
    }
  };

  return (
    <div className="h-[calc(100vh-rem)]">
      <Toaster />
      <div className="flex items-center justify-between mb-5 bg-warmGray dark:bg-gray-800 py-2 px-6">
        <div className="flex items-center text-white gap-2">
          <BadgeCheck className="w-6 h-6" />
          <h4 className="font-bold">Permissions</h4>
        </div>
        <div className="flex items-center justify-end gap-2">
          <button onClick={toggleExpandCollapse} className="btnWhite px-3">
            <Minimize className="w-4 h-4" />{" "}
            {isAllExpanded ? "Collapse All" : "Expand All"}
          </button>
          <button
            onClick={handleAddPermissions}
            type="button"
            className="btnPrimary px-3"
          >
            <PlusCircle className="h-4 w-4" />
            <span className="ml-1">Add permission</span>
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center text-center h-64 flex-col gap-3">
          <LoaderCircle className="animate-spin h-12 w-12 text-primary" />
          Loading Permissions..
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 p-6">
          <div className="border-r border-gray-400 dark:border-white/20 pr-7">
            {permissions?.map((item, index) => {
              return (
                <SubPermission
                  key={index}
                  children={sub_permissions}
                  name={item.name}
                  id={item._id}
                  item={item}
                  handleUpdatePermissions={handleUpdatePermissions}
                  isOpen={expandedCards[index]}
                  setIsOpen={() =>
                    setExpandedCards({
                      ...expandedCards,
                      [index]: !expandedCards[index],
                    })
                  }
                  index={index}
                  toast={toast}
                  getPermissions={getPermissions}
                />
              );
            })}
          </div>

          {addUpdatePermission && (
            <AddUpdatePermission
              permission={permission}
              setPermission={setPermission}
              sub_permissions={sub_permissions}
              setSubPermissions={setSubPermissions}
              showAddUpdatePermission={showAddUpdatePermission}
              getPermissions={getPermissions}
              permission_id={permission_id}
              onClose={onCloseUpdatePermissions}
              toast={toast}
              modalType={modalType}
            />
          )}
        </div>
      )}
    </div>
  );
}
