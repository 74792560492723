import React from "react";
import { CheckCircle, CircleAlert } from "lucide-react";

export default function SelectTemplate({
  image,
  title,
  selectedValue,
  setSelectedValue,
  themeId,
  demoURL,
  createProject,
  page,
  template_type,
}) {
  const handleChange = () => setSelectedValue(themeId);

  return (
    <label className="flex flex-col items-center justify-center relative gap-1 cursor-pointer">
      <div
        className={`bg-white dark:bg-gray-950 transition-all flex-1 w-full flex flex-col rounded ${
          selectedValue === themeId
            ? "shadow-secondary/30 -translate-y-3 shadow-selected"
            : "shadow-md"
        }`}
      >
        <div className="overflow-hidden cursor-pointer relative transition-all h-36 w-full top-0 left-0">
          <img
            src={image}
            alt="Thumbnail Not Found"
            className="absolute top-0 left-0 w-full h-96"
          />
        </div>
        <div className="flex items-center justify-between px-3 py-3">
          <input
            type="radio"
            className="w-4 h-4 hidden"
            checked={selectedValue === themeId}
            onChange={handleChange}
          />
          <div>
            <p className="font-semibold">{title}</p>
            <p className="text-sm text-gray-400">{template_type}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mb-2 px-2">
          <a href={`https://${demoURL}`} target="_blank" rel="noreferrer">
            <button type="button" className="btnPrimary py-1 w-full">
              <CircleAlert className="w-4 h-4" />
              Demo
            </button>
          </a>
          {selectedValue === themeId ? (
            page !== "domain" ? (
              <button
                onClick={(e) => createProject(e, themeId)}
                className="btnPrimary bg-secondary py-1 px-2 text-sm relative"
              >
                Create
              </button>
            ) : (
              <p className="pb-1 text-green-700 border-b border-green-100 rounded px-3 text-center font-medium justify-center flex items-center gap-1">
                Selected
                <CheckCircle className="w-4 h-4" />
              </p>
            )
          ) : (
            <button
              type="button"
              onClick={handleChange}
              className="btnWhite py-1"
            >
              Select
            </button>
          )}
        </div>
      </div>
    </label>
  );
}
