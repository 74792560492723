import React, { useEffect, useState } from "react";
import useApi from "../../utils/useApi";
import { InputField, Modal } from "../../components";
import ListBox from "../../components/common/ListBox";
import toast from "react-hot-toast";

export default function AddUpdateDomain({
  open,
  handleModal,
  cancelButtonRef,
  getDomains,
  modalType,
  domainToUpdate,
  buyTypes,
  registrars,
  niches,
  associations,
  domainStatuses,
  hostingProviders,
  domainTypes,
  filters,
}) {
  const { request } = useApi();
  const [domain, setDomain] = useState("");
  const [price, setPrice] = useState("");
  const [buyType, setBuyType] = useState("");
  const [registrar, setRegistrar] = useState("");
  const [niche, setNiche] = useState("");
  const [association, setAssociation] = useState("");
  const [domainStatus, setDomainStatus] = useState("");
  const [hostingProvider, setHostingProvider] = useState("");
  const [age, setAge] = useState("");
  const [ur, setUr] = useState("");
  const [dr, setDr] = useState("");
  const [backlinks, setBacklinks] = useState("");
  const [refering_domains, setReferringDomains] = useState("");
  const [trust_flow, setTrustFlow] = useState("");
  const [citation_flow, setCitationFlow] = useState("");
  const [oraganic_kw, setOrganicKw] = useState("");
  const [traffic, setTraffic] = useState("");
  const [domain_indexed, setDomainIndexed] = useState(false);
  const [articlesCount, setArticlesCount] = useState("");
  const [domainType, setDomainType] = useState("");
  const [updating, setUpdating] = useState(false);

  const domainIndexes = [
    { _id: 0, name: "yes", val: true },
    { _id: 1, name: "no", val: false },
  ];

  useEffect(() => {
    if (modalType === "update") {
      setBuyType(domainToUpdate?.buy_type_id);
      setDomainType(domainToUpdate?.domain_type_id);
      setNiche(domainToUpdate?.niche_id);
      setDomainStatus(domainToUpdate?.domain_status_id);
      setAssociation(domainToUpdate?.association_id);
      setRegistrar(domainToUpdate?.registrar_id);
      setHostingProvider(domainToUpdate?.hosting_provider_id);

      const dIndex = domainIndexes.find(
        (item) => item.val === domainToUpdate?.domain_indexed
      );
      setDomainIndexed(dIndex);

      setDomain(domainToUpdate.domain || "");
      setPrice(domainToUpdate.price || "");
      setAge(domainToUpdate.age || "");
      setUr(domainToUpdate.ur || "");
      setDr(domainToUpdate.dr || "");
      setBacklinks(domainToUpdate.backLinks || "");
      setReferringDomains(domainToUpdate.refering_domains || "");
      setTrustFlow(domainToUpdate.trust_flow || "");
      setCitationFlow(domainToUpdate.citation_flow || "");
      setOrganicKw(domainToUpdate.oraganic_kw || "");
      setTraffic(domainToUpdate.traffic || "");
      setArticlesCount(domainToUpdate.articles_count || "");
    } else if (modalType === "new") {
      setDomain("");
      setPrice("");
      setBuyType("");
      setRegistrar("");
      setNiche("");
      setAssociation("");
      setDomainStatus("");
      setHostingProvider("");
      setAge("");
      setUr("");
      setDr("");
      setBacklinks("");
      setReferringDomains("");
      setTrustFlow("");
      setCitationFlow("");
      setOrganicKw("");
      setTraffic("");
      setDomainIndexed(false);
      setArticlesCount("");
      setDomainType("");
    }
  }, [modalType, domainToUpdate]);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!domain) {
      errors.domain = "Domain name is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const restoreDomain = async (d) => {
    try {
      await request({
        method: "post",
        url: `domains/${d}`,
      });
      getDomains();
      toast.success("Domain updated.");
    } catch (err) {
      console.log(
        err || "Operation could not be performed, some error occurred."
      );
    }
  };

  const addUpdateDomain = async (e, d) => {
    e.preventDefault();

    console.log("Domain", d);
    setUpdating(true);
    if (!validateForm()) {
      setUpdating(false);
      return;
    }

    try {
      const requestData = {
        domain,
        price,
        age,
        ur,
        dr,
        buy_type_id: buyType?._id,
        registrar_id: registrar?._id,
        domain_type_id: domainType?._id,
        niche_id: niche?._id,
        association_id: association?._id,
        domain_status_id: domainStatus?._id,
        hosting_provider_id: hostingProvider?._id,
        backlinks: parseInt(backlinks),
        refering_domains,
        trust_flow,
        citation_flow,
        oraganic_kw,
        traffic,
        articlesCount: parseInt(articlesCount),
        domain_indexed: domain_indexed?.val,
      };

      if (modalType === "new") {
        await request({
          method: "post",
          url: "domains",
          data: requestData,
        });
      } else if (modalType === "update") {
        await request({
          method: "put",
          url: `domains/${d._id}`,
          data: requestData,
        });
      }
      setDomain("");
      getDomains(filters);
      toast.success("Domain updated.");
      handleModal();
    } catch (err) {
      if (
        err.response.data.message ===
        "This domain has been deleted, do you want to restore it?"
      ) {
        handleModal();
        toast((t) => (
          <div>
            <p>{err.response.data.message}</p>
            <div className="flex items-center justify-end gap-2 mt-2">
              <button
                className="btnPrimary bg-red-500"
                onClick={() => toast.dismiss(t.id)}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  toast.dismiss(t.id);
                  restoreDomain(err.response.data.id);
                }}
                className="btnPrimary"
              >
                Restore
              </button>
            </div>
          </div>
        ));
      } else {
        toast.error(err.response.data.message);
      }
    }
    setUpdating(false);
  };

  return (
    <Modal
      open={open}
      handleModal={handleModal}
      cancelButtonRef={cancelButtonRef}
      className="max-w-5xl"
      title={modalType === "new" ? "Add New Domain" : "Update Domain"}
      handleModalSubmit={
        (modalType === "new" && addUpdateDomain) ||
        (modalType === "update" && ((e) => addUpdateDomain(e, domainToUpdate)))
      }
      modalType="add update"
      disabled={!domain || updating}
      isUpdating={updating}
    >
      <div className="grid grid-cols-5 gap-x-5 gap-y-3">
        <InputField
          name="domain"
          label="Domain Name"
          placeholder="domain.com"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          error={errors.domain}
        />
        <InputField
          name="price"
          label="Price"
          placeholder="0.00"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          error={errors.price}
        />
        <ListBox
          name="buyType"
          label="Buy Type"
          selectedOption={buyType}
          setSelectedOption={setBuyType}
          options={buyTypes}
          placeholder="Choose Type"
          error={errors.buyType}
        />
        <ListBox
          name="domainType"
          label="Domain Type"
          options={domainTypes}
          placeholder="Choose"
          selectedOption={domainType}
          setSelectedOption={setDomainType}
          error={errors.domainType}
        />
        <ListBox
          name="registrar"
          label="Registrar"
          options={registrars}
          placeholder="Choose"
          selectedOption={registrar}
          setSelectedOption={setRegistrar}
          error={errors.registrar}
        />
        <ListBox
          name="niche"
          label="Niche"
          options={niches}
          placeholder="Choose"
          selectedOption={niche}
          setSelectedOption={setNiche}
          error={errors.niche}
        />
        <ListBox
          name="association"
          label="Association"
          options={associations}
          selectedOption={association}
          setSelectedOption={setAssociation}
          placeholder="Choose"
          error={errors.association}
        />
        <ListBox
          name="domainStatus"
          label="Domain Status"
          selectedOption={domainStatus}
          setSelectedOption={setDomainStatus}
          options={domainStatuses}
          placeholder="Choose"
          error={errors.domainStatus}
        />
        <ListBox
          name="hostingProvider"
          label="Hosting Provider"
          selectedOption={hostingProvider}
          setSelectedOption={setHostingProvider}
          options={hostingProviders}
          placeholder="Choose"
          error={errors.hostingProvider}
        />
        <InputField
          name="age"
          label="Age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          error={errors.age}
        />
        <InputField
          name="ur"
          label="UR"
          value={ur}
          onChange={(e) => setUr(e.target.value)}
          error={errors.ur}
        />

        <InputField
          name="dr"
          label="DR"
          value={dr}
          onChange={(e) => setDr(e.target.value)}
          error={errors.dr}
        />

        <InputField
          name="backlinks"
          label="Backlinks"
          value={backlinks}
          onChange={(e) => setBacklinks(e.target.value)}
          error={errors.backlinks}
        />

        <InputField
          name="refering_domains"
          label="Referring Domains"
          value={refering_domains}
          onChange={(e) => setReferringDomains(e.target.value)}
          error={errors.refering_domains}
        />

        <InputField
          name="trust_flow"
          label="Trust Flow"
          value={trust_flow}
          onChange={(e) => setTrustFlow(e.target.value)}
          error={errors.trust_flow}
        />

        <InputField
          name="citation_flow"
          label="Citation Flow"
          value={citation_flow}
          onChange={(e) => setCitationFlow(e.target.value)}
          error={errors.citation_flow}
        />

        <InputField
          name="oraganic_kw"
          label="Organic KW"
          value={oraganic_kw}
          onChange={(e) => setOrganicKw(e.target.value)}
          error={errors.oraganic_kw}
        />

        <InputField
          name="traffic"
          label="Traffic"
          value={traffic}
          onChange={(e) => setTraffic(e.target.value)}
          error={errors.traffic}
        />

        <ListBox
          name="domainIndexed"
          label="Domain Indexed"
          selectedOption={domain_indexed}
          setSelectedOption={setDomainIndexed}
          options={domainIndexes}
          placeholder="Choose"
          error={errors.domainIndexed}
        />

        <InputField
          name="articlesCount"
          label="Articles Count"
          value={articlesCount}
          onChange={(e) => setArticlesCount(e.target.value)}
          error={errors.articlesCount}
        />
      </div>
    </Modal>
  );
}
