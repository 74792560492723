import React, { useEffect, useState, useRef, useCallback } from "react";

const ProjectImage = ({ manageProject, grid, project }) => {
  const [position, setPosition] = useState({ top: "auto", bottom: "auto" });
  const imageRef = useRef(null);

  const updatePosition = useCallback(() => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const newPosition =
        rect.top < 0
          ? { top: "0", bottom: "auto" }
          : rect.bottom > windowHeight
          ? { top: "auto", bottom: "0" }
          : { top: "0", bottom: "auto" };

      setPosition(newPosition);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    window.addEventListener("resize", updatePosition);

    // Initial position update
    updatePosition();

    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [updatePosition]);

  return (
    <div className="relative tempImg">
      <div
        onClick={manageProject}
        className={`${
          grid ? "w-full h-48" : "w-full lg:w-48 h-40 lg:h-full"
        } bg-black rounded relative overflow-hidden cursor-pointer`}
      >
        <img
          src={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${project?.industry_template_id?._id}/thumb/${project?.industry_template_id?.thumbnail}`}
          alt="Thumbnail Not Found"
          className="absolute top-0 left-0 w-full h-auto"
        />
      </div>
      <div
        ref={imageRef}
        className="absolute bg-white left-0 ml-[230px] scale-125 z-50 imgToShow p-1 rounded-md shadow-xl"
        style={position}
      >
        <div className="overflow-hidden cursor-pointer transition-all h-96 overflow-y-scroll relative w-[200px]">
          <img
            src={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${project?.industry_template_id?._id}/thumb/${project?.industry_template_id?.thumbnail}`}
            alt="Thumbnail Not Found"
            className="absolute top-0 left-0 w-full h-auto"
            width={200}
            height={300}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectImage;
