import React, { useEffect, useRef, useState } from "react";
import { SearchBox, SelectBox } from "../../components";
import Pagination from "../../components/common/Pagination";
import * as XLSX from "xlsx";
import {
  Box,
  FileSpreadsheet,
  LoaderCircle,
  PlusCircle,
  RefreshCw,
  SlidersHorizontal,
  Upload,
} from "lucide-react";
import toast from "react-hot-toast";
import useApi from "../../utils/useApi";

export default function Dmheader({
  page,
  pageSize,
  setPageSize,
  isLoading,
  getDomains,
  totalPages,
  setShowFilters,
  handleNew,
  hasPermission,
  handlePageChange,
  showFilters,
  searchQuery,
  handleSearch,
}) {
  const { request } = useApi();
  const excelFormRef = useRef(null);

  // Excel
  const [excel, setExcel] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFileData(event.target.result);
        const workbook = XLSX.read(new Uint8Array(event.target.result), {
          type: "array",
        });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const previewData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setFilePreview(previewData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleUploadExcel = async (event) => {
    event.preventDefault();
    setUploading(true);

    if (!fileData) {
      toast.error("No file selected.");
      setUploading(false);
      return;
    }

    const workbook = XLSX.read(new Uint8Array(fileData), { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);

    try {
      await request({
        method: "post",
        url: "domains/upload_domain_excel",
        data: { jsonData },
      });
      toast.success("Excel data uploaded successfully.");
      getDomains();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    } finally {
      setUploading(false);
      setExcel(false);
      setFileData(null);
      setFilePreview(null);
    }
  };

  const UploadExcelForm = React.forwardRef(
    (
      { excel, setExcel, handleFileChange, handleUploadExcel, filePreview },
      ref
    ) => (
      <div className="relative text-black dark:text-white/80">
        {excel && (
          <form
            onSubmit={handleUploadExcel}
            ref={ref}
            style={{ minWidth: 300 }}
            className="flex-col z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
          >
            <input
              type="file"
              id="excelUpload"
              name="excelUpload"
              className="hidden"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
            />
            {!filePreview && (
              <label
                htmlFor="excelUpload"
                className="btnWhite border py-5 dark:bg-slate-700 relative mb-5"
              >
                Choose File
              </label>
            )}
            {filePreview && (
              <div className="mt-3 overflow-auto h-96 w-[50vw]">
                <table className="table-auto w-full text-sm text-left text-gray-100 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    {filePreview[0] && (
                      <tr>
                        {filePreview[0].map((header, index) => (
                          <th key={index} className="px-4 py-2">
                            {header}
                          </th>
                        ))}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {filePreview.slice(1).map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex} className="px-4 py-2">
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="flex items-center justify-end mt-3 gap-2">
              {filePreview && (
                <label
                  htmlFor="excelUpload"
                  className="btnWhite border-2 h-9 border-black dark:bg-slate-700 relative"
                >
                  Choose another File
                </label>
              )}
              <button
                type="submit"
                className="btnPrimary bg-red-500"
                disabled={uploading}
                onClick={() => setExcel(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btnPrimary bg-black"
                disabled={uploading}
              >
                {uploading ? (
                  <LoaderCircle className="w-4 h-4 animate-spin text-primary" />
                ) : (
                  <Upload className="w-4 h-4" />
                )}
                {uploading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </form>
        )}
        <button
          onClick={() => setExcel(true)}
          className="btnOutlined py-[6px] relative"
        >
          <FileSpreadsheet className="h-4 w-4 text-gray-100 dark:text-gray-300" />
          Upload Excel
        </button>
      </div>
    )
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        excelFormRef.current &&
        !excelFormRef.current.contains(event.target)
      ) {
        setExcel(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-center justify-between gap-5 flex-wrap py-2 bg-warmGray dark:bg-gray-800 px-6">
      <div className="flex items-center gap-2 flex-wrap">
        <div className="flex items-center text-white">
          <Box className="h-6 w-6" />
          <h4 className="ml-2 mr-3 whitespace-nowrap">My Domains</h4>
        </div>
        <SearchBox
          placeholder="Search Domain."
          className="border-white/20 bg-white text-black"
          inputPadding="py-1"
          inputClass="min-w-44"
          name="domain"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="flex items-center justify-end gap-2 flex-wrap-reverse">
        <button className="mr-3 ml-1" onClick={() => getDomains("")}>
          <RefreshCw
            className={`w-5 cursor-pointer text-white dark:text-white/80 ${
              isLoading && "animate-spin"
            }`}
          />
        </button>
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          handlePageChange={handlePageChange}
        />
        <div className="flex items-center gap-2 md:justify-end flex-wrap">
          <SelectBox
            value={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
            options={[250, 100, 50, "all"]}
            buttonClass="py-[5px]"
            inputStyle="text-base"
          />

          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btnOutlined py-[6px]"
          >
            <SlidersHorizontal className="h-4 w-4 text-gray-100 dark:text-gray-300" />
            Filters
          </button>

          {hasPermission("upload excel") && (
            <UploadExcelForm
              excel={excel}
              setExcel={setExcel}
              handleFileChange={handleFileChange}
              handleUploadExcel={handleUploadExcel}
              filePreview={filePreview}
              ref={excelFormRef}
            />
          )}
          {hasPermission("add domain") && (
            <button
              onClick={handleNew}
              className="btnPrimary text-base py-1 px-3"
            >
              <PlusCircle className="h-4 w-4" /> Add domain
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
