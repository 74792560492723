import { ChevronLeft } from "lucide-react";
import React from "react";

export default function SectionHead({ setIsCustomizing, title }) {
  return (
    <div className="flex w-full gap-2 border-b-2 dark:border-white/20 shadow-sm shadow-black/10">
      <button
        onClick={() => setIsCustomizing(false)}
        className="border-r border-gray-300 dark:border-white/20 flex items-center justify-center p-2 hover:cursor-pointer hover:text-primary transition-all"
      >
        <ChevronLeft className="w-7 h-7" />
      </button>
      <div className="p-2">
        <p className="text-gray-500 text-sm">Customizing</p>
        <p className="text-lg font-bold">{title}</p>
      </div>
    </div>
  );
}
