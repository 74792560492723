import React, { useEffect, useRef, useState } from "react";
import { useTemplateContext } from "./TemplateContext";
import { Search } from "lucide-react";

export default function SearchTemplate() {
  const [searchTerm, setSearchTerm] = useState("");
  const { templates } = useTemplateContext();

  const filteredProjects = templates.filter((project) =>
    project.template_name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const inputRef = useRef(null);
  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setSearchTerm("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={inputRef}
      className="flex items-center relative gap-2 py-[3px] bg-gray-700 font-normal w-72 px-2 rounded"
    >
      <Search className="w-4" />
      <input
        className="bg-transparent flex-1 outline-none placeholder:text-gray-200"
        placeholder="Search Templates"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchTerm && (
        <div className="absolute top-0 left-0 py-1 w-full rounded-md flex flex-col bg-white dark:bg-gray-900 dark:text-white/90 shadow-lg mt-8 text-black">
          {filteredProjects.map((project) => (
            <div
              key={project._id}
              className="px-4 py-2 border-b last:border-none dark:border-white/20"
            >
              <p>{project.template_name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
