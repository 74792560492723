import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import useApi from "../../utils/useApi";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";
import { Edit, Gem, PlusCircle, Trash } from "lucide-react";
import { DataTable, InputField, Modal } from "../../components";

export default function Merchants() {
  const { isLoading, request } = useApi();

  const [merchants, setMerchants] = useState([]);
  const getMerchants = async () => {
    try {
      const data = await request({ method: "get", url: "merchants" });
      setMerchants(data.data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getMerchants();
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const [modalType, setModalType] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userId, setUserId] = useState(null);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleAdd = () => {
    setModalType("new");
    handleModal();
  };
  const handleUpdate = (merchant) => {
    setModalType("update");
    setEmail(merchant?.email);
    setPhone(merchant?.phone);
    setAddress(merchant?.address);
    setUserId(merchant?._id);
    setCompanyName(merchant?.company_name);
    setName(merchant?.name);
    handleModal();
  };

  const addMerchant = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "post",
        url: "merchants",
        data: {
          name,
          company_name: companyName,
          email,
          phone,
          address,
        },
      });
      getMerchants();
      toast.success("Merchant created successfully.");
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setEmail("");
    setPhone("");
    setName("");
    setCompanyName("");
    setAddress("");
    handleModal();
  };

  const DeleteAction = React.forwardRef(
    ({ index, deleteInd, setDelete, deleteIndustry, merchant }, ref) => (
      <div className="relative">
        <Trash onClick={() => setDelete(index)} className="deleteIcon" />
        {deleteInd === index && (
          <div
            ref={(el) => (ref.current[index] = el)}
            className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
          >
            <p className="font-medium">
              Please confirm to delete {merchant.merchant_name} merchant
            </p>
            <div className="flex items-center justify-end mt-4">
              <button onClick={() => setDelete(null)} className="px-6">
                Cancel
              </button>
              <button
                onClick={() => deleteMerchant(merchant)}
                className="btnPrimary bg-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );

  const [deleteInd, setDelete] = useState(null);
  const deleteRef = useRef({});
  const deleteMerchant = async (merchant) => {
    try {
      await request({ method: "delete", url: `merchants/${merchant._id}` });
      getMerchants();
      setDelete(null);
      toast.success(`${merchant.merchant_name} merchant removed.`);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const updateMerchant = async (e) => {
    e.preventDefault();
    try {
      await request({
        method: "put",
        url: `merchants/${userId}`,
        data: {
          name,
          email,
          phone,
          address,
          company_name: companyName,
        },
      });
      getMerchants();
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setEmail("");
    setName("");
    setPhone("");
    setAddress("");
    setCompanyName("");
    handleModal();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) setDelete(null);
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { hasPermission } = usePermissions();

  return (
    hasPermission("View Merchants") && (
      <div>
        <Toaster />
        <DataTable
          title={
            <span className="flex items-center gap-2">
              <Gem className="w-6 h-6" />
              Merchants
            </span>
          }
          searchBox
          isLoading={isLoading}
          extras={
            hasPermission("Add Merchant") && (
              <button onClick={handleAdd} className="btnPrimary px-3">
                <PlusCircle className="h-4 w-4" /> Add Merchant
              </button>
            )
          }
          heads={[
            "Sr#",
            hasPermission("Edit Mechant") && "Actions",
            "name",
            "company name",
            "email",
            "phone",
            "domains",
            "address",
            "created by",
            "created at",
            "updated at",
          ]}
          items={merchants?.map((merchant, index) => ({
            serial: index + 1,
            actions: (
              <div className="flex items-center gap-3">
                {/* {hasPermission("delete category") && (
                <DeleteAction
                  index={index}
                  deleteInd={deleteInd}
                  setDelete={setDelete}
                  deleteIndustry={deleteMerchant}
                  merchant={merchant}
                  ref={deleteRef}
                />
              )} */}
                {hasPermission("Edit Mechant") && (
                  <Edit
                    onClick={() => handleUpdate(merchant)}
                    className="editIcon"
                  />
                )}
              </div>
            ),
            name: merchant.name,
            company_name: merchant.company_name,
            email: merchant.email,
            phone: merchant.phone,
            domains: "",
            address: merchant.address,
            createdBy: merchant.createdBy?.username,
            createdAt: dayjs(merchant.createdAt)?.format(
              "ddd, MMM D, YYYY h:mm A"
            ),
            updatedAt: dayjs(merchant.updatedAt)?.format(
              "ddd, MMM D, YYYY h:mm A"
            ),
          }))}
        />

        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-2xl"
          title={
            (modalType === "new" && "Add New Merchant") ||
            (modalType === "update" && "Update Merchant Info")
          }
          handleModalSubmit={
            (modalType === "new" && addMerchant) ||
            (modalType === "update" && updateMerchant)
          }
          modalType="new"
          disabled={!name || !companyName}
        >
          <div className="grid grid-cols-2 gap-5">
            <InputField
              required={true}
              label="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputField
              required={true}
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
            <InputField
              required={true}
              label="Address"
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <InputField
              required={true}
              label="company name"
              name="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <div>
              <label className="inputLabel mb-1">Phone</label>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={handlePhoneChange}
                className="inputField"
              />
            </div>
          </div>
        </Modal>
      </div>
    )
  );
}
