import { LoaderCircle, PanelsTopLeft, Upload } from "lucide-react";

function ProjectActions({
  project,
  hasPermission,
  isDeploying,
  deployProject,
  lastUpdatedAt,
  deployedOn,
  manageProject,
}) {
  function timeAgo(datePast) {
    const now = new Date();
    const past = new Date(datePast);
    const diff = now - past;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return `${seconds} seconds ago`;
    if (minutes < 60) return `${minutes} minutes ago`;
    if (hours < 24) return `${hours} hours ago`;
    if (days < 30) return `${days} days ago`;
    if (months < 12) return `${months} months ago`;
    return `${years} years ago`;
  }

  const timeAgoCreated = timeAgo(project.createdAt);
  const timeAgoUpdated = timeAgo(project.last_updated_at || project.updatedAt);

  return (
    <div className="flex flex-col-reverse lg:flex-col lg:items-end gap-1 lg:p-1">
      <div className="flex items-center justify-end gap-2">
        {hasPermission("deploy project") && project?.is_deployed && (
          <>
            {lastUpdatedAt.getTime() > deployedOn.getTime() && (
              <button
                onClick={deployProject}
                className={`btnPrimary rounded-full px-3 py-1 hover:bg-warmGray hover:text-white ${
                  isDeploying && "bg-warmGray"
                }`}
              >
                {isDeploying ? (
                  <LoaderCircle className="w-4 animate-spin text-primary" />
                ) : (
                  <Upload className="w-4" />
                )}
                {isDeploying
                  ? "Publishing"
                  : project?.is_deployed
                  ? "Publish Changes"
                  : "Publish Project"}{" "}
              </button>
            )}
          </>
        )}
        {hasPermission("manage project") && (
          <button onClick={manageProject} className="btnPrimary bg-coolGray">
            <PanelsTopLeft className="h-4 w-4" />
            Manage Project
          </button>
        )}
      </div>
      <div className="flex items-center justify-end mt-1 gap-3">
        <p className="text-sm text-gray-500 dark:text-gray-400">
          <b>Category:</b>{" "}
          <span className="text-black dark:text-white">
            {project.industry_id.industry_name}
          </span>
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          <b>Template:</b>{" "}
          <span className="text-black dark:text-white">
            {project.industry_template_id.template_name}
          </span>
        </p>
      </div>
      <p className="text-gray-500 dark:text-gray-400 text-sm">
        <b>Created:</b> {timeAgoCreated} <b className="ml-2">By:</b>
        <span className="text-black dark:text-white">
          {project.createdBy.first_name + " " + project.createdBy.last_name}
        </span>
      </p>
      <p className="text-gray-500 dark:text-gray-400 text-sm">
        <b>Last Updated:</b> {timeAgoUpdated}
      </p>
    </div>
  );
}

export default ProjectActions;
