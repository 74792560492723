import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { CheckBox, Modal } from "../../../components";
import useApi from "../../../utils/useApi";
import usePermissions from "../../../utils/userPermission";
import ProjectImage from "./ProjectImage";
import ProjectActions from "./ProjectActions";
import SSLStatus from "./SSLStatus";
import ProjectDetails from "./ProjectDetails";
import ModalContent from "./ModalContent";

// Main ProjectCard component
export default function ProjectCard({
  project,
  grid,
  handleConnect,
  isSelected,
  onSingleSelect,
  select,
  getProjects,
}) {
  const navigate = useNavigate();
  const { request } = useApi();
  const { hasPermission } = usePermissions();

  const [open, setOpen] = useState(true);
  const [isDeploying, setIsDeploying] = useState(false);

  const cancelButtonRef = useRef(null);

  const lastUpdatedAt = new Date(project.last_updated_at);
  const deployedOn = new Date(project.deployed_on);

  const manageProject = () => {
    navigate(`/projects/${project?.slug}`);
  };

  const handleModal = () => {
    setOpen(!open);
  };

  const deployProject = async () => {
    setIsDeploying(true);
    try {
      await request({
        method: "get",
        url: `projects/${project?._id}/deploy`,
      });
      toast.success("Project Deployed");
      getProjects();
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setIsDeploying(false);
    }
  };

  return (
    <div className="flex gap-2 items-start">
      {select && (
        <CheckBox
          checked={isSelected(project._id)}
          className="h-6 w-6"
          boxSize="h-7 w-7"
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: project,
            })
          }
        />
      )}
      <div
        className={`bg-white flex-1 dark:bg-gray-800 rounded shadow-md shadow-black/20 flex ${
          grid ? "flex-col" : "p-2 gap-4 flex-col lg:flex-row"
        }`}
      >
        <ProjectImage
          project={project}
          grid={grid}
          manageProject={manageProject}
        />

        <div
          className={`flex-1 grid ${
            grid
              ? "px-3 pt-3 grid-cols-1 gap-2"
              : "grid-cols-1 xl:grid-cols-3 gap-5"
          }`}
        >
          <div className="flex flex-col gap-1">
            <ProjectDetails
              project={project}
              handleConnect={handleConnect}
              hasPermission={hasPermission}
              deployProject={deployProject}
              isDeploying={isDeploying}
            />
          </div>

          <div className="p-1">
            {project?.domain_id?.cloudflair_details?.status && (
              <SSLStatus project={project} handleModal={handleModal} />
            )}
          </div>

          <ProjectActions
            project={project}
            hasPermission={hasPermission}
            isDeploying={isDeploying}
            deployProject={deployProject}
            lastUpdatedAt={lastUpdatedAt}
            deployedOn={deployedOn}
            manageProject={manageProject}
          />
        </div>

        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-3xl"
          title={
            <span className="text-2xl">Steps to point domain to registrar</span>
          }
        >
          <ModalContent />
        </Modal>
      </div>
    </div>
  );
}
