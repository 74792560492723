import React, { useState } from "react";
import { InputField, ListBox } from "../../components";
import { Search } from "lucide-react";

const DomainFilters = ({
  setShowFilters,
  domainFiltersRef,
  buyTypes,
  registrars,
  niches,
  associations,
  domainStatuses,
  hostingProviders,
  domainTypes,
  getDomains,
  setFilters,
}) => {
  const [domain, setDomain] = useState("");
  const [price, setPrice] = useState("");
  const [buyType, setBuyType] = useState("");
  const [registrar, setRegistrar] = useState("");
  const [niche, setNiche] = useState("");
  const [association, setAssociation] = useState("");
  const [domainStatus, setDomainStatus] = useState("");
  const [hostingProvider, setHostingProvider] = useState("");
  const [age, setAge] = useState("");
  const [ur, setUr] = useState("");
  const [dr, setDr] = useState("");
  const [backlinks, setBacklinks] = useState("");
  const [referringDomains, setReferringDomains] = useState("");
  const [trustFlow, setTrustFlow] = useState("");
  const [citationFlow, setCitationFlow] = useState("");
  const [organicKw, setOrganicKw] = useState("");
  const [traffic, setTraffic] = useState("");
  const [domainIndexed, setDomainIndexed] = useState(false);
  const [articlesCount, setArticlesCount] = useState("");
  const [domainType, setDomainType] = useState("");

  const domainIndexes = [
    { _id: 0, name: "yes", val: true },
    { _id: 1, name: "no", val: false },
  ];

  const applyFilters = () => {
    const rules = [];

    if (domain) rules.push({ field: "domain", op: "cn", data: domain });
    if (price) rules.push({ field: "price", op: "eq", data: Number(price) });
    if (age) rules.push({ field: "age", op: "cn", data: age });
    if (ur) rules.push({ field: "ur", op: "cn", data: ur });
    if (dr) rules.push({ field: "dr", op: "cn", data: dr });
    if (buyType?._id)
      rules.push({ field: "buy_type_id", op: "eq", data: buyType._id });
    if (registrar?._id)
      rules.push({ field: "registrar_id", op: "eq", data: registrar._id });
    if (domainType?._id)
      rules.push({ field: "domain_type_id", op: "eq", data: domainType._id });
    if (niche?._id)
      rules.push({ field: "niche_id", op: "eq", data: niche._id });
    if (association?._id)
      rules.push({ field: "association_id", op: "eq", data: association._id });
    if (domainStatus?._id)
      rules.push({
        field: "domain_status_id",
        op: "eq",
        data: domainStatus._id,
      });
    if (hostingProvider?._id)
      rules.push({
        field: "hosting_provider_id",
        op: "eq",
        data: hostingProvider._id,
      });
    if (backlinks)
      rules.push({ field: "backlinks", op: "cn", data: backlinks });
    if (referringDomains)
      rules.push({
        field: "referring_domains",
        op: "cn",
        data: referringDomains,
      });
    if (trustFlow)
      rules.push({ field: "trust_flow", op: "cn", data: trustFlow });
    if (citationFlow)
      rules.push({ field: "citation_flow", op: "cn", data: citationFlow });
    if (organicKw)
      rules.push({ field: "organic_kw", op: "cn", data: organicKw });
    if (traffic) rules.push({ field: "traffic", op: "cn", data: traffic });
    if (articlesCount)
      rules.push({ field: "articlesCount", op: "cn", data: articlesCount });
    if (domainIndexed?.val !== undefined)
      rules.push({
        field: "domain_indexed",
        op: "eq",
        data: domainIndexed.val,
      });

    const filters = {
      groupOp: "AND",
      rules: rules,
    };

    setFilters(filters);
    getDomains(filters);
  };

  const handleResetFilters = () => {
    setFilters("");
    getDomains("");
  };

  return (
    <div
      ref={domainFiltersRef}
      className="w-full bg-white dark:bg-gray-950 shadow-md shadow-black/20 rounded p-6 mt-3 mb-7"
    >
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-x-4 gap-y-3 mt-4">
        <InputField
          name="domain"
          label="Domain Name"
          placeholder="domain.com"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
        />
        <InputField
          name="price"
          label="Price"
          placeholder="0.00"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <ListBox
          name="buyType"
          label="Buy Type"
          selectedOption={buyType}
          setSelectedOption={setBuyType}
          options={buyTypes}
          placeholder="Choose Type"
        />
        <ListBox
          name="domainType"
          label="Domain Type"
          options={domainTypes}
          placeholder="Choose"
          selectedOption={domainType}
          setSelectedOption={setDomainType}
        />
        <ListBox
          name="registrar"
          label="Registrar"
          options={registrars}
          placeholder="Choose"
          selectedOption={registrar}
          setSelectedOption={setRegistrar}
        />
        <ListBox
          name="niche"
          label="Niche"
          options={niches}
          placeholder="Choose"
          selectedOption={niche}
          setSelectedOption={setNiche}
        />
        <ListBox
          name="association"
          label="Association"
          options={associations}
          selectedOption={association}
          setSelectedOption={setAssociation}
          placeholder="Choose"
        />
        <ListBox
          name="domainStatus"
          label="Domain Status"
          selectedOption={domainStatus}
          setSelectedOption={setDomainStatus}
          options={domainStatuses}
          placeholder="Choose"
        />
        <ListBox
          name="hostingProvider"
          label="Hosting Provider"
          selectedOption={hostingProvider}
          setSelectedOption={setHostingProvider}
          options={hostingProviders}
          placeholder="Choose"
        />
        <InputField
          name="age"
          label="Age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
        <InputField
          name="ur"
          label="UR"
          value={ur}
          onChange={(e) => setUr(e.target.value)}
        />

        <InputField
          name="dr"
          label="DR"
          value={dr}
          onChange={(e) => setDr(e.target.value)}
        />

        <InputField
          name="backlinks"
          label="Backlinks"
          value={backlinks}
          onChange={(e) => setBacklinks(e.target.value)}
        />

        <InputField
          name="referring_domains"
          label="Referring Domains"
          value={referringDomains}
          onChange={(e) => setReferringDomains(e.target.value)}
        />

        <InputField
          name="trust_flow"
          label="Trust Flow"
          value={trustFlow}
          onChange={(e) => setTrustFlow(e.target.value)}
        />

        <InputField
          name="citation_flow"
          label="Citation Flow"
          value={citationFlow}
          onChange={(e) => setCitationFlow(e.target.value)}
        />

        <InputField
          name="organic_kw"
          label="Organic KW"
          value={organicKw}
          onChange={(e) => setOrganicKw(e.target.value)}
        />

        <InputField
          name="traffic"
          label="Traffic"
          value={traffic}
          onChange={(e) => setTraffic(e.target.value)}
        />

        <ListBox
          name="domainIndexed"
          label="Domain Indexed"
          selectedOption={domainIndexed}
          setSelectedOption={setDomainIndexed}
          options={domainIndexes}
          placeholder="Choose"
        />

        <InputField
          name="articlesCount"
          label="Articles Count"
          value={articlesCount}
          onChange={(e) => setArticlesCount(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-end gap-2 mt-5">
        <button
          onClick={() => setShowFilters(false)}
          className="button btnPrimary bg-red-500"
        >
          Close
        </button>
        <button onClick={applyFilters} className="btnPrimary bg-secondary">
          <Search className="w-4 h-4" /> Apply Filters
        </button>
        <button onClick={handleResetFilters} className="btnPrimary ">
          Reset Filters
        </button>
      </div>
    </div>
  );
};

export default DomainFilters;
