import React, { useEffect, useRef, useState } from "react";
import { DataTable, InputField } from "../../components";
import { Edit, PlusCircle, Trash } from "lucide-react";
import useApi from "../../utils/useApi";
import dayjs from "dayjs";
import usePermissions from "../../utils/userPermission";
import toast, { Toaster } from "react-hot-toast";

export default function DomainType() {
  const [domainType, setDomainType] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [updateIndex, setUpdateIndex] = useState(null);
  const dropdownRef = useRef(null);
  const deleteRefs = useRef({});
  const updateRefs = useRef({});
  const { isLoading, request } = useApi();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const fetchDomainType = async () => {
      try {
        const data = await request({
          method: "get",
          url: "masters/domain_type",
        });
        setDomainType(data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDomainType();
  }, []);

  const handleAddDomainType = async (e) => {
    e.preventDefault();
    const value = e.target.input.value;
    try {
      const res = await request({
        method: "post",
        url: "masters/domain_type",
        data: { name: value },
      });
      updateDomainTypeList(res.data);
      toast.success(`${value} Domain type added.`);
      setDropdown(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "Operation could not be performed, some error occurred."
      );
    }
  };

  const handleDeleteDomainType = async (type) => {
    try {
      const res = await request({
        method: "delete",
        url: `masters/domain_type/${type._id}`,
      });
      updateDomainTypeList(res.data);
      toast.success(`${type.name} Domain type removed.`);
      setDeleteIndex(null);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleUpdateDomainType = async (e, type) => {
    e.preventDefault();
    const newValue = e.target.elements.update.value;
    try {
      const res = await request({
        method: "put",
        url: `masters/domain_type/${type._id}`,
        data: { name: newValue },
      });
      updateDomainTypeList(res.data);
      toast.success(`${type.name} Domain type updated.`);
      setUpdateIndex(null);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const updateDomainTypeList = (newRecord) => {
    setDomainType((prevDomainType) => {
      const newData = [...prevDomainType];
      const index = newData.findIndex((item) => item._id === newRecord._id);
      if (index !== -1) {
        newRecord.deleted
          ? newData.splice(index, 1)
          : (newData[index] = newRecord);
      } else {
        newData.push(newRecord);
      }
      return newData;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
      Object.entries(deleteRefs.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setDeleteIndex(null);
        }
      });
      Object.entries(updateRefs.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setUpdateIndex(null);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const form = updateRefs.current[updateIndex];
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [updateIndex]);

  return (
    hasPermission("Domain Type") && (
      <div>
        <Toaster />
        <DataTable
          searchBox
          title="Domain Type"
          isLoading={isLoading}
          heads={["Sr#", "Actions", "Name", "Created At", "Updated At"]}
          items={domainType.map((type, index) => ({
            serial: index + 1,
            actions: (
              <div className="flex items-center gap-2">
                {hasPermission("Delete Domain Type") && (
                  <div className="relative">
                    <Trash
                      onClick={() => setDeleteIndex(index)}
                      className="deleteIcon"
                    />
                    {deleteIndex === index && (
                      <div
                        ref={(el) => (deleteRefs.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
                      >
                        <p className="font-medium">
                          Please confirm to delete Domain type
                        </p>
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setDeleteIndex(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => handleDeleteDomainType(type)}
                            className="btnPrimary bg-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {hasPermission("Edit Domain Type") && (
                  <div className="relative">
                    <Edit
                      onClick={() => setUpdateIndex(index)}
                      className="editIcon"
                    />
                    {updateIndex === index && (
                      <form
                        ref={(el) => (updateRefs.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-xl border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50 w-80"
                        onSubmit={(e) => handleUpdateDomainType(e, type)}
                      >
                        <InputField
                          name="update"
                          label="Name"
                          defaultValue={type.name}
                        />
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setUpdateIndex(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btnPrimary bg-black">
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>
            ),
            name: type.name,
            createdAt: dayjs(type.createdAt).format("ddd, MMM D, YYYY h:mm A"),
            updatedAt: dayjs(type.updatedAt).format("ddd, MMM D, YYYY h:mm A"),
          }))}
          extras={
            hasPermission("Add Domain Type") && (
              <div className="relative text-black dark:text-white/80">
                {dropdown && (
                  <form
                    onSubmit={handleAddDomainType}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
                  >
                    <InputField name="input" label="Name" />
                    <div className="flex items-center justify-end mt-3">
                      <button type="submit" className="btnPrimary bg-black">
                        Add
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdown(!dropdown)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add Domain Type
                </button>
              </div>
            )
          }
        />
      </div>
    )
  );
}
