import React, { useCallback, useEffect, useState } from "react";
import { Camera, Images, LoaderCircle, Save } from "lucide-react";
import { InputField, ListBox, TextArea } from "../../../../../components";
import { useArticleContext } from "../ArticleContext";
import useApi from "../../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectContext } from "../../ProjectContext";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import ReactMarkdown from "react-markdown";
import { v4 as uuidv4 } from "uuid";
import Gallery from "./Gallery";
import TagInput from "../../../../../components/common/TagInput";

export default function ManageArticle() {
  const navigate = useNavigate();
  const { request } = useApi();

  const {
    getBlog,
    blogsList,
    article,
    tagList,
    setArticle,
    fileInfo,
    categories,
    handleFileChange,
  } = useArticleContext();

  const fontSizeOptions = [
    "12px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "48px",
    "64px",
    "72px",
    "80px",
    "96px",
  ];

  const { articleKey } = useParams();
  const { projectToManage, project_id } = useProjectContext();
  const [newArticleKey, setNewArticleKey] = useState(null);

  const viewArticles = () => {
    navigate(
      `/projects/${projectToManage.project_name.replaceAll(" ", "-")}/articles`
    );
  };

  const [article_category, setArticleCategory] = useState();
  useEffect(() => {
    if (articleKey && blogsList?.length > 0) {
      const blogToEdit = blogsList.find((item) => item.key === articleKey);
      getBlog(blogToEdit?.key);
    }
  }, [articleKey, project_id, blogsList]);

  useEffect(() => {
    if (articleKey && blogsList?.length > 0) {
      setArticleCategory(article?.article_category);
    }
  }, [articleKey, project_id, blogsList, article]);

  const handleChange = (e) => {
    setArticle({
      ...article,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = ({ text }) => {
    setArticle({
      ...article,
      articleContent: text,
    });
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const createUpdate = async (event) => {
    event.preventDefault();

    setIsUpdating(true);
    const isUpdate = Boolean(articleKey);
    const uniqueKey = isUpdate
      ? articleKey
      : newArticleKey || `article-${uuidv4()}`;

    if (!isUpdate && !newArticleKey) {
      setNewArticleKey(uniqueKey);
    }

    if (!article_category) {
      toast.error("Please select article category.");
      setIsUpdating(false);
      return;
    }

    const updatedArticle = {
      ...article,
      article_category,
      key: uniqueKey,
    };

    try {
      const formData = new FormData();
      formData.append("key", uniqueKey);
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedArticle));
      if (fileInfo?.file) {
        formData.append("file", fileInfo?.file);
      }

      const res = await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Update the article object with values from the response
      const articleWithResponseData = {
        ...updatedArticle,
        _id: res.data._id,
        key: res.data.key,
        image: res.data.file_name,
        articleContent: updatedArticle?.articleContent?.slice(0, 300),
      };

      // Ensure tagList is an array before spreading it
      const updatedTagsArray = Array.isArray(tagList)
        ? [...tagList, ...(article.tags || [])]
        : [...(article.tags || [])];

      // Create a map to count occurrences of each tag
      const tagCountMap = updatedTagsArray.reduce((acc, tag) => {
        acc[tag] = (acc[tag] || 0) + 1;
        return acc;
      }, {});

      // Transform the map into the desired structure
      const updatedTagList = Object.keys(tagCountMap).map((tag) => ({
        name: tag,
        count: tagCountMap[tag],
      }));

      const tagData = new FormData();
      tagData.append("key", "tag_list");
      tagData.append("value_type", "JSON");
      tagData.append("value", JSON.stringify(updatedTagList));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: tagData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status) {
        const updatedBlogsList = blogsList ? [...blogsList] : [];
        const blogIndex = updatedBlogsList.findIndex(
          (blog) => blog.key === uniqueKey
        );

        if (blogIndex !== -1) {
          updatedBlogsList[blogIndex] = articleWithResponseData;
        } else {
          updatedBlogsList.push(articleWithResponseData);
        }

        const updatedFormData = new FormData();
        updatedFormData.append("key", "blog_list");
        updatedFormData.append("value_type", "JSON");
        updatedFormData.append("value", JSON.stringify(updatedBlogsList));

        await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: updatedFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Your Article is updated.");
      }

      getBlog(res?.data?.key);
    } catch (err) {
      toast.error(err.message);
    }
    setIsUpdating(false);
  };

  const getColorForTitle = (length) => {
    return length >= 50 && length <= 60 ? "text-green-600" : "text-red-500";
  };

  const getColorForDescription = (length) => {
    return length >= 150 && length <= 160 ? "text-green-600" : "text-red-500";
  };

  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = useCallback(() => {
    setSidebar((prevSidebar) => !prevSidebar);
  }, []);

  return (
    <div className="p-5 rounded-md bg-white dark:bg-gray-800">
      <Toaster />
      <div className="flex items-center justify-between">
        <h3>Manage Article</h3>
        <div className="flex items-center justify-end gap-2">
          <button onClick={viewArticles} className="btnPrimary px-3 bg-red-500">
            Close
          </button>
          <button
            disabled={isUpdating}
            type="button"
            onClick={createUpdate}
            className="btnPrimary px-3 bg-green-600 disabled:cursor-default disabled:opacity-90 disabled:bg-primary"
          >
            {isUpdating ? (
              <LoaderCircle className="w-4 h-4 animate-spin" />
            ) : (
              <Save className="w-4 h-4" />
            )}
            {isUpdating ? <p>Updating Article</p> : <p>Save & Update</p>}
          </button>
        </div>
      </div>
      <div>
        <div className="overflow-hidden relative h-36 transition-all w-full rounded-md bg-gray-300 mt-4 border border-white/20 flex items-center justify-end">
          <input
            type="file"
            onChange={handleFileChange}
            id="imageUpload"
            className="hidden"
            accept="image/*"
          />
          <div className="flex items-end z-10 p-3 pt-5 gap-3 absolute right-0 bottom-0 h-fit w-full bg-gradient-to-b from-black/5 to-black">
            <div className="flex-1 grid grid-cols-2 gap-3">
              <InputField
                label="Image Title"
                name="imageTitle"
                placeholder="image title"
                value={article?.imageTitle || ""}
                onChange={handleChange}
                inputStyle="py-1 text-sm w-full bg-transparent text-white border-white/50 placeholder:text-white"
                labelStyle="text-white"
              />
              <InputField
                label="Alt Text"
                name="imageAltText"
                placeholder="image alt text"
                value={article?.imageAltText || ""}
                onChange={handleChange}
                inputStyle="py-1 text-sm w-full bg-transparent text-white border-white/50 placeholder:text-white"
                labelStyle="text-white"
              />
            </div>
            <label
              htmlFor="imageUpload"
              className="btnPrimary py-1 px-3 cursor-pointer flex items-center gap-2"
            >
              Upload Image
              <Camera className="w-4" />
            </label>
          </div>
          <img
            title={"Upload an image"}
            src={fileInfo?.preview}
            loading="lazy"
            alt=""
            className={`w-full h-full object-cover absolute top-0 ${
              fileInfo ? "scale-100" : "scale-125"
            }`}
          />
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div>
            <p className="inputLabel mb-1">
              Background Opacity{" "}
              <span className="font-normal text-primary">
                ({article.opacity})
              </span>{" "}
            </p>
            <div className="w-full flex flex-col items-center border dark:border-white/20 rounded justify-center py-4 px-2">
              <input
                type="range"
                min="0"
                max="100"
                name="opacity"
                value={article.opacity}
                onChange={handleChange}
                className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-lg cursor-pointer"
              />
            </div>
          </div>
          <div>
            <p className="inputLabel mb-1">Text Color</p>
            <input
              type="color"
              name="textColor"
              value={article.textColor}
              onChange={handleChange}
              className="w-full h-[42px] p-1 px-2 border dark:border-white/20 rounded cursor-pointer"
            />
          </div>
          <div>
            <p className="inputLabel mb-1">Title Font Size</p>
            <select
              name="titleFontSize"
              value={article.titleFontSize || "48px"}
              onChange={handleChange}
              className="inputField py-[9px]"
            >
              {fontSizeOptions.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="inputLabel mb-1">Tagline Font Size</p>
            <select
              name="taglineFontSize"
              value={article.taglineFontSize || "48px"}
              onChange={handleChange}
              className="inputField py-[9px]"
            >
              {fontSizeOptions.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
        <InputField
          label="Article Title"
          name="title"
          placeholder="Enter Article title"
          value={article?.title || ""}
          onChange={handleChange}
          className="mt-4"
        />
        <InputField
          label="Tagline"
          name="tagline"
          placeholder="Enter Article tagline"
          className="mt-4"
          value={article?.tagline || ""}
          onChange={handleChange}
        />
        <div className="grid grid-cols-3 gap-4 mt-4 mb-3">
          <InputField
            label="Author Name"
            name="author"
            placeholder="Enter your name"
            value={article?.author || ""}
            onChange={handleChange}
          />
          <InputField
            label="Publishing Date"
            name="published_at"
            type="date"
            value={article?.published_at || ""}
            onChange={handleChange}
          />
          <ListBox
            label="Select Category"
            name="article_category"
            placeholder="Select"
            options={categories?.map((item, index) => ({
              _id: index + 1,
              name: item,
            }))}
            selectedOption={article_category}
            setSelectedOption={setArticleCategory}
          />
        </div>
        <div className="flex items-end justify-between mb-2 mt-1">
          <div>
            <label className="font-semibold text-lg">Article Content</label>
            <p className="text-gray-400 text-sm">
              This is a markdown text editor.
            </p>
          </div>
          <div className="flex items-center justify-end gap-2">
            <button className="btnWhite" onClick={toggleSidebar}>
              <Images className="w-4 h-4" /> Gallery
            </button>
            <a
              target="_blank"
              rel="noreferrer"
              className="btnPrimary bg-black"
              href="https://stackedit.io/app#"
            >
              External Markdown Editor
            </a>
          </div>
        </div>
        <MdEditor
          value={article?.articleContent || ""}
          style={{
            height: `${Math.round(
              (article?.articleContent?.length || 0) / 2
            )}px`,
          }}
          renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
          onChange={handleEditorChange}
          config={{ view: { menu: true, md: true, html: false } }}
          className="min-h-52 text-lg"
        />
        <h4 className="border-b mt-5">SEO</h4>
        <InputField
          label="Meta Title"
          name="meta_title"
          placeholder="Meta Title"
          value={article?.meta_title || ""}
          onChange={handleChange}
          className="my-4"
        />
        <p
          className={`text-sm ${getColorForTitle(
            article?.meta_title?.length || 0
          )}`}
        >
          Character count: {article?.meta_title?.length || 0}{" "}
          {article?.meta_title?.length < 50 || article?.meta_title?.length > 60
            ? "❗"
            : ""}
        </p>
        <strong>Recommended Characters:</strong> 50-60
        <TextArea
          label="Meta Description"
          name="meta_description"
          value={article?.meta_description || ""}
          onChange={handleChange}
          placeholder="Meta Description"
        />
        <p
          className={`text-sm ${getColorForDescription(
            article?.meta_description?.length || 0
          )}`}
        >
          Character count: {article?.meta_description?.length || 0}{" "}
          {article?.meta_description?.length < 150 ||
          article?.meta_description?.length > 160
            ? "❗"
            : ""}
        </p>
        <strong>Recommended characters:</strong> 150-160
      </div>

      <div className="flex flex-wrap items-center mt-3">
        <TagInput
          tags={article?.tags || []}
          setTags={(tags) => setArticle({ ...article, tags })}
        />
      </div>

      <div className="flex items-center justify-end gap-2 mt-5">
        <button onClick={viewArticles} className="btnPrimary px-3 bg-red-500">
          Close
        </button>
        <button
          disabled={isUpdating}
          type="button"
          onClick={createUpdate}
          className="btnPrimary px-3 bg-green-600 disabled:cursor-default disabled:opacity-90 disabled:bg-primary"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          {isUpdating ? <p>Updating Article</p> : <p>Save & Update</p>}
        </button>
      </div>

      <Gallery
        project_id={project_id}
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
      />
    </div>
  );
}
