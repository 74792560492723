import React, { useEffect, useRef, useState } from "react";
import {
  Edit,
  LoaderCircle,
  Plus,
  Save,
  ShieldAlertIcon,
  Trash,
  X,
} from "lucide-react";
import dayjs from "dayjs";
import Dropdown from "./Dropdown";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../../utils/useApi";
import { useArticleContext } from "../ArticleContext";
import { useTemplateContext } from "../../TemplateContext";
import usePermissions from "../../../../../utils/userPermission";
import { InputField } from "../../../../../components";

export default function ArticleCard({
  blog,
  index,
  deleteConfirmation,
  setDeleteConfirmation,
}) {
  const {
    grid,
    deleteBlog,
    deletingArticle,
    deleteFromList,
    blogsList,
    industry_id,
    handleUpdateBlog,
  } = useArticleContext();

  const { template_id } = useTemplateContext();
  const [blogTags, setBlogTags] = useState(blog.tags || []);
  const [input, setInput] = useState("");
  const [tagsModal, setTagsModal] = useState(false);
  const [action, setAction] = useState(false);
  const tagsModalRef = useRef(null);

  const { request } = useApi();

  const addTag = (e) => {
    if ((e.key === "Enter" || e.type === "click") && input.trim() !== "") {
      setBlogTags([...blogTags, input.trim()]);
      setInput("");
    }
  };

  const removeTag = (index) => {
    setBlogTags(blogTags.filter((_, i) => i !== index));
  };

  const handleSaveTags = async () => {
    setAction(true);
    const updatedBlog = { ...blog, tags: blogTags };
    const updatedBlogsList = (blogsList || [])?.map((blogItem) =>
      blogItem._id === updatedBlog._id ? updatedBlog : blogItem
    );
    const blogIndex = updatedBlogsList.findIndex(
      (blogItem) => blogItem.key === updatedBlog.key
    );

    let allTags = [];

    updatedBlogsList.forEach((article) => {
      const tags = Array.isArray(article?.tags) ? article.tags : [];
      allTags = [...allTags, ...tags];
    });
    const uniqueTags = [...new Set(allTags)];

    const tagArticleMapping = uniqueTags.map((tag) => {
      const article_ids = updatedBlogsList
        .filter(
          (article) => Array.isArray(article.tags) && article.tags.includes(tag)
        )
        .map((article) => article.key);
      return { tag, article_ids };
    });

    const tagData = new FormData();
    tagData.append("key", "tag_list");
    tagData.append("value_type", "JSON");
    tagData.append("value", JSON.stringify(tagArticleMapping));
    await request({
      method: "post",
      url: `industries/${industry_id}/templates/${template_id}/data`,
      data: tagData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (blogIndex !== -1) {
      updatedBlogsList[blogIndex] = updatedBlog;

      try {
        const formData = new FormData();
        formData.append("key", "blog_list");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedBlogsList));

        await request({
          method: "post",
          url: `industries/${industry_id}/templates/${template_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Article tags updated successfully.");
        setDeleteConfirmation(false);
        setAction(false);
      } catch (err) {
        toast.error("Error updating blog: " + err.message);
      }
    }
  };

  const { hasPermission } = usePermissions();

  const handleClickOutside = (event) => {
    if (tagsModalRef.current && !tagsModalRef.current.contains(event.target)) {
      setTagsModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`bg-white dark:bg-gray-800 shadow-lg rounded  ${
        grid ? "flex flex-col" : "grid grid-cols-articleCard"
      }`}
    >
      <Toaster />
      <div
        className={`overflow-hidden cursor-pointer relative rounded ${
          grid ? "h-40" : "h-full"
        }`}
      >
        <img
          src={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template_id}/${blog?.image}`}
          alt="no article coverphoto"
          className="object-fill absolute m-auto min-w-full min-h-full"
        />
      </div>
      <div className="py-2 px-3 flex flex-col justify-between flex-1">
        <div>
          <div className="flex items-center flex-wrap justify-between gap-2">
            <p className="capitalize font-semibold leading-tight mt-1">
              {blog?.title}
            </p>
            <div className="flex items-center flex-wrap justify-end gap-2">
              <p className="dark:text-white/80 text-xs text-gray-500">
                {blog?.author} -{" "}
                {dayjs(blog?.published_at)?.format("MMM D, YYYY")}
              </p>
              {hasPermission("Delete Project Article") && (
                <Trash
                  onClick={() => deleteBlog(blog)}
                  className="deleteIcon"
                />
              )}
              {hasPermission("Edit Project Article") && (
                <Edit
                  onClick={() => handleUpdateBlog(blog)}
                  className="editIcon"
                />
              )}
              <Dropdown blog={blog} index={index} deleteBlog={deleteBlog} />
            </div>
          </div>
          <p className="text-xs text-gray-400 dark:text-white/80 mt-1">
            {grid ? blog?.articleContent?.slice(0, 110) : blog?.articleContent}
            ...
          </p>
        </div>
        <div className="flex flex-wrap mt-2 gap-1">
          <p className="font-medium text-sm text-gray-500 dark:text-white/80">
            Category:
          </p>
          <span className="text-sm capitalize">
            {blog?.article_category?.name}
          </span>
          {!grid && (
            <>
              <div className="flex items-center gap-1 ml-5">
                <p className="font-medium text-sm">Tags:</p>
              </div>
              {blogTags.map((tag, index) => (
                <span
                  key={index}
                  className="flex items-center text-xs px-2 bg-orange-100 text-orange-600 rounded gap-2"
                >
                  {tag}
                </span>
              ))}

              <button
                type="button"
                onClick={() => setTagsModal(true)}
                className="flex items-center text-xs font-medium mx-1 gap-1 hover:text-primary hover:border-primary border-b border-black transition-all"
              >
                <Plus className="w-4 h-4" /> Add/Edit Tags
              </button>
            </>
          )}
        </div>
      </div>

      {deleteConfirmation && (
        <div className="flex items-center justify-center fixed top-0 left-0 bg-gray-400/10 dark:bg-black/10 h-screen w-full z-50">
          <div className="bg-white dark:bg-gray-800 dark:text-white px-10 py-6 rounded-xl flex flex-col items-center text-center">
            <ShieldAlertIcon className="h-12 w-14 text-yellow-400 mb-3" />
            <p className="text-lg">
              Are you sure? You want to delete this article.
            </p>
            <div className="flex items-center justify-center gap-6 mt-6">
              <button onClick={() => setDeleteConfirmation(false)}>
                Cancel
              </button>
              <button
                onClick={(e) => deleteFromList(e)}
                className="btnPrimary bg-red-500"
              >
                {deletingArticle ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {deletingArticle ? <p>Deleting</p> : <p>Delete Article</p>}
              </button>
            </div>
          </div>
        </div>
      )}

      {tagsModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 dark:bg-black/50 bg-opacity-75 transition-opacity">
          <div
            ref={tagsModalRef}
            className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-2xl dark:border-white/20 max-w-lg w-full"
          >
            <h3 className="font-bold border-b mb-6 pb-2">Article Tags</h3>
            <div className="flex items-end gap-3">
              <InputField
                label="new tag"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={addTag}
                placeholder="Add a tag and press Enter"
              />
              <button
                type="button"
                className="btnPrimary text-base"
                disabled={!input}
                onClick={addTag}
              >
                Add
              </button>
            </div>

            <div className="flex items-center gap-2 mt-3">
              {blogTags.map((tag, index) => (
                <span
                  key={index}
                  className="flex items-center px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded gap-2"
                >
                  {tag}
                  <X
                    className="w-4 h-4 text-red-400 hover:text-red-500 cursor-pointer"
                    onClick={() => removeTag(index)}
                  />
                </span>
              ))}
            </div>

            <div className="flex items-center justify-end mt-7 gap-2">
              <button
                type="button"
                title="close"
                onClick={() => setTagsModal(false)}
                className="btnPrimary bg-red-400 hover:bg-red-500"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={action}
                onClick={handleSaveTags}
                className="btnPrimary bg-secondary px-3"
              >
                {action ? (
                  <LoaderCircle className="w-4 animate-spin" />
                ) : (
                  <Save className="w-4 h-4" />
                )}
                {action ? <p>Updating Tags</p> : <p>Save & Update</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
