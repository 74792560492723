import React from "react";
import { InputField } from "../../../components";

export default function ReportFilters() {
  return (
    <div className="p-6">
      <div className="w-full bg-white dark:bg-gray-950 shadow-md shadow-black/20 rounded p-6 mt-3 mb-7">
        <div className="grid grid-cols-4 xl:grid-cols-6 gap-4">
          <InputField label="URL From" />
          <InputField label="Title" />
          <InputField label="Languages" />
          <InputField label="Powered By" />
          <InputField label="Link Type" />
          <InputField label="Redirect Code" />
          <InputField label="First Seen Link" />
          <InputField label="Lost Reason" />
          <InputField label="Drop Reason" />
          <InputField label="Http Code" />
          <InputField label="Discovered Status" />
          <InputField label="Source Page Author" />
          <InputField label="Is do follow" />
          <InputField label="Is no follow" />
          <InputField label="Is UGC" />
          <InputField label="Is Sponsered" />
          <InputField label="Is Content" />
          <InputField label="Domain Rating Source" />
          <InputField label="Traffic Domain" />
          <InputField label="Is route source" />
          <InputField label="route name source" />
          <InputField label="Traffic" />
          <InputField label="Positions" />
          <InputField label="Link External" />
          <InputField label="URL Rating Source" />
          <InputField label="Last Visited" />
          <InputField label="Refdomains source" />
          <InputField label="Linked domains source page" />
          <InputField label="Snippet Left" />
          <InputField label="Anchor" />
          <InputField label="URL to" />
          <InputField label="JS Crawl" />
          <InputField label="Http Crawl" />
          <InputField label="Redirect Kind" />
          <InputField label="URL redirect" />
          <InputField label="Broken Redirect Source" />
          <InputField label="Broken redirect new target" />
          <InputField label="broken redirect reason" />
          <InputField label="last seen" />
        </div>
      </div>
    </div>
  );
}
