import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import useApi from "../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";
import { DataTable, InputField } from "../../components";
import {
  Bot,
  Edit,
  LoaderCircle,
  LucidePlusCircle,
  PencilLine,
  PlusCircle,
  Trash,
  Ungroup,
} from "lucide-react";

export default function Niche() {
  const [niches, setNiches] = useState([]);
  const [trashNiches, setTrashNiches] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [actionIndex, setActionIndex] = useState({
    delete: null,
    update: null,
    niche: null,
    deletedActions: null,
  });
  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [action, setAction] = useState(false);
  const [selectedNiche, setSelectedNiche] = useState(null);

  const dropdownRef = useRef(null);
  const deleteRefs = useRef({});
  const updateRefs = useRef({});
  const nicheRefs = useRef({});
  const deletedActionsRef = useRef({});
  const confirmModalRef = useRef(null);

  const { isLoading, error, request } = useApi();
  const { hasPermission } = usePermissions();

  const fetchNiches = async () => {
    try {
      const [nichesRes, trashNichesRes] = await Promise.all([
        request({ method: "get", url: "masters/niche" }),
        request({ method: "get", url: "masters/niche/trash" }),
      ]);
      setNiches(nichesRes.data);
      setTrashNiches(trashNichesRes.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchNiches();
  }, []);

  const list = [...niches, ...trashNiches];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
    Object.entries(nicheRefs.current).forEach(([key, ref]) => {
      if (ref && !ref.contains(event.target)) {
        setActionIndex((prev) => ({ ...prev, niche: null }));
      }
    });
    Object.entries(deleteRefs.current).forEach(([key, ref]) => {
      if (ref && !ref.contains(event.target)) {
        setActionIndex((prev) => ({ ...prev, delete: null }));
      }
    });
    Object.entries(updateRefs.current).forEach(([key, ref]) => {
      if (ref && !ref.contains(event.target)) {
        setActionIndex((prev) => ({ ...prev, update: null }));
      }
    });
    Object.entries(deletedActionsRef.current).forEach(([key, ref]) => {
      if (ref && !ref.contains(event.target)) {
        setActionIndex((prev) => ({ ...prev, deletedActions: null }));
      }
    });
    if (
      confirmModalRef.current &&
      !confirmModalRef.current.contains(event.target)
    ) {
      setShowConfirmModal(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const form = updateRefs.current[actionIndex.update];
      if (form) {
        form.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [actionIndex.update]);

  const [adding, setAdding] = useState(false);
  const handleAddNiche = async (e) => {
    e.preventDefault();

    setAction(true);
    const value = e.target.input.value;
    try {
      const res = await request({
        method: "post",
        url: "masters/niche",
        data: { name: value },
      });
      // updateNichesList(res.data);
      fetchNiches();
      toast.success(`${value} niche added.`);
      setDropdownVisible(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || `Couldn't add ${value} niche.`
      );
    }
    setAction(false);
  };

  const handleDeleteNiche = async (niche) => {
    setAction(true);
    try {
      const res = await request({
        method: "delete",
        url: `masters/niche/${niche._id}`,
      });
      // updateNichesList(res.data);
      fetchNiches();
      toast.success(`${niche.name} niche removed.`);
      setActionIndex((prev) => ({ ...prev, delete: null }));
    } catch (err) {
      toast.error(error || `Couldn't remove ${niche.name} niche.`);
    }
    setAction(false);
  };

  const handleUpdateNiche = async (e, niche) => {
    e.preventDefault();

    setAction(true);
    const newValue = e.target.elements.update.value;
    try {
      const res = await request({
        method: "put",
        url: `masters/niche/${niche._id}`,
        data: { name: newValue },
      });
      updateNichesList(res.data);
      toast.success(`${niche.name} niche updated.`);
      setActionIndex((prev) => ({ ...prev, update: null }));
    } catch (err) {
      toast.error(
        err?.response?.data?.message || `Couldn't update ${niche.name} niche.`
      );
    }
    setAction(false);
  };

  const handleDeleteNichePermanently = async () => {
    setAction(true);
    try {
      await request({
        method: "delete",
        url: `masters/niche/trash/${selectedNiche?._id}`,
      });
      fetchNiches();
      toast.success(`${selectedNiche?.name} niche removed.`);
      setShowConfirmModal(false);
      setSelectedNiche(null);
    } catch (err) {
      toast.error(
        err.response.data.message ||
          `Couldn't remove ${selectedNiche?.name} niche.`
      );
    }
    setAction(false);
  };

  const handleRestore = async () => {
    setAction(true);
    try {
      await request({
        method: "put",
        url: `masters/niche/trash/${selectedNiche?._id}`,
      });
      fetchNiches();
      toast.success(`${selectedNiche?.name} niche removed.`);
      setShowConfirmModal(false);
      setSelectedNiche(null);
    } catch (err) {
      toast.error(
        err.response.data.message ||
          `Couldn't remove ${selectedNiche?.name} niche.`
      );
    }
    setAction(false);
  };

  const updateNichesList = (newRecord) => {
    setNiches((prevNiches) => {
      const newData = [...prevNiches];
      const index = newData.findIndex((item) => item._id === newRecord._id);
      if (index !== -1) {
        newRecord.deleted
          ? newData.splice(index, 1)
          : (newData[index] = newRecord);
      } else {
        newData.push(newRecord);
      }
      return newData;
    });
  };

  const renderActionButtons = (niche, index) => (
    <div className="flex items-center gap-2">
      {hasPermission("Edit Niche") && (
        <div className="relative">
          <Edit
            onClick={() =>
              setActionIndex((prev) => ({ ...prev, update: index }))
            }
            className="editIcon"
          />
          {actionIndex.update === index && (
            <form
              ref={(el) => (updateRefs.current[index] = el)}
              className={`p-5 absolute ${
                index >= list.length - 3 ? "bottom-0" : "top-0"
              } left-0 ml-5 rounded-md shadow-xl border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50 w-80`}
              onSubmit={(e) => handleUpdateNiche(e, niche)}
            >
              <InputField
                name="update"
                label="Name"
                defaultValue={niche.name}
              />
              <div className="flex items-center justify-end mt-4">
                <button
                  onClick={() =>
                    setActionIndex((prev) => ({ ...prev, update: null }))
                  }
                  className="px-6"
                >
                  Cancel
                </button>
                <button
                  title="Update Niche"
                  disabled={action}
                  type="submit"
                  className="btnPrimary bg-black"
                >
                  {action ? (
                    <LoaderCircle className="w-4 h-4 animate-spin" />
                  ) : (
                    <PencilLine className="w-4 h-4" />
                  )}
                  {action ? <p>Updating</p> : <p>Update</p>}
                </button>
              </div>
            </form>
          )}
        </div>
      )}
      {hasPermission("Delete Niche") && (
        <div className="relative">
          {!niche.deleted && (
            <Trash
              onClick={() =>
                setActionIndex((prev) => ({ ...prev, delete: index }))
              }
              className="deleteIcon"
            />
          )}
          {actionIndex.delete === index && (
            <div
              ref={(el) => (deleteRefs.current[index] = el)}
              className={`p-5 absolute left-0 ml-5 ${
                index >= list.length - 3 ? "bottom-0" : "top-0"
              }  rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50`}
            >
              <p className="font-medium">
                Please confirm to delete {niche.name} niche
              </p>
              <div className="flex items-center justify-end mt-4">
                <button
                  onClick={() =>
                    setActionIndex((prev) => ({ ...prev, delete: null }))
                  }
                  className="px-6"
                >
                  Cancel
                </button>
                <button
                  title="Delete Niche"
                  disabled={action}
                  onClick={() => handleDeleteNiche(niche)}
                  className="btnPrimary bg-red-500"
                >
                  {action ? (
                    <LoaderCircle className="w-4 h-4 animate-spin" />
                  ) : (
                    <Trash className="w-4 h-4" />
                  )}
                  {action ? <p>Deleting</p> : <p>Delete Niche</p>}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderNicheDomainsPopup = (index, niche) => {
    const positionClass = index >= list.length - 3 ? "bottom-0" : "top-0";
    return (
      <div
        ref={(el) => (nicheRefs.current[index] = el)}
        className={`p-5 absolute ${positionClass} left-0 ml-5 flex flex-col gap-1 max-h-96 overflow-y-scroll rounded-md shadow-lg shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50`}
      >
        {niche.domains?.map((item, i) => (
          <a
            key={i}
            href={`http://${item.domain}`}
            target="_blank"
            rel="noreferrer"
            className="underline hover:text-primary transition-all cursor-pointer"
          >
            <p>{item.domain}</p>
          </a>
        ))}
      </div>
    );
  };

  const [updateIndex] = useState(null);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const form = updateRefs.current[updateIndex];
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [updateIndex]);

  return (
    hasPermission("View Niches") && (
      <div>
        <Toaster />
        <DataTable
          searchBox
          title={
            <span className="flex items-center gap-2">
              <Ungroup className="w-6 h-6" />
              Niches
            </span>
          }
          isLoading={isLoading}
          heads={[
            "Sr#",
            ...(hasPermission("Edit Niche") || hasPermission("Delete Niche")
              ? ["Actions"]
              : []),
            "Name",
            "Domains",
            "Created At",
            "Updated At",
          ]}
          items={list.map((niche, index) => {
            const item = {
              serial: index + 1,
              name: (
                <div className="flex items-center gap-1">
                  {niche.deleted && (
                    <button
                      onClick={() =>
                        setActionIndex((prev) => ({
                          ...prev,
                          deletedActions: index,
                        }))
                      }
                      className="relative"
                    >
                      <p className="text-red-500 hover:text-red-100 bg-red-100 hover:bg-red-500 transition-all text-sm font-medium rounded-full w-fit text-center py-[1px] px-2">
                        Deleted
                      </p>
                      {actionIndex.deletedActions === index && (
                        <div
                          ref={(el) => (deletedActionsRef.current[index] = el)}
                          className={`p-1 absolute left-0 ${
                            index >= list.length - 3 ? "bottom-0" : "top-0"
                          }  ml-10 mt-4 flex flex-col gap-1 rounded-md shadow-lg shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50`}
                        >
                          <button
                            title="Delete Permanently"
                            onClick={() => {
                              setSelectedNiche(niche);
                              setShowConfirmModal("deleteP");
                            }}
                            className="btnDropdown"
                          >
                            Delete Permanently
                          </button>
                          <button
                            title="Restore"
                            onClick={() => {
                              setSelectedNiche(niche);
                              setShowConfirmModal("restore");
                            }}
                            className="btnDropdown"
                          >
                            Restore
                          </button>
                        </div>
                      )}
                    </button>
                  )}
                  {niche.name}
                </div>
              ),
              domains: (
                <div className="relative">
                  <p
                    onClick={() =>
                      setActionIndex((prev) => ({ ...prev, niche: index }))
                    }
                    className="hover:text-primary cursor-pointer"
                  >
                    {niche.domains?.length}
                  </p>
                  {actionIndex.niche === index &&
                    renderNicheDomainsPopup(index, niche)}
                </div>
              ),
              createdAt: dayjs(niche.createdAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              updatedAt: dayjs(niche.updatedAt).format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };

            if (hasPermission("Edit Niche") || hasPermission("Delete Niche")) {
              return {
                serial: item.serial,
                action: renderActionButtons(niche, index),
                ...item,
              };
            }

            return item;
          })}
          extras={
            hasPermission("Add Niche") && (
              <div className="relative text-black dark:text-white/80">
                {dropdownVisible && (
                  <form
                    onSubmit={handleAddNiche}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md shadow-xl"
                  >
                    <InputField name="input" label="Name" />
                    <div className="flex items-center justify-end mt-3">
                      <button
                        type="submit"
                        disabled={adding}
                        className="btnPrimary bg-black"
                      >
                        {adding ? (
                          <LoaderCircle className="w-4 h-4 animate-spin" />
                        ) : (
                          <LucidePlusCircle className="w-4 h-4" />
                        )}
                        {adding ? <p>Adding</p> : <p>Add Niche</p>}
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add Niche
                </button>
              </div>
            )
          }
        />

        {showConfirmModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 dark:bg-black/50 bg-opacity-75 transition-opacity">
            <div
              ref={confirmModalRef}
              className="bg-white dark:bg-gray-800 p-5 rounded-md shadow-2xl border-2 border-gray-200 dark:border-white/20"
            >
              <p className="font-medium">
                Are you sure you want to{" "}
                {showConfirmModal === "deleteP" && "permanently delete"}{" "}
                {showConfirmModal === "restore" && "restore"} this niche?
              </p>
              <div className="flex items-center justify-end mt-4">
                <button
                  onClick={() => setShowConfirmModal(null)}
                  className="px-6"
                >
                  Cancel
                </button>
                {showConfirmModal === "deleteP" && (
                  <button
                    disabled={action}
                    onClick={handleDeleteNichePermanently}
                    className="btnPrimary bg-red-500"
                  >
                    {action ? (
                      <LoaderCircle className="w-4 h-4 animate-spin" />
                    ) : (
                      <Trash className="w-4 h-4" />
                    )}
                    {action ? (
                      <p>Deleting Permanently</p>
                    ) : (
                      <p>Delete Permanently</p>
                    )}
                  </button>
                )}
                {showConfirmModal === "restore" && (
                  <button
                    disabled={action}
                    onClick={handleRestore}
                    className="btnPrimary bg-secondary px-3 gap-2"
                  >
                    {action ? (
                      <LoaderCircle className="w-4 h-4 animate-spin" />
                    ) : (
                      <Bot className="w-4 h-4" />
                    )}
                    {action ? <p>Restoring</p> : <p>Restore</p>}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
}
