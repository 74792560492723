import React from "react";
import { useArticleContext } from "../ArticleContext";
import MarkdownIt from "markdown-it";

export default function ArticlePreview() {
  const { article, fileInfo } = useArticleContext();
  const markdownIt = new MarkdownIt();
  const convertMarkdown = (markdownText) => markdownIt?.render(markdownText);

  return (
    <div>
      <h1 className="text-5xl font-extrabold">
        {article?.title || "New Article Title"}
      </h1>
      <p className="mt-5 text-2xl font-bold text-slate-500">
        {article?.tagline || "New article tagline"}
      </p>

      <div className="flex items-center gap-10 text-sm mt-3 font-bold">
        <p>
          By{" "}
          <span className="underline">{article?.author || "author name"}</span>
        </p>
        <p>PUBLISHED: {article?.published_at}</p>
      </div>

      <div className="overflow-hidden relative h-72 lg:h-80 w-full bg-black mt-5 rounded-md">
        <img
          src={fileInfo?.preview}
          title={article?.imageTitle}
          fill={true}
          loading="lazy"
          alt="blog"
          className="w-full h-full object-cover absolute top-0 scale-125"
        />
      </div>
      <p className="text-end text-xs text-gray-500">
        {article?.imageTitle || "image title"}
      </p>
      <div
        className="prose mt-5 max-w-full dark:!text-white/80"
        dangerouslySetInnerHTML={{
          __html: convertMarkdown(
            article?.articleContent || "Article content here."
          ),
        }}
      />
    </div>
  );
}
