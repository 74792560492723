import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Group,
  LayoutTemplate,
  LoaderCircle,
  Sparkles,
  SquareGanttChart,
  Users,
} from "lucide-react";
import useApi from "../../utils/useApi";
import usePermissions from "../../utils/userPermission";

const FancyCounter = ({ count }) => {
  const [displayedCount, setDisplayedCount] = useState(0);

  useEffect(() => {
    let timer;
    if (displayedCount < count) {
      timer = setTimeout(() => {
        setDisplayedCount(displayedCount + 1);
      }, 5);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [displayedCount, count]);

  return <>{displayedCount}</>;
};

export default function Home() {
  const user = JSON.parse(localStorage.getItem("user"));

  const { request, isLoading } = useApi();
  const [templates, setTemplates] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [domains, setDomains] = useState([]);

  const fetchData = async (url, setData) => {
    try {
      const data = await request({ method: "get", url });
      setData(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData("industries/All/templates", setTemplates);
    fetchData("industries", setIndustries);
    fetchData("projects", setProjects);
    fetchData("users", setUsers);
    fetchData("merchants", setMerchants);

    const getDomains = async () => {
      try {
        const res = await request({
          method: "post",
          url: "domains/report",
          data: {
            page: "",
            size: "",
            filters: "",
            sort_field: "",
            sort_by: "",
          },
        });
        setDomains(res.totalItems);
      } catch (err) {
        console.error(err);
      }
    };

    getDomains();
  }, []);

  const { hasPermission } = usePermissions();

  const renderLink = (to, icon, label, count, className) => (
    <Link
      to={to}
      className={`group flex items-center justify-between gap-3 p-7 bg-white dark:bg-gray-800 hover:bg-warmGray dark:hover:bg-white dark:hover:text-black hover:text-white transition-all ${className}`}
    >
      <div>
        <div
          className={`rounded-full w-10 h-10 bg-primary text-white flex items-center justify-center`}
        >
          {icon}
        </div>
        <p className="mt-2 text-gray-600 dark:text-white/80 text-sm font-semibold group-hover:text-white/90 dark:group-hover:text-gray-600">
          {label}
        </p>
      </div>
      <div>
        {isLoading ? (
          <LoaderCircle className="h-9 w-9 animate-spin text-gray-500" />
        ) : (
          <p className="font-bold text-5xl mt-3">
            <FancyCounter count={count} />
          </p>
        )}
      </div>
    </Link>
  );

  return (
    <div className="pt-6 px-6 flex flex-col items-center">
      <div className="max-w-screen-lg w-full">
        <div>
          <p className="text-4xl font-bold">
            Hello,{" "}
            <span className="font-normal capitalize">{user?.first_name}</span>
          </p>
          <p className="text-lg font-medium mt-2">Welcome back, on board</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 mt-5 rounded dark:divide-white/20 gap-1">
          {hasPermission("View Projects") &&
            renderLink(
              "/projects",
              <SquareGanttChart className="w-5" />,
              "Projects",
              projects.length
            )}
          {hasPermission("View Template Categories") &&
            renderLink(
              "/categories",
              <Sparkles className="w-5 h-5" />,
              "Template Categories",
              industries.length
            )}
          {hasPermission("View Templates") &&
            renderLink(
              "/templates",
              <LayoutTemplate className="w-5 h-5" />,
              "Templates",
              templates.length
            )}
          {hasPermission("View Domains") &&
            renderLink(
              "/domains",
              <Box className="w-5 h-5" />,
              "Domains",
              domains
            )}
          {hasPermission("View Users") &&
            renderLink(
              "/users",
              <Users className="w-5 h-5" />,
              "Users",
              users.length
            )}
          {hasPermission("View Merchants") &&
            renderLink(
              "/merchants",
              <Group className="w-5 h-5" />,
              "Merchants",
              merchants.length
            )}
        </div>
      </div>
    </div>
  );
}
