import {
  Check,
  Edit,
  FileWarning,
  Grip,
  LoaderCircle,
  Trash,
  X,
} from "lucide-react";
import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import toast from "react-hot-toast";
import SwitchToggle from "../../../../components/common/SwitchToggle";
import usePermissions from "../../../../utils/userPermission";

const ItemType = "SECTION";

export default function DraggableSection({
  index,
  section,
  layout,
  setLayout,
  pageIndex,
  moveSection,
  updateSectionsOnServer,
}) {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveSection(pageIndex, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { type: ItemType, index },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      updateSectionsOrder();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const updateSectionsOrder = async () => {
    try {
      await updateSectionsOnServer(layout, {
        loading: "Updating section position...",
        success: `${layout[pageIndex].sections[index].section} position changed`,
        error: "Failed to update section position.",
      });
    } catch (error) {
      toast.error("Failed to update section position.");
    }
  };

  const [deleteIndex, setDeleteIndex] = useState(null);
  const deleteRef = useRef({});

  const handleDelete = (index) => {
    setDeleteIndex(index);
  };

  const [action, setAction] = useState(false);
  const removeSection = async (pageIndex, index) => {
    setAction(true);
    const updatedSections = layout.map((page, i) =>
      i === pageIndex
        ? { ...page, sections: page.sections.filter((_, j) => j !== index) }
        : page
    );
    setLayout(updatedSections);
    await updateSectionsOnServer(updatedSections, {
      loading: "Deleting section...",
      success: "Section got deleted",
      error: "Failed to delete section.",
    });
    setDeleteIndex(null);
    setAction(false);
  };

  const [editIndex, setEditIndex] = useState(null);
  const [editInput, setEditInput] = useState("");
  const startEditing = (index) => {
    setEditIndex(index);
    setEditInput(layout[pageIndex].sections[index].section);
  };

  const cancelEditing = () => {
    setEditIndex(null);
    setEditInput("");
  };

  const saveEdit = async (pageIndex, index) => {
    if (editInput.trim() !== "") {
      const updatedSections = layout.map((page, i) =>
        i === pageIndex
          ? {
              ...page,
              sections: page.sections.map((section, j) =>
                j === index
                  ? { ...section, section: editInput.trim() }
                  : section
              ),
            }
          : page
      );
      setLayout(updatedSections);
      setEditIndex(null);
      setEditInput("");
      await updateSectionsOnServer(updatedSections, {
        loading: "Updating section...",
        success: "Section updated",
        error: "Failed to update section.",
      });
    } else {
      toast.error("Section name cannot be empty");
    }
  };

  const handleEditKeyDown = (e, pageIndex, index) => {
    if (e.key === "Enter" && editInput.trim() !== "") {
      saveEdit(pageIndex, index);
    } else if (e.key === "Escape") {
      cancelEditing();
    }
  };

  const handleEnabled = async (pageIndex, index) => {
    const updatedSections = layout.map((page, i) =>
      i === pageIndex
        ? {
            ...page,
            sections: page.sections.map((section, j) =>
              j === index ? { ...section, enable: !section.enable } : section
            ),
          }
        : page
    );
    setLayout(updatedSections);
    await updateSectionsOnServer(updatedSections, {
      loading: "Updating section...",
      success: "Section updated",
      error: "Failed to update section.",
    });
  };

  const { hasPermission } = usePermissions();

  return (
    <div
      title="Drag"
      ref={ref}
      className={`flex items-center p-2 hover:bg-gray-200 border-b dark:border-white/20 gap-0 justify-between ${
        isDragging
          ? "bg-primary text-white shadow-xl shadow-black/30"
          : `${
              editIndex !== index && " dark:text-white dark:hover:bg-gray-900"
            } hover:text-black`
      } `}
    >
      {editIndex === index ? (
        <input
          value={editInput}
          onChange={(e) => setEditInput(e.target.value)}
          onKeyDown={(e) => handleEditKeyDown(e, pageIndex, index)}
          className="inputField py-[1px] px-2 mr-2 rounded-md flex-1"
        />
      ) : (
        <span className="flex items-center gap-2">
          <Grip className="w-4 h-4 cursor-move text-gray-400 hover:text-primary transition-all" />
          {section.section}
        </span>
      )}
      {editIndex === index ? (
        <div className="flex items-center justify-end gap-1">
          <button
            onClick={() => saveEdit(pageIndex, index)}
            className="btnPrimary gap-1 bg-secondary p-1"
          >
            <Check className="h-4 w-4 saveIcon" />
          </button>
          <button
            onClick={cancelEditing}
            className="btnPrimary gap-1 bg-red-500 p-1"
          >
            <X className="h-4 w-4 cancelIcon" />
          </button>
        </div>
      ) : (
        <div className="flex items-center justify-end gap-1">
          {hasPermission("disable section") && (
            <SwitchToggle
              enabled={section.enable}
              handleEnabled={() => handleEnabled(pageIndex, index)}
            />
          )}
          {hasPermission("edit section") && (
            <button
              onClick={() => startEditing(index)}
              className="focus:outline-none"
            >
              <Edit className="h-6 w-4 editIcon" />
            </button>
          )}
          {hasPermission("delete section") && (
            <DeleteAction
              index={index}
              deleteIndex={deleteIndex}
              setDeleteIndex={setDeleteIndex}
              section={section}
              handleDelete={handleDelete}
              removeSection={() => removeSection(pageIndex, index)}
              ref={deleteRef}
              action={action}
            />
          )}
        </div>
      )}
    </div>
  );
}

const DeleteAction = React.forwardRef(
  (
    { index, deleteIndex, setDeleteIndex, removeSection, handleDelete, action },
    ref
  ) => (
    <div className="relative">
      <Trash onClick={() => handleDelete(index)} className="deleteIcon" />
      {deleteIndex === index && (
        <div
          ref={(el) => (ref.current[index] = el)}
          className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 dark:bg-black/50 bg-opacity-75 transition-opacity"
        >
          <div className="bg-white p-6 rounded shadow-xl shadow-white/5">
            <div className="flex items-center text-primary gap-2 mb-2">
              <FileWarning className="w-5 h-5" />
              <h2 className="text-lg font-bold">Confirmation</h2>
            </div>
            <p className="mb-6">
              Are you sure you want to delete this section?
            </p>
            <div className="flex items-center justify-end mt-4">
              <button onClick={() => setDeleteIndex(null)} className="px-6">
                Cancel
              </button>
              <button onClick={removeSection} className="btnPrimary bg-red-500">
                {action ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {action ? <p>Deleting</p> : <p>Delete Section</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
);
