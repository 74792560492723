import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../utils/useApi";
import { useProjectContext } from "../ProjectContext";
import SectionHead from "../ProjectSidebar/SectionHead";
import { Info, LoaderCircle } from "lucide-react";

export default function NavStyles({ setIsCustomizing }) {
  const { request, isLoading } = useApi();
  const { project_id, refreshIframe } = useProjectContext();

  const [navStyles, setNavStyles] = useState({ active: "", types: [] });

  const getNavStyles = async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${"nav_type"}`,
      });
      setNavStyles(res.data[0].value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getNavStyles();
  }, [project_id]);

  const [isUpdating, setUpdating] = useState(false);

  const updateNavType = async (event, selectedStyle) => {
    event.preventDefault();

    setUpdating(true);
    const updatedNavStyles = { ...navStyles, active: selectedStyle };

    try {
      const formData = new FormData();
      formData.append("key", "nav_type");
      formData.append("value", JSON.stringify(updatedNavStyles));
      formData.append("value_type", "JSON");

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(`Navbar style updated.`);
      setNavStyles(updatedNavStyles);
      refreshIframe();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }

    setUpdating(false);
  };

  return (
    <div>
      <SectionHead title="Navbar Styles" setIsCustomizing={setIsCustomizing} />
      <Toaster />
      <div className="p-1">
        <p className="text-secondary text-sm py-3 whitespace-nowrap flex items-center gap-1">
          <Info className="w-4 h-4" /> Click to select navbar style.
        </p>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center py-24">
            <LoaderCircle className="w-10 h-10 text-primary animate-spin" />
          </div>
        ) : (
          navStyles?.types?.map((item, index) => (
            <button
              onClick={(e) => updateNavType(e, item)}
              className={`btnDropdown hover:bg-primary hover:text-white capitalize ${
                item === navStyles?.active && "bg-primary text-white rounded-md"
              }`}
              key={index}
              disabled={isUpdating}
            >
              {item?.replaceAll("_", " ")}
            </button>
          ))
        )}
      </div>
    </div>
  );
}
