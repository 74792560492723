import React, { useEffect, useRef, useState, useCallback } from "react";
import { BringToFront, LoaderCircle } from "lucide-react";
import toast from "react-hot-toast";
import useApi from "../../utils/useApi";

const MerchantDropdown = ({
  merchants,
  selectedMerchant,
  onSelectMerchant,
}) => (
  <div className="flex flex-col">
    {merchants.map((merchant) => (
      <label
        key={merchant._id}
        className={`py-2 px-3 border-b flex items-center gap-2 cursor-pointer last:border-none hover:bg-gray-100 hover:text-black dark:hover:bg-white/10 rounded w-full transition-all text-left ${
          merchant._id === selectedMerchant?._id && "bg-warmGray text-white"
        }`}
        htmlFor={`merchant_${merchant._id}`}
      >
        <input
          type="radio"
          id={`merchant_${merchant._id}`}
          name="merchant"
          value={merchant._id}
          checked={merchant._id === selectedMerchant?._id}
          onChange={() => onSelectMerchant(merchant)}
        />
        {merchant.name}
      </label>
    ))}
  </div>
);

const TransferButton = ({ isTransferring, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="btnPrimary bg-black flex items-center justify-center gap-2"
  >
    {isTransferring && <LoaderCircle className="w-4 h-4 animate-spin" />}
    <p>{isTransferring ? "Transferring" : "Transfer Now"}</p>
  </button>
);

const TransferProjectSelectBox = ({
  merchant,
  merchants,
  setMerchant,
  selectedData,
  fetchProjects,
}) => {
  const [isTransferring, setIsTransferring] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { request } = useApi();

  const transferProjects = useCallback(async () => {
    if (!selectedData.length) {
      return toast.error("No Projects Selected");
    }
    if (!merchant?._id) {
      return toast.error("No Merchant Selected");
    }

    setIsTransferring(true);

    try {
      await request({
        method: "post",
        url: `projects/change_merchant/${merchant._id}`,
        data: { project_ids: selectedData.map((item) => item._id) },
      });
      toast.success(`Projects Transferred Successfully to ${merchant.name}`);
      fetchProjects();
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          "Operation could not be performed, some error occurred."
      );
    } finally {
      setIsTransferring(false);
    }
  }, [merchant, selectedData, request, fetchProjects]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative text-black dark:text-white/80">
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
        >
          <p className="font-medium mb-2 border-b">Select a merchant.</p>
          <MerchantDropdown
            merchants={merchants}
            selectedMerchant={merchant}
            onSelectMerchant={setMerchant}
          />
          <div className="flex items-center justify-end mt-3">
            <TransferButton
              isTransferring={isTransferring}
              onClick={transferProjects}
            />
          </div>
        </div>
      )}
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="btnWhite relative"
      >
        <BringToFront className="w-4 h-4" />
        Transfer Projects
      </button>
    </div>
  );
};

export default TransferProjectSelectBox;
