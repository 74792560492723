import React, { useEffect, useState } from "react";
import useApi from "../../../../utils/useApi";
import { InputField, SwitchToggle, TextArea } from "../../../../components";
import toast, { Toaster } from "react-hot-toast";
import { LoaderCircle, Save } from "lucide-react";
import { useTemplateContext } from "../TemplateContext";

export default function TemplateSEO() {
  const { request } = useApi();
  const { industry_id, template_id } = useTemplateContext();

  const [meta_home, setMetaHome] = useState({});
  const getMetaHome = async () => {
    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/meta_home`,
      });
      setMetaHome({
        title: res.data[0].value?.title,
        description: res.data[0].value?.description,
      });
    } catch (err) {
      console.error(err);
    }
  };

  // robotxt
  const [robotxt, setRobotxt] = useState("false");
  const [enabled, setEnabled] = useState(false);
  const [isEnabling, setIsEnabling] = useState(false);
  const handleEnabled = () => {
    const newEnabled = !enabled;
    setEnabled(newEnabled);
    setRobotxt(newEnabled ? "true" : "false");
    updateRobotxt(newEnabled);
  };

  const getRobotxt = async () => {
    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/robotxt`,
      });
      if (res.data[0].value === "true") {
        setEnabled(true);
      } else if (res.data[0].value === "false") {
        setEnabled(false);
      }
      setRobotxt(res.data[0].value);
    } catch (err) {
      console.error(err);
    }
  };

  const updateRobotxt = async (newEnabled) => {
    setIsEnabling(true);

    try {
      const formData = new FormData();
      formData.append("key", "robotxt");
      formData.append("value", newEnabled ? "true" : "false");
      await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`Robots.txt ${newEnabled ? "enabled" : "disabled"}.`);
      getMetaHome();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setIsEnabling(false);
  };

  const [tagList, setTagList] = useState([]);
  const getTagList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/tag_list`,
      });
      setTagList(data?.data[0]?.value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getMetaHome();
    getTagList();
    getRobotxt();
  }, [industry_id, template_id]);

  const [isUpdating, setIsUpdating] = useState(false);
  const updateHomeMeta = async (event) => {
    event.preventDefault();
    setIsUpdating(true);

    try {
      const formData = new FormData();
      formData.append("key", "meta_home");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(meta_home));
      await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`SEO Tags updated.`);
      getMetaHome();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setIsUpdating(false);
  };

  const [meta_about, setMetaAbout] = useState({});
  const getMetaAbout = async () => {
    try {
      const res = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data/meta_about`,
      });
      setMetaAbout({
        title: res.data[0].value?.title,
        description: res.data[0].value?.description,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getMetaAbout();
  }, [industry_id, template_id]);

  const updateAboutMeta = async (event) => {
    event.preventDefault();
    setIsUpdating(true);

    try {
      const formData = new FormData();
      formData.append("key", "meta_about");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(meta_about));
      await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`SEO Tags updated.`);
      getMetaHome();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setIsUpdating(false);
  };

  const getColorForTitle = (length) => {
    return length >= 50 && length <= 60 ? "text-green-600" : "text-red-500";
  };

  const getColorForDescription = (length) => {
    return length >= 150 && length <= 160 ? "text-green-600" : "text-red-500";
  };

  const handleHomeMetaChange = (e) => {
    setMetaHome({
      ...meta_home,
      [e.target.name]: e.target.value,
    });
  };

  const handleAboutMetaChange = (e) => {
    setMetaAbout({
      ...meta_about,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="p-8 h-[calc(100vh-6rem)] overflow-y-scroll flex flex-col items-center">
      <Toaster />
      <div className="max-w-4xl w-full flex flex-col gap-6">
        <div className="flex items-center justify-between gap-3 px-7 py-6 shadow-md bg-white dark:bg-gray-800 rounded-lg">
          <h4 className="font-bold">Robots.txt</h4>
          <div className="flex items-center gap-2">
            <span className="flex items-center gap-2">
              {isEnabling ? (
                <p className="text-gray-400 text-sm">
                  {enabled ? "Enabling" : "Disabling"}
                </p>
              ) : (
                <p className="text-gray-400 text-sm">
                  {enabled ? "Enabled" : "Disabled"}
                </p>
              )}
              {isEnabling ? (
                <LoaderCircle className="w-5 text-primary animate-spin" />
              ) : (
                <SwitchToggle enabled={enabled} handleEnabled={handleEnabled} />
              )}
            </span>
          </div>
        </div>

        <div className="px-7 py-6 shadow-md bg-white dark:bg-gray-800 rounded-lg">
          <div className="flex items-center justify-between border-b pb-4 mb-5">
            <h4 className="font-bold">Home Page</h4>
            <button
              onClick={updateHomeMeta}
              className="btnPrimary bg-secondary py-1 px-3"
            >
              {isUpdating ? (
                <LoaderCircle className="w-4 animate-spin" />
              ) : (
                <Save className="w-4" />
              )}
              {isUpdating ? <p>Updating SEO</p> : <p>Save & Update</p>}
            </button>
          </div>
          <InputField
            label="Meta Title"
            name="title"
            placeholder="Meta Title"
            value={meta_home?.title}
            onChange={handleHomeMetaChange}
          />
          <div className="flex items-center justify-between mb-5 mt-1">
            <p className="text-sm">
              <strong className="text-warmGray">Recommended Characters:</strong>{" "}
              50-60
            </p>
            <p
              className={`text-sm ${getColorForTitle(
                meta_home?.title?.length || 0
              )}`}
            >
              Character count: {meta_home?.title?.length || 0}{" "}
              {meta_home?.title?.length < 50 || meta_home?.title?.length > 60
                ? "❗"
                : ""}
            </p>
          </div>
          <TextArea
            label="Meta Description"
            name="description"
            placeholder="Meta Description"
            value={meta_home?.description}
            onChange={handleHomeMetaChange}
          />
          <div className="flex items-center justify-between">
            <p className="text-sm">
              <strong className="text-warmGray">Recommended characters:</strong>{" "}
              150-160
            </p>
            <p
              className={`text-sm ${getColorForDescription(
                meta_home?.description?.length || 0
              )}`}
            >
              Character count: {meta_home?.description?.length || 0}{" "}
              {meta_home?.description?.length < 150 ||
              meta_home?.description?.length > 160
                ? "❗"
                : ""}
            </p>
          </div>
        </div>

        <div className="px-7 py-6 shadow-md bg-white dark:bg-gray-800 rounded-lg">
          <div className="flex items-center justify-between border-b pb-4 mb-5">
            <h4 className="font-bold">About Page</h4>
            <button
              onClick={updateAboutMeta}
              className="btnPrimary bg-secondary py-1 px-3"
            >
              {isUpdating ? (
                <LoaderCircle className="w-4 animate-spin" />
              ) : (
                <Save className="w-4" />
              )}
              {isUpdating ? <p>Updating SEO</p> : <p>Save & Update</p>}
            </button>
          </div>
          <InputField
            label="Meta Title"
            name="title"
            placeholder="Meta Title"
            value={meta_about?.title}
            onChange={handleAboutMetaChange}
          />
          <div className="flex items-center justify-between mb-5 mt-1">
            <p className="text-sm">
              <strong className="text-warmGray">Recommended Characters:</strong>{" "}
              50-60
            </p>
            <p
              className={`text-sm ${getColorForTitle(
                meta_about?.title?.length || 0
              )}`}
            >
              Character count: {meta_about?.title?.length || 0}{" "}
              {meta_about?.title?.length < 50 || meta_about?.title?.length > 60
                ? "❗"
                : ""}
            </p>
          </div>
          <TextArea
            label="Meta Description"
            name="description"
            placeholder="Meta Description"
            value={meta_about?.description}
            onChange={handleAboutMetaChange}
          />
          <div className="flex items-center justify-between">
            <p className="text-sm">
              <strong className="text-warmGray">Recommended characters:</strong>{" "}
              150-160
            </p>
            <p
              className={`text-sm ${getColorForDescription(
                meta_about?.description?.length || 0
              )}`}
            >
              Character count: {meta_about?.description?.length || 0}{" "}
              {meta_about?.description?.length < 150 ||
              meta_about?.description?.length > 160
                ? "❗"
                : ""}
            </p>
          </div>
        </div>

        <div className="px-7 py-6 shadow-md bg-white dark:bg-gray-800 rounded-lg">
          <h4 className="border-b pb-4 mb-5 font-bold">Blog Tags</h4>
          <div className="flex items-center flex-wrap gap-2 mt-6">
            {tagList?.map((item, index) => (
              <p
                key={index}
                className="px-3 py-1 rounded bg-orange-100 text-primary text-sm shadow"
              >
                {item.tag}{" "}
                {item?.article_ids?.length > 1 && (
                  <span className="rounded-full bg-primary text-white px-1 text-xs ml-1">
                    {item.article_ids.length}
                  </span>
                )}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
