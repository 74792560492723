import React, { useMemo } from "react";
import { useArticleContext } from "../ArticleContext";
import MarkdownIt from "markdown-it";

export default function ArticlePreview() {
  const markdownIt = new MarkdownIt();
  const { article, fileInfo } = useArticleContext();
  const convertMarkdown = (markdownText) => markdownIt?.render(markdownText);

  const bannerStyle = useMemo(
    () => ({
      backgroundColor: `rgba(0, 0, 0, ${article.opacity / 100})` || `#0009`,
      color: article.textColor || "white",
    }),
    [article.opacity, article.textColor]
  );

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${fileInfo?.preview})` }}
        className=" relative min-h-[480px] w-full bg-cover bg-center rounded-md overflow-hidden"
      >
        <div
          style={bannerStyle}
          className="flex flex-col items-center justify-center text-center py-20 px-12 absolute top-0 h-full w-full"
        >
          <h1
            className="font-bold"
            style={{
              fontSize: article.titleFontSize,
              padding: 0,
            }}
          >
            {article?.title || "New Article Title"}
          </h1>
          <p
            className="mt-7 text-2xl font-semibold"
            style={{
              fontSize: article.taglineFontSize,
              padding: 0,
            }}
          >
            {article?.tagline || "New article tagline"}
          </p>
          <div className="flex items-center justify-center gap-10 text-sm mt-10">
            <p>
              By{" "}
              <span className="underline">
                {article?.author || "author name"}
              </span>
            </p>
            <p>PUBLISHED: {article?.published_at}</p>
          </div>
        </div>
      </div>
      <p className="text-end text-sm py-2 text-gray-500">
        {article?.imageTitle || "image title"}
      </p>
      <div
        className="prose mt-5 max-w-full dark:text-white/80"
        dangerouslySetInnerHTML={{
          __html: convertMarkdown(
            article?.articleContent || "Article content here."
          ),
        }}
      />
    </div>
  );
}
