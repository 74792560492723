import { CheckCheck, Copy } from "lucide-react";
import Status from "../../../components/common/Status";
import { useState } from "react";
import toast from "react-hot-toast";

function SSLStatus({ project, handleModal }) {
  const [isCopied, setIsCopied] = useState(null);
  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  return (
    <>
      <p className="text-gray-500 dark:text-gray-400">
        <b>SSL:</b>{" "}
        <button
          type="button"
          onClick={() =>
            project?.domain_id?.cloudflair_details?.status === "pending" &&
            handleModal()
          }
        >
          <Status
            className="py-1 px-3 text-sm"
            type={project?.domain_id?.cloudflair_details?.status}
          />
        </button>
      </p>
      {project?.domain_id?.cloudflair_details?.name_servers?.length > 0 && (
        <div className="text-gray-500 dark:text-gray-400 mt-1">
          <b>Nameservers:</b>
          {project?.domain_id?.cloudflair_details?.name_servers?.map(
            (item, index) => (
              <div className="flex items-center gap-1 p-0 relative" key={index}>
                {isCopied === index ? (
                  <CheckCheck className="w-3 h-3 text-green-500" />
                ) : (
                  <Copy
                    className="w-3 h-3 cursor-pointer text-gray-500 dark:text-gray-400 hover:text-primary transition-all"
                    onClick={() => copyToClipboard(item, index)}
                  />
                )}
                <p className="flex-1 text-black dark:text-white">{item}</p>
              </div>
            )
          )}
        </div>
      )}
      <p className="flex items-center mt-1 gap-1">
        <b className="text-gray-500 dark:text-gray-400">CDN:</b>{" "}
        <span className="">
          {project?.domain_id?.cloudflair_account?.auth_email}
        </span>
      </p>
    </>
  );
}

export default SSLStatus;
