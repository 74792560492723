import React, { useEffect, useState } from "react";
import { Camera, LoaderCircle, Save } from "lucide-react";
import { InputField, ListBox, TextArea } from "../../../../../components";
import { useTemplateContext } from "../../TemplateContext";
import { useArticleContext } from "../ArticleContext";
import useApi from "../../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import ReactMarkdown from "react-markdown";
import { v4 as uuidv4 } from "uuid";

const ManageArticle = () => {
  const navigate = useNavigate();
  const { request } = useApi();
  const {
    getBlog,
    blogsList,
    article,
    setArticle,
    fileInfo,
    handleFileChange,
    categories,
  } = useArticleContext();

  const { template, articleKey } = useParams();
  const { template_id, industry_id } = useTemplateContext();
  const [newArticleKey, setNewArticleKey] = useState(null);
  const [articleCategory, setArticleCategory] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (articleKey && blogsList.length > 0) {
      const blogToEdit = blogsList.find((item) => item.key === articleKey);
      getBlog(blogToEdit?.key);
    }
  }, [articleKey, industry_id, template_id, blogsList]);

  useEffect(() => {
    if (articleKey && blogsList.length > 0) {
      setArticleCategory(article?.article_category);
    }
  }, [articleKey, industry_id, template_id, blogsList, article]);

  const handleChange = (e) => {
    setArticle((prevArticle) => ({
      ...prevArticle,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditorChange = ({ text }) => {
    setArticle((prevArticle) => ({
      ...prevArticle,
      articleContent: text,
    }));
  };

  const handleCreateUpdate = async (event) => {
    event.preventDefault();
    setIsUpdating(true);

    const isUpdate = Boolean(articleKey);
    const uniqueKey = isUpdate
      ? articleKey
      : newArticleKey || `article-${uuidv4()}`;

    if (!isUpdate && !newArticleKey) {
      setNewArticleKey(uniqueKey);
    }

    if (!articleCategory) {
      toast.error("Please select an article category.");
      setIsUpdating(false);
      return;
    }

    const updatedArticle = {
      ...article,
      article_category: articleCategory,
      key: uniqueKey,
    };

    try {
      const formData = new FormData();
      formData.append("key", uniqueKey);
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedArticle));
      if (fileInfo?.file) {
        formData.append("file", fileInfo.file);
      }

      const res = await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const articleWithResponseData = {
        ...updatedArticle,
        _id: res.data._id,
        key: res.data.key,
        image: res.data.file_name,
        articleContent: updatedArticle.articleContent?.slice(0, 300),
      };

      if (res.status) {
        const updatedBlogsList = blogsList ? [...blogsList] : [];
        const blogIndex = updatedBlogsList.findIndex(
          (blog) => blog.key === uniqueKey
        );

        if (blogIndex !== -1) {
          updatedBlogsList[blogIndex] = articleWithResponseData;
        } else {
          updatedBlogsList.push(articleWithResponseData);
        }

        const updatedFormData = new FormData();
        updatedFormData.append("key", "blog_list");
        updatedFormData.append("value_type", "JSON");
        updatedFormData.append("value", JSON.stringify(updatedBlogsList));

        await request({
          method: "post",
          url: `industries/${industry_id}/templates/${template_id}/data`,
          data: updatedFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Your article is updated.");
      }

      getBlog(res.data.key);
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setIsUpdating(false);
  };

  return (
    <div className="p-5 rounded-md bg-white dark:bg-gray-800">
      <Toaster />
      <div className="flex items-center justify-end gap-3 mb-2">
        <button
          onClick={() => navigate(`/templates/${template}/articles`)}
          className="btnPrimary py-1 px-3 bg-red-500"
        >
          Close
        </button>
        <button
          disabled={isUpdating}
          type="button"
          onClick={handleCreateUpdate}
          className="btnPrimary py-1 px-3 bg-green-600 disabled:cursor-default disabled:opacity-90 disabled:bg-primary"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          {isUpdating ? <p>Updating Article</p> : <p>Save & Update</p>}
        </button>
      </div>
      <div>
        <InputField
          label="Article Title"
          name="title"
          placeholder="Enter Article title"
          value={article?.title}
          onChange={handleChange}
        />
        <InputField
          label="Tagline"
          name="tagline"
          placeholder="Enter Article tagline"
          className="mt-3"
          value={article?.tagline}
          onChange={handleChange}
        />
        <div className="grid grid-cols-2 gap-4 mt-3 mb-3">
          <InputField
            label="Author Name"
            name="author"
            placeholder="Enter your name"
            value={article?.author}
            onChange={handleChange}
          />
          <InputField
            label="Publishing Date"
            name="published_at"
            type="date"
            value={article?.published_at}
            onChange={handleChange}
          />
        </div>
        <ListBox
          label="Select Category"
          name="article_category"
          placeholder="Select"
          options={categories?.map((item, index) => ({
            _id: index + 1,
            name: item,
          }))}
          selectedOption={articleCategory}
          setSelectedOption={setArticleCategory}
        />
        <div className="overflow-hidden relative h-40 transition-all w-full rounded-md bg-gray-300 mt-5 border border-white/20 flex items-center justify-end">
          <input
            type="file"
            onChange={handleFileChange}
            id="imageUpload"
            className="hidden"
            accept="image/*"
          />
          <label
            htmlFor="imageUpload"
            className="btnWhite py-1 px-3 cursor-pointer flex items-center gap-2 z-10 m-2 absolute right-0 top-0 h-fit"
          >
            Upload Image
            <Camera className="w-4" />
          </label>
          <img
            title="Upload an image"
            src={fileInfo?.preview}
            loading="lazy"
            alt=""
            className={`w-full h-full object-cover absolute top-0 ${
              fileInfo ? "scale-100" : "scale-125"
            }`}
          />
        </div>
        <div className="flex items-center justify-end">
          <input
            type="text"
            name="imageTitle"
            placeholder="image title"
            value={article?.imageTitle}
            onChange={handleChange}
            className="w-48 py-1 px-2 mt-1 inputField"
          />
        </div>
        <label className="inputLabel mb-1 mt-1">Article Content</label>
        <MdEditor
          value={article?.articleContent}
          style={{
            height: `${Math.round(article?.articleContent?.length / 2.5)}px`,
            minHeight: 200,
          }}
          renderHTML={(text) => <ReactMarkdown>{text}</ReactMarkdown>}
          onChange={handleEditorChange}
          config={{ view: { menu: true, md: true, html: false } }}
        />
        <h4 className="border-b mt-5">SEO</h4>
        <InputField
          label="Meta Title"
          name="meta_title"
          placeholder="Meta Title"
          value={article?.meta_title}
          onChange={handleChange}
          className="my-4"
        />
        <TextArea
          label="Meta Description"
          name="meta_description"
          value={article?.meta_description}
          onChange={handleChange}
          placeholder="Meta Description"
        />
      </div>
    </div>
  );
};

export default ManageArticle;
