import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SectionHead from "./SectionHead";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { useProjectContext } from "../ProjectContext";
import { CameraIcon, LoaderCircle, Save } from "lucide-react";

// Reusable component for file upload button
const FileUploadButton = ({ id, onChange, label }) => (
  <>
    <input
      type="file"
      id={id}
      onChange={onChange}
      className="hidden"
      accept="image/*"
    />
    <label
      htmlFor={id}
      className="btnPrimary py-1 px-3 cursor-pointer flex items-center gap-2 mt-2"
    >
      {label}
      <CameraIcon className="w-4" />
    </label>
  </>
);

// Reusable component for image preview with loader
const ImagePreview = ({ isUpdating, previewSrc, height, width, altText }) => (
  <div className="overflow-hidden relative py-3 mt-3 h-24 w-full rounded-md bg-gray-100 border-2 border-gray-300 dark:border-white/20 flex items-center justify-center">
    {isUpdating ? (
      <LoaderCircle className="w-8 h-8 animate-spin" />
    ) : (
      <img
        src={previewSrc}
        loading="lazy"
        alt={altText}
        style={{ height: `${height}px`, width: `${width}px` }}
      />
    )}
  </div>
);

export default function ProjectIdentity({ setIsCustomizing }) {
  const { request } = useApi();
  const { project_id, refreshIframe } = useProjectContext();

  const [logoInfo, setLogoInfo] = useState(null);
  const [faviconInfo, setFaviconInfo] = useState(null);
  const [isUpdating, setIsUpdating] = useState({ logo: false, favicon: false });

  // Group logo settings into one state object
  const [logoSettings, setLogoSettings] = useState({
    logoType: "",
    logoText: "",
    logoHeight: 50,
    logoWidth: 180,
    fontSize: 24,
    isBold: false,
    isItalic: false,
  });

  // Calculate aspect ratio for proportional scaling
  const aspectRatio = logoSettings.logoWidth / logoSettings.logoHeight;

  const fetchData = async (type, setFileInfo) => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${type}`,
      });
      const file = res.data[0].file_name;
      if (type === "logo") {
        setLogoSettings({
          logoType: res.data[0].value?.logoType,
          logoText: res.data[0].value?.logoText,
          logoHeight: res.data[0].value?.logoHeight,
          logoWidth: res.data[0].value?.logoWidth,
          fontSize: res.data[0].value?.fontSize,
          isBold: res.data[0].value?.isBold,
          isItalic: res.data[0].value?.isItalic,
        });
      }
      setFileInfo({
        file,
        preview: `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${file}`,
      });
    } catch (err) {
      console.error(err);
      toast.error("Error fetching data.");
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        fetchData("favicon", setFaviconInfo),
        fetchData("logo", setLogoInfo),
      ]);
    };
    fetchInitialData();
  }, [project_id]);

  const handleFileChange = (e, setFileInfo) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        // Get the actual dimensions of the image
        const width = img.width;
        const height = img.height;

        setLogoSettings((prev) => ({
          ...prev,
          logoHeight: height,
          logoWidth: width,
        }));

        // Set the preview and file info
        setFileInfo({
          file,
          preview: URL.createObjectURL(file),
        });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const updateFile = async (e, fileInfo, type, successMessage) => {
    e.preventDefault();
    setIsUpdating((prev) => ({ ...prev, [type]: true }));

    try {
      const formData = new FormData();
      formData.append("key", type);
      if (type === "logo" && logoSettings.logoType === "image") {
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(logoSettings));
      } else {
        formData.append("value", type);
      }
      if (fileInfo?.file) {
        formData.append("file", fileInfo?.file);
      }
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(successMessage);
      fetchData(type, type === "logo" ? setLogoInfo : setFaviconInfo);
      refreshIframe();
    } catch (err) {
      toast.error(err.response?.data?.message || "Error updating file.");
    }
    setIsUpdating((prev) => ({ ...prev, [type]: false }));
  };

  const updateTextLogo = async (e) => {
    e.preventDefault();
    setIsUpdating((prev) => ({ ...prev, logo: true }));

    try {
      const formData = new FormData();
      formData.append("key", "logo");
      formData.append("value", JSON.stringify(logoSettings));
      formData.append("value_type", "JSON");
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Logo Updated");
      refreshIframe();
    } catch (err) {
      toast.error(err.response?.data?.message || "Error updating logo.");
    }
    setIsUpdating((prev) => ({ ...prev, logo: false }));
  };

  return (
    <>
      <SectionHead
        title="Project Identity"
        setIsCustomizing={setIsCustomizing}
      />
      <div className="p-2">
        {/* Logo Section */}
        <div className="w-full">
          <p className="font-medium text-sm mb-1 mt-7">Select Logo Type</p>
          <select
            onChange={(e) =>
              setLogoSettings((prev) => ({ ...prev, logoType: e.target.value }))
            }
            value={logoSettings.logoType}
            className="inputField px-1"
          >
            <option className="dark:bg-gray-800" value="image">
              Image Logo
            </option>
            <option className="dark:bg-gray-800" value="text">
              Text Logo
            </option>
          </select>
        </div>

        {logoSettings.logoType === "image" && (
          <>
            <p className="border-b mb-3 mt-7 font-medium">Logo</p>
            <ImagePreview
              isUpdating={isUpdating.logo}
              previewSrc={logoInfo?.preview}
              height={logoSettings.logoHeight}
              width={logoSettings.logoWidth}
              altText="No Logo"
            />

            <FileUploadButton
              id="logoUpload"
              onChange={(e) => handleFileChange(e, setLogoInfo)}
              label="Select Logo"
            />
            <p className="text-sm text-gray-500 mt-1">
              Recommended size: 50x180 pixels
            </p>

            {/* Single Range Slider for Proportional Logo Size */}
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <label htmlFor="logoSize" className="block text-sm font-medium">
                  Adjust Logo Size
                </label>
                <p className="text-sm">
                  {logoSettings.logoHeight}px x {logoSettings.logoWidth}px
                </p>
              </div>
              <input
                type="range"
                id="logoSize"
                name="logoSize"
                min="0"
                max="100"
                step="3"
                value={logoSettings.logoHeight}
                onChange={(e) => {
                  const newHeight = e.target.value;
                  const newWidth = newHeight * aspectRatio; // Calculate new width based on the aspect ratio
                  setLogoSettings((prev) => ({
                    ...prev,
                    logoHeight: newHeight,
                    logoWidth: newWidth,
                  }));
                }}
                className="w-full"
              />
            </div>

            <button
              onClick={(e) => updateFile(e, logoInfo, "logo", "Logo updated.")}
              className="btnPrimary bg-green-600 dark:bg-green-800 py-1 px-3 mt-4 ml-auto"
            >
              {isUpdating.logo ? (
                <LoaderCircle className="w-4 animate-spin" />
              ) : (
                <Save className="w-4" />
              )}
              {isUpdating.logo ? <p>Updating</p> : <p>Update Logo</p>}
            </button>
          </>
        )}

        {logoSettings.logoType === "text" && (
          <>
            <InputField
              label={<span className="text-sm">Logo</span>}
              className="mt-5"
              value={logoSettings.logoText}
              onChange={(e) =>
                setLogoSettings((prev) => ({
                  ...prev,
                  logoText: e.target.value,
                }))
              }
              placeholder="Text Logo"
              style={{
                fontSize: `${logoSettings.fontSize}px`,
                fontWeight: logoSettings.isBold ? "bold" : "normal",
                fontStyle: logoSettings.isItalic ? "italic" : "normal",
              }}
            />
            <div className="flex items-center gap-5 mt-4">
              <div>
                <label className="block text-sm font-medium">Font Style</label>
                <div className="flex items-center mt-2">
                  <label className="flex items-center mr-4">
                    <input
                      type="checkbox"
                      checked={logoSettings.isBold}
                      onChange={() =>
                        setLogoSettings((prev) => ({
                          ...prev,
                          isBold: !prev.isBold,
                        }))
                      }
                      className="mr-2"
                    />
                    Bold
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={logoSettings.isItalic}
                      onChange={() =>
                        setLogoSettings((prev) => ({
                          ...prev,
                          isItalic: !prev.isItalic,
                        }))
                      }
                      className="mr-2"
                    />
                    Italic
                  </label>
                </div>
              </div>
              <div className="flex-1">
                <label htmlFor="fontSize" className="block text-sm font-medium">
                  Font Size
                </label>
                <select
                  id="fontSize"
                  value={logoSettings.fontSize}
                  onChange={(e) =>
                    setLogoSettings((prev) => ({
                      ...prev,
                      fontSize: e.target.value,
                    }))
                  }
                  className="inputField p-1 mt-1 w-full"
                >
                  {[12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72].map(
                    (size) => (
                      <option key={size} value={size}>
                        {size}px
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <button
              onClick={updateTextLogo}
              className="btnPrimary bg-green-600 dark:bg-green-800 py-1 px-3 mt-4 ml-auto"
            >
              {isUpdating.logo ? (
                <LoaderCircle className="w-4 animate-spin" />
              ) : (
                <Save className="w-4" />
              )}
              {isUpdating.logo ? <p>Updating</p> : <p>Update Logo</p>}
            </button>
          </>
        )}

        {/* Favicon Section */}
        <p className="border-b mb-3 mt-7 font-medium">Favicon</p>
        <ImagePreview
          isUpdating={isUpdating.favicon}
          previewSrc={faviconInfo?.preview}
          height={32}
          width={32}
          altText="No Favicon"
        />
        <FileUploadButton
          id="faviconUpload"
          onChange={(e) => handleFileChange(e, setFaviconInfo)}
          label="Select Favicon"
        />
        <p className="text-sm text-gray-500 mt-1">
          Recommended size: 32x32 pixels
        </p>
        <button
          onClick={(e) =>
            updateFile(e, faviconInfo, "favicon", "Favicon updated.")
          }
          className="btnPrimary bg-green-600 dark:bg-green-800 py-1 px-3 mt-4 ml-auto"
        >
          {isUpdating.favicon ? (
            <LoaderCircle className="w-4 animate-spin" />
          ) : (
            <Save className="w-4" />
          )}
          {isUpdating.favicon ? <p>Updating</p> : <p>Update Favicon</p>}
        </button>
      </div>
    </>
  );
}
