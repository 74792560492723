import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import {
  BadgeCheck,
  CheckCheck,
  ChevronUp,
  Copy,
  Edit,
  Layers3,
  LoaderCircle,
  SquarePen,
  Trash,
} from "lucide-react";
import toast from "react-hot-toast";
import { CheckBox, ComboBox, InputField, Modal } from "../../components";
import useApi from "../../utils/useApi";
import usePermissions from "../../utils/userPermission";
import SelectTemplate from "../ProjectManager/NewProject/SelectTemplate";
import { useNavigate } from "react-router-dom";

export default function ReportDomainCard({
  index,
  handleUpdate,
  setDelete,
  deleteRef,
  item,
  deleteD,
  deleteDomain,
  isOpen,
  setIsOpen,
  projects,
  industries,
  isSelected,
  onSingleSelect,
  getDomains,
  deleting,
}) {
  const [isCopied, setIsCopied] = useState(null);

  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  const renderDetailItem = (label, value, className) => (
    <p className={className}>
      <b className="text-gray-600 dark:text-white">{label}:</b> {value}
    </p>
  );

  const renderNameServers = () =>
    item?.cloudflair_details?.name_servers.map((ns, idx) => (
      <div className="flex items-center gap-2" key={idx}>
        {isCopied === idx ? (
          <CheckCheck className="w-4 text-green-500" />
        ) : (
          <Copy
            className="w-4 cursor-pointer text-gray-500 dark:text-white/80 hover:text-primary transition"
            onClick={() => copyToClipboard(ns, idx)}
          />
        )}
        <p className="flex-1 text-gray-700 dark:text-gray-300">{ns}</p>
      </div>
    ));

  const navigate = useNavigate();
  const manageProject = (project_name) => {
    navigate(`/projects/${project_name?.toLowerCase()?.replaceAll(" ", "-")}`);
  };

  const viewBacklinkReport = (domain) => {
    navigate(`/reports/${domain?.domain}`, {
      state: {
        domain_id: domain._id,
      },
    });
  };

  return (
    <div
      className={`bg-white dark:bg-gray-800 last:border-none shadow-md shadow-black/20 dark:border-white/20 ${
        isOpen && "mb-4"
      }`}
    >
      <div className="grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 border-b py-2">
        <div className="flex items-center flex-wrap text-sm">
          <p className="w-9 font-bold text-gray-700 dark:text-gray-300 ml-2">
            {index}.
          </p>
          <div className="flex items-center">
            <b className="text-gray-600 dark:text-white mr-1">Domain:</b>{" "}
            <a
              href={`http://${item.domain}`}
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-primary transition-all cursor-pointer"
            >
              {item.domain}
            </a>
            <div className="pagesIcon relative">
              <Layers3 className="w-4 h-4 ml-2 cursor-pointer hover:text-secondary" />
              <div
                className={`pagesList bg-white dark:bg-gray-700 z-20 absolute top-0 left-0 p-5 rounded-lg shadow-xl flex flex-col gap-1 mt-3 min-h-20 min-w-20 ${
                  item.ahref_backlink_custom_pages?.length > 18 &&
                  "overflow-y-scroll max-h-96"
                }`}
              >
                {item.ahref_backlink_custom_pages?.length > 0 ? (
                  item.ahref_backlink_custom_pages?.map((link, index) => (
                    <p key={index} className="whitespace-nowrap">
                      {index + 1}.{" "}
                      <a
                        href={`http://${item.domain}${link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline hover:text-primary transition-all cursor-pointer"
                      >
                        {link}
                      </a>
                    </p>
                  ))
                ) : (
                  <p className="text-gray-400 dark:text-gray-200 whitespace-nowrap">
                    No Pages To Show
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center text-sm gap-3 flex-wrap">
          <button
            onClick={() => viewBacklinkReport(item)}
            className="underline font-medium text-secondary hover:text-primary transition-all"
          >
            Backlink Report
          </button>
          {renderDetailItem(
            "Domain Status",
            item?.domain_status_id?.name,
            "w-40"
          )}
          {renderDetailItem("Niche", item?.niche_id?.name, "w-40")}
          <p className="font-semibold text-gray-600 flex items-center gap-1 cursor-pointer">
            Project:{" "}
            {item?.project_id?.project_name ? (
              <span
                onClick={() => manageProject(item?.project_id?.project_name)}
                className="bg-green-100 text-green-600 hover:bg-green-600 hover:text-white transition-all py-1 px-3 font-medium rounded-full flex items-center gap-1"
              >
                {item?.project_id?.project_name}
                <BadgeCheck className="w-4 h-4" />
              </span>
            ) : (
              <span className="text-sm text-gray-400 font-normal">
                Not Connected
              </span>
            )}
          </p>
        </div>
        <div className="flex items-center justify-end flex-wrap gap-3 lg:gap-5 text-sm">
          <p className="text-sm">
            {dayjs(item?.createdAt)?.format("D-MMM-YYYY")}
          </p>
          <button onClick={setIsOpen}>
            <ChevronUp
              className={`w-5 transition-transform transform text-gray-500 ${
                isOpen && "rotate-180"
              }`}
            />
          </button>
        </div>
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out px-6 ${
          isOpen ? "max-h-screen py-3" : "max-h-0"
        }`}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          <div>
            {renderDetailItem("Price", item?.price)}
            {renderDetailItem("Buy Type", item?.buy_type_id?.name)}
            {renderDetailItem("Traffic", item?.traffic)}
            {renderDetailItem("Trust Flow", item?.trust_flow)}
          </div>
          <div>
            {renderDetailItem("Domain Type", item?.domain_type_id?.name)}
            {renderDetailItem("Association", item?.association_id?.name)}
            {renderDetailItem("Referring Domains", item?.refering_domains)}
            {renderDetailItem("Indexed", item?.domain_indexed ? "Yes" : "No")}
          </div>
          <div>
            {renderDetailItem("Age", item?.age)}
            {renderDetailItem("Articles Count", item?.articles_count)}
            {renderDetailItem("Backlinks", item?.backLinks)}
            {renderDetailItem("Registrar", item?.registrar_id?.name)}
          </div>
          <div>
            {renderDetailItem("Citation Flow", item?.citation_flow)}
            {renderDetailItem("Organic Kw", item?.oraganic_kw)}
            {renderDetailItem("UR", item?.ur)}
            {renderDetailItem("DR", item?.dr)}
          </div>
        </div>
        {item?.ahref_info && (
          <>
            <h6 className="border-y p-1 bg-gray-50 dark:bg-white/10 font-bold dark:border-white/20 mt-4 mb-1 text-secondary">
              Ahref info
            </h6>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
              {renderDetailItem(
                "Domain Rating",
                item?.ahref_info?.domain_rating?.domain_rating
              )}
              {renderDetailItem(
                "ahrefs rank",
                item?.ahref_info?.domain_rating?.ahrefs_rank
              )}
              {renderDetailItem(
                "Live",
                item?.ahref_info?.backlinks_stats?.live
              )}
              {renderDetailItem(
                "All Time",
                item?.ahref_info?.backlinks_stats?.all_time
              )}
              {renderDetailItem(
                "live refdomains",
                item?.ahref_info?.backlinks_stats?.live_refdomains
              )}
              {renderDetailItem(
                "all time refdomains",
                item?.ahref_info?.backlinks_stats?.all_time_refdomains
              )}
            </div>
          </>
        )}

        {item?.cloudflair_account && (
          <>
            <h6 className="border-y p-1 bg-gray-50 dark:bg-white/10 font-bold dark:border-white/20 mt-4 mb-1 text-secondary">
              Cloudflair Details
            </h6>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
              {renderDetailItem(
                "Auth Email",
                item?.cloudflair_account?.auth_email
              )}
              {renderDetailItem("Auth Key", item?.cloudflair_account?.auth_key)}
              {renderDetailItem(
                "Account Id",
                item?.cloudflair_account?.account_id
              )}
              {renderDetailItem(
                "Domain Status",
                item?.cloudflair_details?.status
              )}
              <div>
                {renderDetailItem("Nameservers", "")}
                <div className="flex items-center gap-5 flex-wrap mt-1">
                  {renderNameServers()}
                </div>
              </div>
              {renderDetailItem("Type", item?.cloudflair_details?.type)}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
