import { CheckCheck, Copy } from "lucide-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { InputField } from "../../../components";

function ModalContent({ project }) {
  const [isCopied, setIsCopied] = useState(null);
  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(index);
        setTimeout(() => {
          setIsCopied(null);
        }, 2000);
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  return (
    <>
      <div className="flex items-end gap-2">
        <InputField
          label="Your Domain"
          value={project?.domain_id?.domain}
          disabled
          className="flex-1"
        />
        <div
          className={`px-4 py-2 rounded whitespace-nowrap font-medium ${
            project?.domain_id?.cloudflair_details?.status === "pending" &&
            "bg-red-100 text-red-500"
          }`}
        >
          Pending DNS
        </div>
      </div>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">
          1. Log in to your domain registrar account:
        </b>
        Visit the website where you registered your domain name and log in to
        your account using your username and password.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">
          2. Locate your domain settings:
        </b>{" "}
        Once logged in, navigate to the domain management section. This might be
        labeled as "Domain Management," "My Domains," or something similar.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">
          3. Find the nameserver settings:
        </b>{" "}
        Look for an option like "Nameservers," "DNS Management," or "DNS
        Settings" within the domain management interface. Click on it to access
        your domain's DNS settings.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">
          4. View current nameservers:
        </b>{" "}
        Before making any changes, take note of the current nameservers listed
        for your domain. This information might be useful in case you need to
        revert the changes later.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">
          5. Edit nameserver settings:
        </b>
        Now, you should see fields where you can enter the new nameserver
        information. Delete the existing nameservers and replace them with the
        ones we provided you.
      </p>
      <img src="/img/cloudflare.png" className="w-44 my-6" alt="" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
        {project?.domain_id?.cloudflair_details?.name_servers.map(
          (item, index) => (
            <div
              className="bg-white dark:bg-gray-900 dark:border-white/20 shadow-md border rounded-md py-2 px-4 flex items-center gap-2 relative"
              key={index}
            >
              <p className="flex-1">{item}</p>
              {isCopied === index ? (
                <CheckCheck className="w-4 text-green-500" />
              ) : (
                <Copy
                  className="w-4 cursor-pointer text-gray-500 dark:text-gray-400  hover:text-primary transition-all"
                  onClick={() => copyToClipboard(item, index)}
                />
              )}
            </div>
          )
        )}
      </div>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">6. Save changes:</b>
        After entering the new nameserver information, look for a "Save" or
        "Update" button and click on it to save your changes.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">7. Confirm changes:</b>
        Some registrars may require you to confirm the changes via email or
        another method. Follow any prompts or instructions to confirm the
        nameserver update.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">
          8. Wait for propagation:
        </b>
        DNS changes can take some time to propagate across the internet. This
        process typically takes a few hours to up to 48 hours, although it's
        often much quicker.
      </p>
      <p className="text-sm mt-5 text-gray-500 dark:text-gray-400 ">
        <b className="text-gray-800 dark:text-white">9. Verify changes:</b>
        Once the propagation is complete, we will notify you via email also you
        can verify that the nameserver change was successful by using online
        tools like DNS lookup or by visiting your website to ensure it loads
        correctly.
      </p>
    </>
  );
}

export default ModalContent;
