import React, { useEffect, useState, useCallback } from "react";
import {
  Info,
  LoaderCircle,
  PlusCircle,
  Edit,
  Trash,
  Check,
  X,
  ChevronUp,
} from "lucide-react";
import { DndProvider } from "react-dnd";
import useApi from "../../../../utils/useApi";
import toast, { Toaster } from "react-hot-toast";
import DraggableSection from "./DraggableSection";
import { useProjectContext } from "../ProjectContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import SectionHead from "../ProjectSidebar/SectionHead";
import InputField from "../../../../components/common/InputField";
import SwitchToggle from "../../../../components/common/SwitchToggle";
import usePermissions from "../../../../utils/userPermission";
import DraggableWidget from "./DraggableWidget";

const ProjectSections = ({ setIsCustomizing }) => {
  const { request } = useApi();
  const { project_id, refreshIframe, setIframeURL, projectToManage } =
    useProjectContext();
  const [layout, setLayout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [newWidgetName, setNewWidgetName] = useState("");
  const [newPageName, setNewPageName] = useState("");
  const [editPageIndex, setEditPageIndex] = useState(null);
  const [editPageName, setEditPageName] = useState("");
  const [showSections, setShowSections] = useState([]);
  const [addSectionIndex, setAddSectionIndex] = useState(null);
  const [addWidgetIndex, setAddWidgetIndex] = useState(null);
  const [addNewPage, setAddNewPage] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [blogsList, setBlogsList] = useState([]);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    if (project_id) {
      fetchSections();
      fetchBlogsList();
    }
  }, [project_id]);

  const fetchSections = async () => {
    setLoading(true);
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/layout`,
      });
      setLayout(res.data[0].value);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const fetchBlogsList = async () => {
    setLoading(true);
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/blog_list`,
      });
      setBlogsList(res.data[0].value);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const updateSectionsOnServer = async (updatedLayout, actionMessage) => {
    toast.promise(
      (async () => {
        const formData = new FormData();
        formData.append("key", "layout");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedLayout));

        await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLayout(updatedLayout);
        refreshIframe();
      })(),
      {
        loading: actionMessage.loading,
        success: actionMessage.success,
        error: actionMessage.error,
      }
    );
  };

  const moveSection = (pageIndex, dragIndex, hoverIndex) => {
    const updatedSections = [...layout];
    const [movedSection] = updatedSections[pageIndex].sections.splice(
      dragIndex,
      1
    );
    updatedSections[pageIndex].sections.splice(hoverIndex, 0, movedSection);
    setLayout(updatedSections);
    updateSectionsOnServer(updatedSections, {
      loading: "Updating section position...",
      success: `${movedSection.section} position changed`,
      error: "Failed to update section position.",
    });
  };

  const moveWidget = (pageIndex, dragIndex, hoverIndex) => {
    const updatedWidgets = [...layout];
    const [movedWidget] = updatedWidgets[pageIndex].widgets.splice(
      dragIndex,
      1
    );
    updatedWidgets[pageIndex].widgets.splice(hoverIndex, 0, movedWidget);
    setLayout(updatedWidgets);
    updateSectionsOnServer(updatedWidgets, {
      loading: "Updating widget position...",
      success: `${movedWidget.section} position changed`,
      error: "Failed to update widget position.",
    });
  };

  const addSection = async (pageIndex) => {
    if (!newSectionName.trim()) {
      alert("Please enter a section name.");
      return;
    }
    const newSection = {
      _id: (layout[pageIndex].sections.length + 1).toString(),
      section: newSectionName,
      template: "Standard (edit)",
      configuration: "N/A",
      enable: true,
      showHome: "Yes",
      showAll: "No",
    };
    const updatedSections = [...layout];
    updatedSections[pageIndex].sections.push(newSection);
    await updateSectionsOnServer(updatedSections, {
      loading: "Adding new section in page...",
      success: "New section added",
      error: "Failed to add section.",
    });
    setNewSectionName("");
    setAddSectionIndex(null);
  };

  const addWidget = async (pageIndex) => {
    if (!newWidgetName.trim()) {
      alert("Please enter a widget name.");
      return;
    }
    if (!layout[pageIndex]) {
      alert("Invalid page index.");
      return;
    }
    if (!layout[pageIndex].widgets) {
      layout[pageIndex].widgets = [];
    }

    const newWidget = {
      _id: (layout[pageIndex].widgets.length + 1).toString(),
      name: newWidgetName,
      enable: true,
    };

    const updatedWidgets = [...layout];
    updatedWidgets[pageIndex].widgets.push(newWidget);

    await updateSectionsOnServer(updatedWidgets, {
      loading: "Adding new widget in page...",
      success: "New widget added",
      error: "Failed to add widget.",
    });

    setNewWidgetName("");
    setAddWidgetIndex(null);
  };

  const addPage = async () => {
    if (!newPageName.trim()) {
      alert("Please enter a page name.");
      return;
    }
    const newPage = { page: newPageName, sections: [], enable: true };
    const updatedSections = [...layout, newPage];
    await updateSectionsOnServer(updatedSections, {
      loading: "Adding new page...",
      success: "New page added",
      error: "Failed to add page.",
    });
    setNewPageName("");
  };

  const startEditingPage = (index) => {
    setEditPageIndex(index);
    setEditPageName(layout[index].page);
  };

  const cancelEditingPage = () => {
    setEditPageIndex(null);
    setEditPageName("");
  };

  const saveEditPage = async (index) => {
    if (!editPageName.trim()) {
      toast.error("Page name cannot be empty");
      return;
    }
    const updatedSections = layout.map((page, i) =>
      i === index ? { ...page, page: editPageName.trim() } : page
    );
    setLayout(updatedSections);
    setEditPageIndex(null);
    setEditPageName("");
    await updateSectionsOnServer(updatedSections, {
      loading: "Updating page name...",
      success: "Page updated",
      error: "Failed to update page.",
    });
  };

  const deletePage = async (index) => {
    if (layout[index].sections.length > 0) {
      toast.error("This page has sections and cannot be deleted.");
      return;
    }
    const updatedSections = layout.filter((_, i) => i !== index);
    await updateSectionsOnServer(updatedSections, {
      loading: `Deleting page ${layout[index].page}...`,
      success: "Page deleted",
      error: "Failed to delete page.",
    });
  };

  const handleEnabledPage = async (index) => {
    const updatedSections = layout.map((page, i) =>
      i === index ? { ...page, enable: !page.enable } : page
    );
    const isEnabled = updatedSections[index].enable;
    await updateSectionsOnServer(updatedSections, {
      loading: isEnabled ? "Enabling page..." : "Disabling page...",
      success: isEnabled ? "Page is active now" : "Page disabled",
      error: "Failed to update page.",
    });
  };

  const toggleSections = (index) => {
    setShowSections((prevIndices) =>
      prevIndices.includes(index) ? [] : [index]
    );
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    const projectUrl = `https://${projectToManage?.project_name?.replaceAll(
      " ",
      "-"
    )}.sitebuilderz.com`;

    switch (page) {
      case "home":
        setIframeURL(projectUrl);
        break;
      case "category":
        if (blogsList[0]?.article_category?.name) {
          setIframeURL(
            `${projectUrl}/${blogsList[0].article_category.name
              .toLowerCase()
              .replaceAll(" ", "-")}`
          );
        }
        break;
      case "blog page":
        if (blogsList[0]?.article_category?.name) {
          setIframeURL(
            `${projectUrl}/${blogsList[0].article_category.name
              .toLowerCase()
              .replaceAll(" ", "-")}/${blogsList[0].title
              .toLowerCase()
              .replaceAll(" ", "-")}`
          );
        }
        break;
      case "terms":
        setIframeURL(`${projectUrl}/terms-and-conditions`);
        break;
      default:
        setIframeURL(
          `${projectUrl}/${page.toLowerCase().replaceAll(" ", "-")}`
        );
    }
  };

  const PageHeader = ({ item, pageIndex }) => (
    <div
      className={`flex justify-between items-center border-b dark:border-white/20 ${
        item.page === currentPage && editPageIndex !== pageIndex
          ? "bg-primary text-white dark:bg-primary rounded-l-md rounded-tr-md mx-1 px-1"
          : "hover:bg-gray-100 dark:hover:bg-gray-700 px-2"
      } ${showSections.includes(pageIndex) && "bg-gray-100"} ${
        editPageIndex === pageIndex && "bg-gray-100 dark:bg-gray-900"
      }`}
    >
      {editPageIndex === pageIndex ? (
        <input
          value={editPageName}
          onChange={(e) => setEditPageName(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter"
              ? saveEditPage(pageIndex)
              : e.key === "Escape" && cancelEditingPage()
          }
          className="inputField py-[1px] px-2 mr-2 rounded-md text-black flex-1 my-2 dark:text-white"
          autoFocus
        />
      ) : (
        <div
          onClick={() => {
            toggleSections(pageIndex);
            handlePageClick(item.page);
          }}
          className="capitalize flex items-center flex-1 cursor-pointer"
        >
          <ChevronUp
            className={`w-5 h-5 transition-all ${
              showSections.includes(pageIndex) ? "rotate-180" : ""
            } `}
          />
          <button className="flex-1 text-left p-2 capitalize">
            {item.page}
          </button>
        </div>
      )}
      <PageActions pageIndex={pageIndex} item={item} />
    </div>
  );

  const PageActions = ({ pageIndex, item }) => (
    <div className="flex items-center justify-end gap-1">
      {editPageIndex === pageIndex ? (
        <>
          <button
            onClick={() => saveEditPage(pageIndex)}
            className="btnPrimary gap-1 bg-secondary p-1"
          >
            <Check className="h-4 w-4 saveIcon" />
          </button>
          <button
            onClick={cancelEditingPage}
            className="btnPrimary gap-1 bg-red-500 p-1"
          >
            <X className="h-4 w-4 cancelIcon" />
          </button>
        </>
      ) : (
        <>
          {hasPermission("disable page") && (
            <SwitchToggle
              enabled={item.enable}
              handleEnabled={() => handleEnabledPage(pageIndex)}
            />
          )}
          {hasPermission("edit page") && (
            <button
              onClick={() => startEditingPage(pageIndex)}
              className="focus:outline-none"
            >
              <Edit
                className={`h-6 w-4 editIcon ${
                  item.page === currentPage && "text-white"
                }`}
              />
            </button>
          )}
          {hasPermission("delete page") && (
            <button
              onClick={() => deletePage(pageIndex)}
              className="focus:outline-none"
            >
              <Trash
                className={`h-6 w-4 deleteIcon ${
                  item.page === currentPage && "text-white"
                }`}
              />
            </button>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="h-[calc(100vh-6rem)] flex flex-col justify-between">
      <Toaster />
      <div>
        <SectionHead
          title="Project Layout"
          setIsCustomizing={setIsCustomizing}
        />
        <DndProvider backend={HTML5Backend}>
          <p className="text-secondary text-sm px-2 py-3 whitespace-nowrap flex items-center gap-1">
            <Info className="w-4 h-4" /> Drag sections to change position.
          </p>
          {loading ? (
            <div className="flex flex-col items-center justify-center py-24">
              <LoaderCircle className="w-10 h-10 text-primary animate-spin" />
            </div>
          ) : (
            layout.map((item, pageIndex) => (
              <div className="relative" key={pageIndex}>
                <PageHeader item={item} pageIndex={pageIndex} />
                <div
                  className={`section-transition ${
                    showSections.includes(pageIndex)
                      ? "max-h-screen mb-5"
                      : "max-h-0"
                  } overflow-hidden transition-all duration-500 ease-in-out pl-6 mx-1`}
                >
                  <p className="border-b border-primary text-primary w-full font-semibold mt-3">
                    Sections
                  </p>
                  <div className="flex flex-col rounded-b-xl overflow-hidden">
                    {item.sections.map((section, index) => (
                      <DraggableSection
                        index={index}
                        layout={layout}
                        key={section._id}
                        section={section}
                        setLayout={setLayout}
                        pageIndex={pageIndex}
                        moveSection={moveSection}
                        updateSectionsOnServer={updateSectionsOnServer}
                      />
                    ))}
                    {addSectionIndex === pageIndex ? (
                      <AddSectionForm
                        newSectionName={newSectionName}
                        setNewSectionName={setNewSectionName}
                        addSection={() => addSection(pageIndex)}
                        cancel={() => setAddSectionIndex(null)}
                      />
                    ) : (
                      hasPermission("add section") && (
                        <button
                          className="flex items-center px-3 py-2 gap-2 cursor-pointer transition-all hover:text-primary"
                          onClick={() => setAddSectionIndex(pageIndex)}
                        >
                          <PlusCircle className="w-4 h-4" />
                          Add Section
                        </button>
                      )
                    )}
                  </div>

                  <p className="border-b border-primary text-primary w-full font-semibold mt-3">
                    Widgets
                  </p>
                  <div className="flex flex-col rounded-b-xl overflow-hidden">
                    {item?.widgets?.map((widget, index) => (
                      <DraggableWidget
                        index={index}
                        layout={layout}
                        widget={widget}
                        key={widget._id}
                        pageIndex={pageIndex}
                        setLayout={setLayout}
                        moveWidget={moveWidget}
                        updateSectionsOnServer={updateSectionsOnServer}
                      />
                    ))}
                    {addWidgetIndex === pageIndex ? (
                      <AddWidgetForm
                        newWidgetName={newWidgetName}
                        setNewWidgetName={setNewWidgetName}
                        addWidget={() => addWidget(pageIndex)}
                        cancel={() => setAddWidgetIndex(null)}
                      />
                    ) : (
                      hasPermission("add widget") && (
                        <button
                          className="flex items-center px-3 py-2 gap-2 cursor-pointer transition-all hover:text-primary"
                          onClick={() => setAddWidgetIndex(pageIndex)}
                        >
                          <PlusCircle className="w-4 h-4" />
                          Add Widget
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </DndProvider>
      </div>
      {addNewPage ? (
        <AddPageForm
          newPageName={newPageName}
          setNewPageName={setNewPageName}
          addPage={addPage}
          cancel={() => setAddNewPage(false)}
        />
      ) : (
        hasPermission("add page") && (
          <button
            className="btnWhite border-primary border rounded-full text-primary hover:btnPrimary hover:rounded-full transition-all my-3 mx-2 shadow-none"
            onClick={() => setAddNewPage(true)}
          >
            <PlusCircle className="w-4 h-4" />
            Add New Page
          </button>
        )
      )}
    </div>
  );
};

const AddSectionForm = ({
  newSectionName,
  setNewSectionName,
  addSection,
  cancel,
}) => (
  <div className="mx-1 my-3">
    <InputField
      label="New Section"
      value={newSectionName}
      onChange={(e) => setNewSectionName(e.target.value)}
      onKeyDown={(e) => e.key === "Enter" && addSection()}
      placeholder="Enter section name"
      inputStyle="py-1 px-2"
      autoFocus
    />
    <div className="flex items-center justify-end gap-2 mt-2">
      <button className="btnWhite shadow-none px-1 py-1" onClick={cancel}>
        Cancel
      </button>
      <button className="btnPrimary px-3 py-1" onClick={addSection}>
        Add
      </button>
    </div>
  </div>
);

const AddWidgetForm = ({
  newWidgetName,
  setNewWidgetName,
  addWidget,
  cancel,
}) => (
  <div className="mx-1 my-3">
    <InputField
      label="New Widget"
      value={newWidgetName}
      onChange={(e) => setNewWidgetName(e.target.value)}
      onKeyDown={(e) => e.key === "Enter" && addWidget()}
      placeholder="Enter widget name"
      inputStyle="py-1 px-2"
      autoFocus
    />
    <div className="flex items-center justify-end gap-2 mt-2">
      <button className="btnWhite shadow-none px-1 py-1" onClick={cancel}>
        Cancel
      </button>
      <button className="btnPrimary px-3 py-1" onClick={addWidget}>
        Add
      </button>
    </div>
  </div>
);

const AddPageForm = ({ newPageName, setNewPageName, addPage, cancel }) => (
  <div className="mt-4 p-2">
    <InputField
      label="Add New Page"
      value={newPageName}
      onChange={(e) => setNewPageName(e.target.value)}
      onKeyDown={(e) => e.key === "Enter" && addPage()}
      placeholder="Enter new page name"
    />
    <div className="flex items-center justify-end gap-2 mt-2">
      <button className="btnWhite shadow-none px-1 py-1" onClick={cancel}>
        Cancel
      </button>
      <button className="btnPrimary" onClick={addPage}>
        Add
      </button>
    </div>
  </div>
);

export default ProjectSections;
