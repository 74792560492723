import React, { useState, useEffect, useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Facebook, Instagram, LoaderCircle, Save, X } from "lucide-react";
import MapGenerator from "./MapGenerator";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import { useProjectContext } from "../ProjectContext";

export default function ContactDetails() {
  const { request, } = useApi();
  const { project_id } = useProjectContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    socials: [
      { name: "facebook", link: "" },
      { name: "instagram", link: "" },
      { name: "x", link: "" },
    ],
    mapDetails: {
      location: "",
      mapUrl: "",
      center: { lat: 0, lng: 0 },
      loading: false,
      error: "",
    },
  });

  const fetchContactDetails = useCallback(async () => {
    try {
      const { data } = await request({
        method: "get",
        url: `projects/${project_id}/data/contact_details`,
      });

      const details = data[0].value || {};
      const socials = details.socials?.filter((social) => social.link) || [
        { name: "facebook", link: "" },
        { name: "instagram", link: "" },
        { name: "x", link: "" },
      ];

      setContactDetails((prev) => ({
        ...prev,
        ...details,
        socials,
      }));
    } catch (err) {
      console.error("Failed to fetch contact details:", err);
    }
  }, [project_id, request]);

  useEffect(() => {
    fetchContactDetails();
  }, [project_id]);

  const updateContactDetails = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      const formData = new FormData();
      formData.append("key", "contact_details");
      formData.append("value", JSON.stringify(contactDetails));
      formData.append("value_type", "JSON");
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Contact details updated");
    } catch (err) {
      toast.error(
        err.response?.data?.message || "Error updating contact details."
      );
    }
    setIsUpdating(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSocialChange = (index, value) => {
    setContactDetails((prev) => {
      const socials = [...prev.socials];
      socials[index].link = value;
      return { ...prev, socials };
    });
  };

  const handleMapDetailsChange = (newMapDetails) => {
    setContactDetails((prev) => ({
      ...prev,
      mapDetails: { ...prev.mapDetails, ...newMapDetails },
    }));
  };

  return (
    <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-10 pt-6">
      <Toaster />
      <div className="flex items-center justify-between mb-6">
        <h2 className="font-bold">Contact Details</h2>
        <button
          onClick={updateContactDetails}
          className="btnPrimary bg-green-600 dark:bg-green-800 px-3 ml-auto"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          {isUpdating ? "Updating" : "Save & Update"}
        </button>
      </div>
      <div className="w-full grid grid-cols-2 gap-10">
        <div className="flex flex-col gap-5 bg-white p-6 rounded-md">
          {["name", "email", "address", "phone"].map((field) => (
            <InputField
              key={field}
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              name={field}
              value={contactDetails[field]}
              onChange={handleChange}
            />
          ))}
          <h5 className="border-b font-semibold mt-3 mb-1">Socials</h5>
          {contactDetails.socials.map((social, index) => (
            <div key={social.name} className="flex items-center gap-2">
              {index === 0 && <Facebook className="w-6 h-6 text-gray-500" />}
              {index === 1 && <Instagram className="w-6 h-6 text-gray-500" />}
              {index === 2 && <X className="w-6 h-6 text-gray-500" />}
              <InputField
                placeholder={`${social.name}.com`}
                value={social.link}
                onChange={(e) => handleSocialChange(index, e.target.value)}
              />
            </div>
          ))}
        </div>
        <MapGenerator
          onUpdateMapDetails={handleMapDetailsChange}
          mapDetails={contactDetails.mapDetails}
        />
      </div>
    </div>
  );
}
