import React, { useEffect, useRef, useState } from "react";
import { DataTable, InputField } from "../../components";
import useApi from "../../utils/useApi";
import dayjs from "dayjs";
import { Edit, PlusCircle, SquareLibraryIcon, Trash } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";

export default function Registrar() {
  const [registrars, setRegistrars] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const { isLoading, error, request } = useApi();
  const dropdownRef = useRef(null);
  const deleteRef = useRef({});
  const updateRef = useRef({});

  const getRegistrars = async () => {
    try {
      const data = await request({ method: "get", url: "masters/registrar" });
      setRegistrars(data.data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getRegistrars();
  }, []);

  const addRegistrar = async (e) => {
    e.preventDefault();
    let value = e.currentTarget.input.value;
    try {
      await request({
        method: "post",
        url: "masters/registrar",
        data: { name: value },
      });
      getRegistrars();
      value = "";
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setDropdown(false);
  };

  const [deleteInd, setDelete] = useState(null);
  const deleteRegistrar = async (registrar) => {
    try {
      await request({
        method: "delete",
        url: `masters/registrar/${registrar._id}`,
      });
      getRegistrars();
      setDelete(null);
      toast.success(`${registrar.name} registrar removed.`);
    } catch (err) {
      console.error(err);
    }
  };

  const [updateReg, setUpdate] = useState(null);
  const updateRegistrar = async (e, registrar) => {
    e.preventDefault();
    const newValue = e.target.elements.update.value;
    try {
      await request({
        method: "put",
        url: `masters/registrar/${registrar._id}`,
        data: { name: newValue },
      });
      getRegistrars();
      setUpdate(null);
      toast.success(`${registrar.name} registrar updated.`);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setDelete(null);
        }
      });
      Object.entries(updateRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setUpdate(null);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { hasPermission } = usePermissions();

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderActionButtons = (registrar, index) => (
    <div className="flex items-center gap-2">
      {hasPermission("delete registrar") && (
        <div className="relative">
          <Trash onClick={() => setDelete(index)} className="deleteIcon" />
          {deleteInd === index && (
            <div
              ref={(el) => (deleteRef.current[index] = el)}
              className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
            >
              <p className="font-medium">
                Please confirm to delete {registrar.name} registrar
              </p>
              <div className="flex items-center justify-end mt-4">
                <button onClick={() => setDelete(null)} className="px-6">
                  Cancel
                </button>
                <button
                  onClick={() => deleteRegistrar(registrar)}
                  className="btnPrimary bg-red-500"
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {hasPermission("edit registrar") && (
        <div className="relative">
          <Edit onClick={() => setUpdate(index)} className="editIcon" />
          {updateReg === index && (
            <form
              ref={(el) => (updateRef.current[index] = el)}
              className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-xl border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50 w-80"
              onSubmit={(e) => updateRegistrar(e, registrar)}
            >
              <InputField
                name="update"
                label="Registrar Name"
                placeholder="Update registrar name"
                defaultValue={registrar.name}
              />
              <div className="flex items-center justify-end mt-4">
                <button onClick={() => setUpdate(null)} className="px-6">
                  Cancel
                </button>
                <button type="submit" className="btnPrimary bg-black">
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Toaster />
      {hasPermission("registrar") && (
        <DataTable
          searchBox
          title={
            <span className="flex items-center gap-2">
              <SquareLibraryIcon className="w-6 h-6" />
              Registrars
            </span>
          }
          isLoading={isLoading}
          heads={[
            "Sr#",
            ...(hasPermission("edit registrar") ||
            hasPermission("delete registrar")
              ? ["Actions"]
              : []),
            "Name",
            "Created At",
            "Updated At",
          ]}
          items={registrars?.map((registrar, index) => {
            const item = {
              serial: index + 1,
              name: registrar.name,
              createdAt: dayjs(registrar.createdAt)?.format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              updatedAt: dayjs(registrar.updatedAt)?.format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };

            if (
              hasPermission("edit registrar") ||
              hasPermission("delete registrar")
            ) {
              return {
                serial: item.serial,
                action: renderActionButtons(registrar, index),
                ...item,
              };
            }

            return item;
          })}
          extras={
            hasPermission("add registrar") && (
              <div className="relative text-black dark:text-white/80">
                {dropdown && (
                  <form
                    onSubmit={addRegistrar}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
                  >
                    <InputField
                      name="input"
                      label="Registrar Name"
                      placeholder="Enter new registrar name"
                    />
                    <div className="flex items-center justify-end mt-3">
                      <button type="submit" className="btnPrimary bg-black">
                        Add
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdown(!dropdown)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add Registrar
                </button>
              </div>
            )
          }
        />
      )}
    </div>
  );
}
