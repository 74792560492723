import React, { useEffect, useRef, useState } from "react";
import {
  DataTable,
  InputField,
  Modal,
  SwitchToggle,
} from "../../../components";
import useApi from "../../../utils/useApi";
import dayjs from "dayjs";
import MarkdownConverter from "../../../components/container/MarkdownConverter";
import {
  Camera,
  LoaderCircle,
  RefreshCw,
  SquarePen,
  Trash,
  X,
} from "lucide-react";
import { useTemplateContext } from "./TemplateContext";
import toast, { Toaster } from "react-hot-toast";

const TemplateData = () => {
  const { request } = useApi();
  const { template_id, industry_id, template } = useTemplateContext();

  const [templateData, setTemplateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState("newKey");
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [fileInfo, setFileInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [enableJSON, setEnableJSON] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteKey, setDeleteKey] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const cancelButtonRef = useRef(null);
  const deleteRef = useRef([]);
  const imageModalRef = useRef(null);

  useEffect(() => {
    if (industry_id && template_id) {
      fetchTemplateData();
    }
  }, [industry_id, template_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteRef.current.every((ref) => ref && !ref.contains(event.target))
      ) {
        setDeleteKey(null);
      }
      if (
        imageModalRef.current &&
        !imageModalRef.current.contains(event.target)
      ) {
        setImagePreview(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchTemplateData = async () => {
    setLoading(true);
    try {
      const { data } = await request({
        method: "get",
        url: `industries/${industry_id}/templates/${template_id}/data`,
      });

      const formattedData = data?.map((item) => ({
        ...item,
        value:
          typeof item.value === "object"
            ? JSON.stringify(item.value)
            : item.value,
      }));
      setTemplateData(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = () => setOpen(!open);
  const toggleEnabled = () => setEnabled(!enabled);
  const toggleEnableJSON = () => setEnableJSON(!enableJSON);

  const handleCreateNewKey = () => {
    setModalType("newKey");
    resetForm();
    toggleModal();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFileInfo(files);
  };

  const handleUpdateKey = (item) => {
    setKey(item.key);
    setValue(item.value);
    setFileInfo([
      {
        file: null,
        preview: `${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template_id}/${item.file_name}`,
      },
    ]);
    setModalType("updateKey");
    toggleModal();
  };

  const resetForm = () => {
    setKey("");
    setValue("");
    setFileInfo([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUpdating(true);

    const formData = new FormData();
    formData.append("key", key);
    formData.append("value_type", enableJSON ? "JSON" : "text");
    formData.append("value", value);
    fileInfo.forEach(({ file }) => formData.append("file", file));

    try {
      await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(modalType === "newKey" ? "New Tag Created" : "Tag Updated");
      fetchTemplateData();
      toggleModal();
    } catch (error) {
      toast.error(error.response?.data?.message || "Error occurred");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async (item) => {
    setDeleting(true);
    try {
      await request({
        method: "delete",
        url: `industries/${industry_id}/templates/${template_id}/data/${item._id}`,
        data: { key: item.key, value: item.value },
      });
      toast.success("Key removed.");
      fetchTemplateData();
      setDeleteKey(null);
    } catch (error) {
      toast.error("Error deleting key.");
    } finally {
      setDeleting(false);
    }
  };

  const parseJSON = () => {
    try {
      const parsed = JSON.parse(value);
      setValue(JSON.stringify(parsed, null, 2));
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const renderActions = (item, index) => (
    <div className="flex items-center gap-2">
      <button
        disabled={item.key === "blog_list"}
        className="relative disabled:cursor-not-allowed"
      >
        <Trash
          className="w-4 cursor-pointer text-red-400 hover:text-red-600"
          onClick={() => setDeleteKey(index)}
        />
        {deleteKey === index && (
          <div
            ref={(el) => (deleteRef.current[index] = el)}
            className={`p-5 absolute cursor-auto ${
              templateData.length > 9 && index >= templateData.length - 3
                ? "bottom-0"
                : "top-0"
            } left-0 ml-5 rounded-md shadow-2xl shadow-black/30 bg-white dark:bg-gray-800 z-50`}
          >
            <p className="font-medium">Please confirm to delete key</p>
            <div className="flex items-center justify-end mt-4">
              <button onClick={() => setDeleteKey(null)} className="px-6">
                Cancel
              </button>
              <button
                onClick={() => handleDelete(item)}
                className="btnPrimary py-1 px-3 bg-red-500"
              >
                {deleting ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {deleting ? <p>Removing</p> : <p>Remove Tag</p>}
              </button>
            </div>
          </div>
        )}
      </button>
      <button
        disabled={item.key === "blog_list"}
        className="cursor-pointer disabled:cursor-not-allowed"
        onClick={() => handleUpdateKey(item)}
      >
        <SquarePen className="w-4" />
      </button>
    </div>
  );

  const handleImagePreview = (imageUrl) => {
    setImagePreview(imageUrl);
  };

  return (
    <div className="pt-3">
      <Toaster position="top-center" reverseOrder={false} />
      <DataTable
        headerColor="none"
        tableHeight="h-[calc(100vh-220px)]"
        isLoading={loading}
        searchBox
        title={template?.replaceAll("-", " ")}
        className="-mt-2"
        heads={[
          "Sr#",
          "Actions",
          "Key",
          "Value",
          "Image",
          "Created At",
          "Updated At",
        ]}
        items={templateData.map((item, index) => ({
          serial: index + 1,
          actions: renderActions(item, index),
          key: item.key,
          value: <div className="w-56 overflow-hidden">{item.value}</div>,
          image: item.file_name && (
            <img
              src={`${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template_id}/${item.file_name}`}
              className="w-14 h-9 rounded-lg cursor-pointer"
              alt="tag"
              onClick={() =>
                handleImagePreview(
                  `${process.env.REACT_APP_PUBLIC_API}/images/industry_template_images/${template_id}/${item.file_name}`
                )
              }
            />
          ),
          createdAt: dayjs(item.createdAt).format("ddd, MMM D, YYYY h:mm A"),
          updatedAt: dayjs(item.updatedAt).format("ddd, MMM D, YYYY h:mm A"),
        }))}
        extras={
          <div className="flex items-center gap-3">
            <RefreshCw
              onClick={fetchTemplateData}
              className={`w-5 cursor-pointer dark:text-white/80 ${
                loading && "animate-spin"
              }`}
            />
            <button onClick={handleCreateNewKey} className="btnPrimary">
              Add New Key
            </button>
          </div>
        }
      />

      <Modal
        open={open}
        handleModal={toggleModal}
        cancelButtonRef={cancelButtonRef}
        disabled={!key || !value}
        handleModalSubmit={handleSubmit}
        className={enabled ? "max-w-screen-xl" : "max-w-3xl"}
        isUpdating={isUpdating}
        modalType="new/edit"
      >
        <div className="flex items-center justify-between mb-5">
          <h2 className="text-xl font-semibold">
            {modalType === "newKey" ? "Create New Tag" : "Update Tag Value"}
          </h2>
          <div className={`relative ${enabled ? "w-96" : ""}`}>
            {enabled && (
              <div
                className={`overflow-hidden h-12 transition-all z-20 absolute top-0 w-full rounded-md bg-gray-300 -mt-5 border dark:border-white/20 flex items-center justify-end ${
                  fileInfo.length && "hover:h-80 hover:scale-150 hover:mt-16"
                }`}
              >
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="imageUpload"
                  className="hidden"
                  accept="image/*"
                  multiple
                />
                <label
                  htmlFor="imageUpload"
                  className="btnWhite py-1 px-3 cursor-pointer flex items-center gap-2 z-50 m-2 absolute right-0 top-0 h-fit"
                >
                  Add Image
                  <Camera className="w-4" />
                </label>
                {fileInfo.length > 0 &&
                  fileInfo.map((file, index) => (
                    <img
                      key={index}
                      src={file.preview}
                      loading="lazy"
                      alt="preview"
                      className="w-[150px] object-cover"
                    />
                  ))}
              </div>
            )}
          </div>
          <div className="flex items-center gap-6 justify-end">
            <div className="flex items-center gap-1">
              JSON
              <SwitchToggle
                enabled={enableJSON}
                handleEnabled={toggleEnableJSON}
              />
            </div>
            <div className="flex items-center gap-1">
              Markdown
              <SwitchToggle enabled={enabled} handleEnabled={toggleEnabled} />
            </div>
          </div>
        </div>

        {!enabled && (
          <div className="relative my-10 pb-2">
            <div
              className={`overflow-hidden h-12 transition-all z-20 absolute top-0 w-full rounded-md bg-gray-300 -mt-5 border dark:border-white/20 flex items-center justify-end ${
                fileInfo.length && "hover:h-80"
              }`}
            >
              <input
                type="file"
                onChange={handleFileChange}
                id="imageUpload"
                className="hidden"
                accept="image/*"
                multiple
              />
              <label
                htmlFor="imageUpload"
                className="btnWhite py-1 px-3 cursor-pointer flex items-center gap-2 z-50 m-2 absolute right-0 top-0 h-fit"
              >
                Add Image
                <Camera className="w-4" />
              </label>
              <div className="flex flex-wrap">
                {fileInfo.map((file, index) => (
                  <img
                    key={index}
                    src={file.preview}
                    loading="lazy"
                    alt="preview"
                    className="w-[150px] object-cover mr-2 mb-2"
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        <InputField
          label="New Key"
          placeholder="Enter key tag"
          value={key}
          onChange={(event) => setKey(event.target.value)}
          required
          disabled={modalType === "updateKey"}
        />

        {enabled ? (
          <MarkdownConverter
            newValue={value}
            setNewValue={setValue}
            height="h-[calc(100vh-340px)]"
          />
        ) : (
          <div className="mt-4 relative">
            <label htmlFor="new-value" className="inputLabel">
              New value
            </label>
            <textarea
              id="new-value"
              placeholder={
                enableJSON ? "Enter JSON value" : "Enter above tag value here"
              }
              className={`inputField mt-1 h-80 ${
                enableJSON && "bg-gray-900 dark:bg-gray-900 text-teal-500"
              }`}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              required
            />
            {enableJSON && (
              <button
                type="button"
                onClick={parseJSON}
                className="absolute top-0 right-0 mt-7 py-1 px-3 rounded mr-2 bg-gray-300 text-sm border border-gray-500 text-black"
              >
                Parse
              </button>
            )}
          </div>
        )}
      </Modal>

      {imagePreview && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-10">
          <X
            className="w-12 h-12 absolute top-0 right-0 m-10 text-white cursor-pointer"
            onClick={() => setImagePreview(null)}
          />
          <div
            ref={imageModalRef}
            className="bg-gray-200 p-4 rounded shadow-lg max-h-[calc(100vh-200px) max-w-screen-lg overflow-hidden]"
          >
            <img
              src={imagePreview}
              alt="preview"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateData;
