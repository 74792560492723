import React, { useEffect, useRef, useState } from "react";
import Dropdown from "./Dropdown";
import {
  Edit,
  FileWarning,
  Info,
  LoaderCircle,
  PenSquare,
  Plus,
  Save,
  Trash,
  X,
} from "lucide-react";
import { useArticleContext } from "../ArticleContext";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import useApi from "../../../../../utils/useApi";
import usePermissions from "../../../../../utils/userPermission";
import { CheckBox, InputField, ListBox } from "../../../../../components";

export default function ArticleCard({
  blog,
  index,
  deleteConfirmation,
  setDeleteConfirmation,
  select,
  isSelected,
  onSingleSelect,
}) {
  const {
    handleUpdateBlog,
    grid,
    deleteBlog,
    deletingArticle,
    deleteFromList,
    project_id,
    blogsList,
    categories,
  } = useArticleContext();

  const [tagsModal, setTagsModal] = useState(false);
  const tagsModalRef = useRef(null);

  const [blogTags, setBlogTags] = useState(blog?.tags || []);
  const [input, setInput] = useState("");
  const [action, setAction] = useState(false);

  const { request } = useApi();

  const addTag = (e) => {
    if ((e.key === "Enter" || e.type === "click") && input.trim() !== "") {
      setBlogTags([...blogTags, input.trim()]);
      setInput("");
    }
  };

  const removeTag = (index) => {
    setBlogTags(blogTags.filter((_, i) => i !== index));
  };

  const handleSaveTags = async () => {
    setAction(true);
    const updatedBlog = { ...blog, tags: blogTags };
    const updatedBlogsList = (blogsList || [])?.map((blogItem) =>
      blogItem._id === updatedBlog._id ? updatedBlog : blogItem
    );
    const blogIndex = updatedBlogsList.findIndex(
      (blogItem) => blogItem.key === updatedBlog.key
    );

    let allTags = [];

    updatedBlogsList.forEach((article) => {
      const tags = Array.isArray(article?.tags) ? article.tags : [];
      allTags = [...allTags, ...tags];
    });
    const uniqueTags = [...new Set(allTags)];

    const tagArticleMapping = uniqueTags.map((tag) => {
      const article_ids = updatedBlogsList
        .filter(
          (article) => Array.isArray(article.tags) && article.tags.includes(tag)
        )
        .map((article) => article.key);
      return { tag, article_ids };
    });

    const tagData = new FormData();
    tagData.append("key", "tag_list");
    tagData.append("value_type", "JSON");
    tagData.append("value", JSON.stringify(tagArticleMapping));

    await request({
      method: "post",
      url: `projects/${project_id}/data`,
      data: tagData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (blogIndex !== -1) {
      updatedBlogsList[blogIndex] = updatedBlog;

      try {
        const formData = new FormData();
        formData.append("key", "blog_list");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedBlogsList));

        await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Article tags updated successfully.");
        setDeleteConfirmation(false);
        setTagsModal(false);
      } catch (err) {
        toast.error("Couldn't update blog tags, an error occured.");
      }
    }
    setAction(false);
  };

  const { hasPermission } = usePermissions();

  const handleClickOutside = (event) => {
    if (tagsModalRef.current && !tagsModalRef.current.contains(event.target)) {
      setTagsModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [article_category, setArticleCategory] = useState(
    blog?.article_category
  );

  const handleUpdateCat = async (category) => {
    setAction(true);
    const updatedBlog = { ...blog, article_category: category };
    const updatedBlogsList = (blogsList || [])?.map((blogItem) =>
      blogItem._id === updatedBlog._id ? updatedBlog : blogItem
    );
    const blogIndex = updatedBlogsList.findIndex(
      (blogItem) => blogItem.key === updatedBlog.key
    );

    if (blogIndex !== -1) {
      updatedBlogsList[blogIndex] = updatedBlog;

      const formData = new FormData();
      formData.append("key", "blog_list");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedBlogsList));

      const updatePromise = request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.promise(updatePromise, {
        loading: "Updating article category...",
        success: "Article category updated successfully.",
        error: "Couldn't update blog category, an error occurred.",
      });

      try {
        await updatePromise;
      } catch (err) {
        toast.error("Couldn't update blog category, an error occurred.");
      }
    }

    setAction(false);
  };

  return (
    <div className="flex gap-2 items-start w-full">
      <Toaster />

      {select && (
        <CheckBox
          checked={isSelected(blog?._id)}
          className="h-6 w-6"
          boxSize="h-7 w-7"
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: blog,
            })
          }
        />
      )}
      <div
        className={`bg-white flex-1 dark:bg-gray-800 shadow-lg rounded  ${
          grid ? "flex flex-col" : "grid grid-cols-articleCard"
        }`}
      >
        <div
          className={`overflow-hidden cursor-pointer relative rounded ${
            grid ? "h-40" : "h-full"
          }`}
          onClick={
            hasPermission("Edit Project Article")
              ? () => handleUpdateBlog(blog)
              : null
          }
        >
          <img
            src={`${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${blog?.image}`}
            alt="no article coverphoto"
            className="object-fill absolute m-auto min-w-full min-h-full hover:scale-110 transition-all"
          />
        </div>
        <div className="py-2 px-3 flex flex-col justify-between flex-1">
          <div>
            <div className="flex items-center flex-wrap justify-between gap-2">
              <p className="capitalize font-semibold leading-tight mt-1">
                {blog?.title}
              </p>
              <div className="flex items-center flex-wrap justify-end gap-3">
                <p className="dark:text-white/80 text-xs text-gray-500">
                  {blog?.author} -{" "}
                  {dayjs(blog?.published_at)?.format("MMM D, YYYY")}
                </p>
                <div className="flex items-center gap-2">
                  {hasPermission("Delete Project Article") && (
                    <Trash
                      className="deleteIcon"
                      onClick={() => deleteBlog(blog)}
                    />
                  )}
                  {hasPermission("Edit Project Article") && (
                    <Edit
                      className="editIcon"
                      onClick={() => handleUpdateBlog(blog)}
                    />
                  )}
                </div>
                <Dropdown blog={blog} index={index} deleteBlog={deleteBlog} />
              </div>
            </div>
            <p className="text-xs text-gray-400 dark:text-white/80 mt-1">
              {grid
                ? blog?.articleContent?.slice(0, 110)
                : blog?.articleContent}
              ...
            </p>
          </div>
          <div className="flex flex-wrap items-center mt-2 gap-1">
            <p className="font-medium text-sm text-gray-500 dark:text-white/80">
              Category:
            </p>
            <ListBox
              inputStyle="py-0 px-2 w-44 border-gray-400 text-sm"
              name="article_category"
              placeholder="Select"
              options={categories?.map((item, index) => ({
                _id: index + 1,
                name: item,
              }))}
              selectedOption={article_category}
              setSelectedOption={(category) => {
                setArticleCategory(category);
                handleUpdateCat(category);
              }}
              optionsStyle="mt-7 text-sm"
            />
            {!grid && (
              <>
                <div className="flex items-center gap-1 ml-5">
                  <p className="font-medium text-sm">Tags:</p>
                </div>
                {blogTags.map((tag, index) => (
                  <span
                    key={index}
                    className="flex items-center text-xs px-2 bg-orange-100 text-orange-600 rounded gap-2"
                  >
                    {tag}
                  </span>
                ))}

                <button
                  type="button"
                  onClick={() => setTagsModal(true)}
                  className="flex items-center text-xs font-medium mx-1 gap-1 hover:text-primary hover:border-primary border-b border-black transition-all"
                >
                  <Plus className="w-4 h-4" /> Add/Edit Tags
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {deleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800/10 dark:bg-black/20 top-0 left-0 z-50">
          <div className="bg-white p-6 rounded shadow-xl w-full max-w-md">
            <div className="flex items-center gap-2">
              <FileWarning className="w-5 h-5 text-red-500" />{" "}
              <h2 className="text-lg font-bold">Are you sure?</h2>
            </div>
            <p>Do you really want to delete this article.</p>
            <div className="flex justify-end mt-4 gap-2">
              <button
                onClick={(e) => deleteFromList(e)}
                className="btnPrimary bg-red-500 text-sm"
                disabled={deletingArticle}
              >
                {deletingArticle ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {deletingArticle ? <p>Deleting</p> : <p>Delete Article</p>}
              </button>
              <button
                className="bg-gray-300 px-4 py-2 rounded-md text-sm"
                onClick={() => setDeleteConfirmation(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {tagsModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 dark:bg-black/50 bg-opacity-75 transition-opacity">
          <div
            ref={tagsModalRef}
            className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-2xl dark:border-white/20 max-w-2xl w-full"
          >
            <h3 className="font-bold">Article Tags</h3>
            <p className="text-secondary text-sm py-2 mb-3 whitespace-nowrap flex items-center gap-1">
              <Info className="w-4 h-4" /> Click on add or press enter to add
              tag.
            </p>
            <div className="flex items-end gap-3">
              <InputField
                label="new tag"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={addTag}
                autoFocus
              />
              <button
                type="button"
                className="btnPrimary text-base"
                disabled={!input}
                onClick={addTag}
              >
                Add
              </button>
            </div>

            <div className="flex items-center flex-wrap gap-2 mt-5">
              {blogTags.map((tag, index) => (
                <span
                  key={index}
                  className="flex items-center px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded gap-2"
                >
                  {tag}
                  <X
                    className="w-4 h-4 text-red-400 hover:text-red-500 cursor-pointer"
                    onClick={() => removeTag(index)}
                  />
                </span>
              ))}
            </div>

            <div className="flex items-center justify-end mt-7 gap-2">
              <button
                type="button"
                title="close"
                onClick={() => setTagsModal(false)}
                className="btnPrimary bg-red-400 hover:bg-red-500"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={action || input}
                onClick={handleSaveTags}
                className={`btnPrimary bg-secondary px-3 ${
                  action && "cursor-wait"
                }`}
              >
                {action ? (
                  <LoaderCircle className="w-4 animate-spin" />
                ) : (
                  <Save className="w-4 h-4" />
                )}
                {action ? <p>Updating Tags</p> : <p>Save & Update</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
