import React, { useEffect, useRef, useState } from "react";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import toast from "react-hot-toast";
import { Check, Edit, LoaderCircle, Trash, X } from "lucide-react";
import { useProjectContext } from "../ProjectContext";
import usePermissions from "../../../../utils/userPermission";

export default function ProjArticleCategories() {
  const { request, isLoading } = useApi();
  const { project_id } = useProjectContext();
  const [categories, setCategories] = useState([]);
  const [input, setInput] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editInput, setEditInput] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(null);
  const deleteRef = useRef({});

  const getCategories = async () => {
    if (!project_id) return;

    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${"categories"}`,
      });
      setCategories(res?.data?.[0]?.value || []);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  const [blogsList, setBlogsList] = useState([]);
  const getBlogList = async () => {
    if (!project_id) return;

    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${"blog_list"}`,
      });
      setBlogsList(res?.data?.[0]?.value || []);
    } catch (err) {
      console.error("Error fetching blogs list:", err);
    }
  };

  useEffect(() => {
    getBlogList();
    getCategories();
  }, [project_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) setDeleteIndex(null);
      });
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updateCategoriesOnServer = async (updatedCategories) => {
    try {
      const formData = new FormData();
      formData.append("key", "categories");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedCategories));

      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setCategories(updatedCategories);
      toast.success("Categories updated successfully");
    } catch (error) {
      toast.error("Failed to update categories");
    }
  };

  const addCategory = async (e) => {
    e.preventDefault();
    if (!categories.includes(input.trim())) {
      const updatedCategories = [...categories, input.trim()];
      setCategories(updatedCategories);
      setInput("");
      await updateCategoriesOnServer(updatedCategories);
    } else {
      toast.error("Category already exists");
    }
  };

  const handleDelete = (index, category) => {
    const categoryExists = blogsList.some(
      (blog) => blog.article_category.name === category
    );

    if (categoryExists) {
      toast.error(`Cannot delete category "${category}" - Blogs exist!`);
    } else {
      setDeleteIndex(index);
    }
  };

  const removeCategory = async (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
    await updateCategoriesOnServer(updatedCategories);
    setDeleteIndex(null);
  };

  const startEditing = (index) => {
    setEditIndex(index);
    setEditInput(categories[index]);
  };

  const cancelEditing = () => {
    setEditIndex(null);
    setEditInput("");
  };

  const saveEdit = async (index) => {
    if (editInput.trim() !== "") {
      const updatedCategories = categories.map((category, i) =>
        i === index ? editInput.trim() : category
      );
      setCategories(updatedCategories);
      setEditIndex(null);
      setEditInput("");
      await updateCategoriesOnServer(updatedCategories);
    } else {
      toast.error("Category name cannot be empty");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim() !== "") {
      addCategory(e);
    }
  };

  const handleEditKeyDown = (e, index) => {
    if (e.key === "Enter" && editInput.trim() !== "") {
      saveEdit(index);
    } else if (e.key === "Escape") {
      cancelEditing();
    }
  };

  const { hasPermission } = usePermissions();

  return (
    hasPermission("Project Categories") && (
      <div className="py-6 px-8 flex flex-col items-center">
        <h2 className="mb-3 font-bold">Project Categories</h2>
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-fit">
          {hasPermission("Add Project Category") && (
            <div className="flex items-end gap-2">
              <InputField
                label="New Category"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Add a category and press Enter"
              />
              <button onClick={addCategory} className="btnPrimary text-base">
                Add
              </button>
            </div>
          )}

          <div className="grid mt-5 divide-x divide-gray-200 dark:divide-white/20 border-x dark:border-white/20 border-b grid-cols-categories">
            {["Sr#", "Category", "Article Count", "Actions"].map(
              (item, index) => (
                <div
                  key={index}
                  className="tableHead text-gray-500 w-full h-full bg-white dark:bg-gray-800 text-start"
                >
                  {item}
                </div>
              )
            )}
          </div>

          <div className="flex flex-col">
            {isLoading ? (
              <LoaderCircle className="animate-spin h-10 w-10 mx-auto my-12 text-primary" />
            ) : (
              categories?.map((category, index) => (
                <div
                  key={index}
                  className={`dark:border-white/20 border-x border-x-transparent ${
                    editIndex === index
                      ? "flex items-center justify-between py-4 border-b dark:border-white/20"
                      : "border-b grid grid-cols-categories divide-x divide-gray-200 dark:divide-white/20"
                  }`}
                >
                  {editIndex !== index && (
                    <p className="py-2 px-3">{index + 1}</p>
                  )}
                  {editIndex === index ? (
                    <input
                      value={editInput}
                      onChange={(e) => setEditInput(e.target.value)}
                      onKeyDown={(e) => handleEditKeyDown(e, index)}
                      className="inputField py-[1px] mr-2 rounded-md"
                    />
                  ) : (
                    <span className="py-2 px-3 capitalize">{category}</span>
                  )}
                  {editIndex !== index && (
                    <span className="py-2 px-5">
                      {
                        blogsList.filter(
                          (item) => item?.article_category?.name === category
                        ).length
                      }
                    </span>
                  )}
                  <div className="flex items-center justify-end gap-2">
                    {editIndex === index ? (
                      <>
                        <button
                          onClick={() => saveEdit(index)}
                          className="btnPrimary py-[1px] gap-1 bg-secondary px-2"
                        >
                          <Check className="h-6 w-4 saveIcon" />
                          Update
                        </button>
                        <button
                          onClick={cancelEditing}
                          className="btnPrimary py-[1px] gap-1 bg-red-500 px-2"
                        >
                          <X className="h-6 w-4 cancelIcon" />
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        {hasPermission("Edit Project Category") && (
                          <button
                            onClick={() => startEditing(index)}
                            className="ml-2 focus:outline-none"
                          >
                            <Edit className="h-6 w-4 editIcon" />
                          </button>
                        )}
                        {hasPermission("Delete Project Category") && (
                          <DeleteAction
                            index={index}
                            deleteIndex={deleteIndex}
                            category={category}
                            handleDelete={handleDelete}
                            setDeleteIndex={setDeleteIndex}
                            removeCategory={removeCategory}
                            ref={deleteRef}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  );
}

const DeleteAction = React.forwardRef(
  (
    {
      index,
      deleteIndex,
      setDeleteIndex,
      removeCategory,
      handleDelete,
      category,
    },
    ref
  ) => (
    <div className="relative">
      <Trash
        onClick={() => handleDelete(index, category)}
        className="deleteIcon"
      />
      {deleteIndex === index && (
        <div
          ref={(el) => (ref.current[index] = el)}
          className="p-5 w-72 absolute top-0 right-0 mr-5 rounded-lg shadow-2xl border border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
        >
          <p className="font-medium">Please confirm to delete category</p>
          <div className="flex items-center justify-end mt-4">
            <button onClick={() => setDeleteIndex(null)} className="px-6">
              Cancel
            </button>
            <button
              onClick={() => removeCategory(index)}
              className="btnPrimary bg-red-500"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  )
);
