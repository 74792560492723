import React, { createContext, useContext, useEffect, useState } from "react";
import useApi from "../../../../utils/useApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../ProjectContext";

const ArticleContext = createContext();

export const useArticleContext = () => useContext(ArticleContext);

export const ArticleProvider = ({ children }) => {
  const { request, isLoading } = useApi();
  const { project_id, projectToManage } = useProjectContext();
  const [blogsList, setBlogsList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fileInfo, setFileInfo] = useState(null);
  const [article, setArticle] = useState({});
  const [grid, setGrid] = useState(false);

  const getCategories = async () => {
    try {
      const data = await request({
        method: "get",
        url: `projects/${project_id}/data/${"categories"}`,
      });
      setCategories(data?.data[0]?.value);
    } catch (err) {
      console.error(err);
    }
  };

  const [loadingArticles, setLoadingArticles] = useState(false);
  const getBlogsList = async () => {
    setLoadingArticles(true);
    try {
      const data = await request({
        method: "get",
        url: `projects/${project_id}/data/${"blog_list"}`,
      });
      setBlogsList(data.data[0].value);
    } catch (err) {
      console.error(err);
    }
    setLoadingArticles(false);
  };
  const getTagList = async () => {
    try {
      const data = await request({
        method: "get",
        url: `projects/${project_id}/data/${"tag_list"}`,
      });
      setTagList(data?.data[0]?.value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getBlogsList();
    getTagList();
    getCategories();
  }, [project_id]);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState("");
  const deleteBlog = (blog) => {
    setDeleteConfirmation(true);
    setKeyToDelete(`blog_${blog.key}`);
  };

  const [deletingArticle, setDeletingArticle] = useState(false);
  const deleteFromList = async (e) => {
    e.preventDefault();
    setDeletingArticle(true);

    const index = blogsList.findIndex(
      (blog) => `blog_${blog.key}` === keyToDelete
    );

    if (index !== -1) {
      const updatedBlogsList = [...blogsList];
      updatedBlogsList.splice(index, 1);
      setBlogsList(updatedBlogsList);

      try {
        const formData = new FormData();
        formData.append("key", "blog_list");
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(updatedBlogsList));

        const res = await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (res.status) {
          toast.success("Article Removed.");
        }
      } catch (err) {
        console.error("Error deleting blog:", err);
      }
    }

    setDeletingArticle(false);
  };

  const getBlog = async (articleKey) => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${articleKey}`,
      });

      const blog = res.data[0].value;
      const file = res.data[0].file_name;
      setFileInfo({
        file: file,
        preview: `${process.env.REACT_APP_PUBLIC_API}/images/project_images/${project_id}/${file}`,
      });
      setArticle(blog);
    } catch (err) {
      console.error(err);
    }
  };

  const navigate = useNavigate();
  const handleUpdateBlog = (blog) => {
    console.log("Blog", blog);
    navigate(
      `/projects/${projectToManage?.project_name
        ?.replaceAll(" ", "-")
        ?.toLowerCase()}/articles/${blog?.key}`
    );
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFileInfo({
      file: file,
      preview: URL.createObjectURL(file),
    });
  };

  return (
    <ArticleContext.Provider
      value={{
        grid,
        setGrid,
        blogsList,
        getBlogsList,
        deleteFromList,
        deleteBlog,
        handleUpdateBlog,
        deleteConfirmation,
        setDeleteConfirmation,
        isLoading,
        setArticle,
        setFileInfo,
        article,
        fileInfo,
        handleFileChange,
        getBlog,
        project_id,
        categories,
        tagList,
        projectToManage,
        deletingArticle,
        loadingArticles,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};
