import React, { useEffect, useState, useCallback, memo } from "react";
import useApi from "../../../../utils/useApi";
import { useProjectContext } from "../ProjectContext";
import { InputField, TextArea } from "../../../../components";
import toast, { Toaster } from "react-hot-toast";
import { LoaderCircle, Save } from "lucide-react";
import Robots from "./Robots";

// Custom hook for fetching metadata
const useMetaData = (project_id, key, request) => {
  const [data, setData] = useState({ title: "", description: "" });

  const fetchMetaData = useCallback(async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${key}`,
      });
      const { title = "", description = "" } = res.data[0]?.value || {};
      setData({ title, description });
    } catch (err) {
      console.error(err);
    }
  }, [project_id, key, request]);

  useEffect(() => {
    fetchMetaData();
  }, []);

  return [data, setData, fetchMetaData];
};

// Custom hook for fetching tag list
const useTagList = (project_id, request) => {
  const [tagList, setTagList] = useState([]);

  const fetchTagList = useCallback(async () => {
    try {
      const data = await request({
        method: "get",
        url: `projects/${project_id}/data/tag_list`,
      });
      setTagList(data?.data[0]?.value || []);
    } catch (err) {
      console.error(err);
    }
  }, [project_id, request]);

  useEffect(() => {
    fetchTagList();
  }, []);

  return tagList;
};

// Reusable component for meta sections
const MetaSection = memo(
  ({ sectionId, label, metaData, onMetaChange, onUpdateMeta, isUpdating }) => (
    <section
      id={sectionId}
      className="px-7 py-6 shadow-md bg-white dark:bg-gray-800 rounded-lg"
    >
      <div className="flex items-center justify-between border-b pb-4 mb-5">
        <h4 className="font-bold">{label}</h4>
        <button
          onClick={onUpdateMeta}
          className="btnPrimary bg-secondary py-1 px-3"
        >
          {isUpdating ? (
            <LoaderCircle className="w-4 animate-spin" />
          ) : (
            <Save className="w-4" />
          )}
          {isUpdating ? "Updating" : "Save & Update"}
        </button>
      </div>
      <InputField
        label="Meta Title"
        name="title"
        placeholder="Meta Title"
        value={metaData.title}
        onChange={onMetaChange}
      />
      <CharacterCount
        label="Recommended Characters: 50-60"
        currentCount={metaData.title.length}
        isWithinRange={
          metaData.title.length >= 50 && metaData.title.length <= 60
        }
      />
      <TextArea
        label="Meta Description"
        name="description"
        placeholder="Meta Description"
        value={metaData.description}
        onChange={onMetaChange}
      />
      <CharacterCount
        label="Recommended Characters: 150-160"
        currentCount={metaData.description.length}
        isWithinRange={
          metaData.description.length >= 150 &&
          metaData.description.length <= 160
        }
      />
    </section>
  )
);

// Character count display component
const CharacterCount = ({ label, currentCount, isWithinRange }) => (
  <div className="flex items-center justify-between mb-5 mt-1">
    <p className="text-sm">
      <strong className="text-warmGray">{label}</strong>
    </p>
    <p
      className={`text-sm ${isWithinRange ? "text-green-600" : "text-red-500"}`}
    >
      Character count: {currentCount} {isWithinRange ? "" : "❗"}
    </p>
  </div>
);

// Blog Tags component
const BlogTags = memo(({ tagList }) => (
  <section
    id="blogTags"
    className="px-7 py-6 bg-white dark:bg-gray-800 rounded-lg"
  >
    <h4 className="border-b pb-4 mb-5 font-bold">Blog Tags</h4>
    <div className="flex items-center flex-wrap gap-2 mt-6">
      {tagList.map((item, index) => (
        <TagItem key={index} tag={item.tag} count={item.article_ids?.length} />
      ))}
    </div>
  </section>
));

const TagItem = ({ tag, count }) => (
  <p className="px-3 py-1 rounded bg-orange-100 text-primary text-sm shadow">
    {tag}{" "}
    {count > 1 && (
      <span className="rounded-full bg-primary text-white px-1 text-xs ml-1">
        {count}
      </span>
    )}
  </p>
);

export default function ProjectSEO() {
  const { request } = useApi();
  const { project_id } = useProjectContext();
  const [activeSection, setActiveSection] = useState("robots");
  const [isUpdating, setIsUpdating] = useState("");

  // Fetch and manage meta data states
  const [metaHome, setMetaHome, getMetaHome] = useMetaData(
    project_id,
    "meta_home",
    request
  );
  const [metaAbout, setMetaAbout, getMetaAbout] = useMetaData(
    project_id,
    "meta_about",
    request
  );
  const [metaContact, setMetaContact, getMetaContact] = useMetaData(
    project_id,
    "meta_contact",
    request
  );
  const [metaPrivacy, setMetaPrivacy, getMetaPrivacy] = useMetaData(
    project_id,
    "meta_privacy",
    request
  );
  const [metaTerms, setMetaTerms, getMetaTerms] = useMetaData(
    project_id,
    "meta_terms",
    request
  );
  const [metaCategory, setMetaCategory, getMetaCategory] = useMetaData(
    project_id,
    "meta_category",
    request
  );
  const [metaTags, setMetaTags, getMetaTags] = useMetaData(
    project_id,
    "meta_tags",
    request
  );
  const [metaTag, setMetaTag, getMetaTag] = useMetaData(
    project_id,
    "meta_tag",
    request
  );

  // Fetch tag list
  const tagList = useTagList(project_id, request);

  const handleMetaChange = (e, setMeta) => {
    setMeta((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updateMeta = useCallback(
    async (metaKey, metaData, fetchMeta) => {
      setIsUpdating(metaKey);
      try {
        const formData = new FormData();
        formData.append("key", metaKey);
        formData.append("value_type", "JSON");
        formData.append("value", JSON.stringify(metaData));
        await request({
          method: "post",
          url: `projects/${project_id}/data`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success(`${metaKey.replace("meta_", "")} page meta updated.`);
        fetchMeta();
      } catch (err) {
        toast.error(
          err.response?.data?.message ||
            "Operation could not be performed, some error occurred."
        );
      }
      setIsUpdating("");
    },
    [project_id, request]
  );

  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="p-8 h-[calc(100vh-6rem)] overflow-y-scroll flex flex-col">
      <Toaster />
      <div className="w-full">
        <h2 className="font-bold">Search Engine Optimization (SEO)</h2>
        <p className="text-gray-500">
          Optimize this site for search engines so more people can find it
          online.
        </p>
      </div>

      <div className="grid grid-cols-projectSeo gap-10 mt-7 w-full relative">
        <div className="grid grid-cols-1 gap-6">
          <section id="robots">
            <Robots />
          </section>

          <MetaSection
            sectionId="meta_home"
            label="Meta Home Page"
            metaData={metaHome}
            onMetaChange={(e) => handleMetaChange(e, setMetaHome)}
            onUpdateMeta={() => updateMeta("meta_home", metaHome, getMetaHome)}
            isUpdating={isUpdating === "meta_home"}
          />
          <MetaSection
            sectionId="meta_about"
            label="Meta About Page"
            metaData={metaAbout}
            onMetaChange={(e) => handleMetaChange(e, setMetaAbout)}
            onUpdateMeta={() =>
              updateMeta("meta_about", metaAbout, getMetaAbout)
            }
            isUpdating={isUpdating === "meta_about"}
          />
          <MetaSection
            sectionId="meta_contact"
            label="Meta Contact Page"
            metaData={metaContact}
            onMetaChange={(e) => handleMetaChange(e, setMetaContact)}
            onUpdateMeta={() =>
              updateMeta("meta_contact", metaContact, getMetaContact)
            }
            isUpdating={isUpdating === "meta_contact"}
          />
          <MetaSection
            sectionId="meta_privacy"
            label="Meta Privacy Policy"
            metaData={metaPrivacy}
            onMetaChange={(e) => handleMetaChange(e, setMetaPrivacy)}
            onUpdateMeta={() =>
              updateMeta("meta_privacy", metaPrivacy, getMetaPrivacy)
            }
            isUpdating={isUpdating === "meta_privacy"}
          />
          <MetaSection
            sectionId="meta_terms"
            label="Meta Term & Conditions"
            metaData={metaTerms}
            onMetaChange={(e) => handleMetaChange(e, setMetaTerms)}
            onUpdateMeta={() =>
              updateMeta("meta_terms", metaTerms, getMetaTerms)
            }
            isUpdating={isUpdating === "meta_terms"}
          />
          <MetaSection
            sectionId="meta_category"
            label="Meta Category Page"
            metaData={metaCategory}
            onMetaChange={(e) => handleMetaChange(e, setMetaCategory)}
            onUpdateMeta={() =>
              updateMeta("meta_category", metaCategory, getMetaCategory)
            }
            isUpdating={isUpdating === "meta_category"}
          />
          <MetaSection
            sectionId="meta_tags"
            label="Meta Tags Page"
            metaData={metaTags}
            onMetaChange={(e) => handleMetaChange(e, setMetaTags)}
            onUpdateMeta={() => updateMeta("meta_tags", metaTags, getMetaTags)}
            isUpdating={isUpdating === "meta_tags"}
          />
          <MetaSection
            sectionId="meta_tag"
            label="Tag Detail Page"
            metaData={metaTag}
            onMetaChange={(e) => handleMetaChange(e, setMetaTag)}
            onUpdateMeta={() => updateMeta("meta_tag", metaTag, getMetaTag)}
            isUpdating={isUpdating === "meta_tag"}
          />

          <BlogTags tagList={tagList} />
        </div>

        {/* Navigation Buttons */}
        <div className="flex flex-col top-0 sticky h-fit gap-1">
          <button
            onClick={() => scrollToSection("robots")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "robots" ? "text-primary" : ""
            }`}
          >
            Robots.txt
          </button>
          <button
            onClick={() => scrollToSection("meta_home")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_home" ? "text-primary" : ""
            }`}
          >
            Meta Home Page
          </button>
          <button
            onClick={() => scrollToSection("meta_about")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_about" ? "text-primary" : ""
            }`}
          >
            Meta About Page
          </button>
          <button
            onClick={() => scrollToSection("meta_contact")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_contact" ? "text-primary" : ""
            }`}
          >
            Meta Contact Page
          </button>
          <button
            onClick={() => scrollToSection("meta_privacy")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_privacy" ? "text-primary" : ""
            }`}
          >
            Meta Privacy Policy
          </button>
          <button
            onClick={() => scrollToSection("meta_privacy")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_privacy" ? "text-primary" : ""
            }`}
          >
            Meta Terms & Conditions
          </button>
          <button
            onClick={() => scrollToSection("meta_tags")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_privacy" ? "text-primary" : ""
            }`}
          >
            Meta Category Page
          </button>
          <button
            onClick={() => scrollToSection("meta_tags")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_privacy" ? "text-primary" : ""
            }`}
          >
            Meta Tags Page
          </button>
          <button
            onClick={() => scrollToSection("meta_tag")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "meta_tag" ? "text-primary" : ""
            }`}
          >
            Tag Detail Page
          </button>
          <button
            onClick={() => scrollToSection("blogTags")}
            className={`text-left hover:underline hover:transition-all ${
              activeSection === "blogTags" ? "text-primary" : ""
            }`}
          >
            Blog Tags
          </button>
        </div>
      </div>
    </div>
  );
}
