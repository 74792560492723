import {
  Check,
  Edit,
  FileWarning,
  Grip,
  LoaderCircle,
  Trash,
  X,
} from "lucide-react";
import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import toast from "react-hot-toast";
import SwitchToggle from "../../../../components/common/SwitchToggle";
import usePermissions from "../../../../utils/userPermission";

const ItemType = "WIDGET";

export default function DraggableWidget({
  index,
  layout,
  widget,
  setLayout,
  pageIndex,
  moveWidget,
  updateSectionsOnServer,
}) {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveWidget(pageIndex, dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { type: ItemType, index },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      updateWidgetsOrder();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const updateWidgetsOrder = async () => {
    try {
      await updateSectionsOnServer(layout, {
        loading: "Updating widget position...",
        success: `${layout[pageIndex].widgets[index].widget} position changed`,
        error: "Failed to update widget position.",
      });
    } catch (error) {
      toast.error("Failed to update widget position.");
    }
  };

  const [deleteIndex, setDeleteIndex] = useState(null);
  const deleteRef = useRef({});

  const handleDelete = (index) => {
    setDeleteIndex(index);
  };

  const [action, setAction] = useState(false);
  const removeWidget = async (pageIndex, index) => {
    setAction(true);
    const updatedWidgets = layout.map((page, i) =>
      i === pageIndex
        ? { ...page, widgets: page.widgets.filter((_, j) => j !== index) }
        : page
    );
    setLayout(updatedWidgets);
    await updateSectionsOnServer(updatedWidgets, {
      loading: "Deleting widget...",
      success: "Widget removed",
      error: "Failed to delete widget.",
    });
    setDeleteIndex(null);
    setAction(false);
  };

  const [editIndex, setEditIndex] = useState(null);
  const [editInput, setEditInput] = useState("");
  const startEditing = (index) => {
    setEditIndex(index);
    setEditInput(layout[pageIndex].widgets[index].name);
  };

  const cancelEditing = () => {
    setEditIndex(null);
    setEditInput("");
  };

  const saveEdit = async (pageIndex, index) => {
    if (editInput.trim() !== "") {
      const updatedLayout = layout.map((page, i) =>
        i === pageIndex
          ? {
              ...page,
              widgets: page.widgets.map((widget, j) =>
                j === index ? { ...widget, name: editInput.trim() } : widget
              ),
            }
          : page
      );
      setLayout(updatedLayout);
      setEditIndex(null);
      setEditInput("");
      await updateSectionsOnServer(updatedLayout, {
        loading: "Updating widget...",
        success: "Widget updated",
        error: "Failed to update widget.",
      });
    } else {
      toast.error("Widget name cannot be empty");
    }
  };

  const handleEditKeyDown = (e, pageIndex, index) => {
    if (e.key === "Enter" && editInput.trim() !== "") {
      saveEdit(pageIndex, index);
    } else if (e.key === "Escape") {
      cancelEditing();
    }
  };

  const handleEnabled = async (pageIndex, index) => {
    const updatedLayout = layout.map((page, i) =>
      i === pageIndex
        ? {
            ...page,
            widgets: page.widgets.map((widget, j) =>
              j === index ? { ...widget, enable: !widget.enable } : widget
            ),
          }
        : page
    );
    setLayout(updatedLayout);
    await updateSectionsOnServer(updatedLayout, {
      loading: "Updating Widget...",
      success: "Widget updated",
      error: "Failed to update Widget.",
    });
  };

  const { hasPermission } = usePermissions();

  return (
    <div
      title="Drag"
      ref={ref}
      className={`flex items-center p-2 hover:bg-gray-200 border-b dark:border-white/20 gap-0 justify-between ${
        isDragging
          ? "bg-primary text-white shadow-xl shadow-black/30"
          : `${
              editIndex !== index && " dark:text-white dark:hover:bg-gray-900"
            } hover:text-black`
      } `}
    >
      {editIndex === index ? (
        <input
          value={editInput}
          onChange={(e) => setEditInput(e.target.value)}
          onKeyDown={(e) => handleEditKeyDown(e, pageIndex, index)}
          className="inputField py-[1px] px-2 mr-2 rounded-md flex-1"
        />
      ) : (
        <span className="flex items-center gap-2">
          <Grip className="w-4 h-4 cursor-move text-gray-400 hover:text-primary transition-all" />
          {widget.name}
        </span>
      )}
      {editIndex === index ? (
        <div className="flex items-center justify-end gap-1">
          <button
            onClick={() => saveEdit(pageIndex, index)}
            className="btnPrimary gap-1 bg-secondary p-1"
          >
            <Check className="h-4 w-4 saveIcon" />
          </button>
          <button
            onClick={cancelEditing}
            className="btnPrimary gap-1 bg-red-500 p-1"
          >
            <X className="h-4 w-4 cancelIcon" />
          </button>
        </div>
      ) : (
        <div className="flex items-center justify-end gap-1">
          {hasPermission("disable widget") && (
            <SwitchToggle
              enabled={widget.enable}
              handleEnabled={() => handleEnabled(pageIndex, index)}
            />
          )}
          {hasPermission("edit widget") && (
            <button
              onClick={() => startEditing(index)}
              className="focus:outline-none"
            >
              <Edit className="h-6 w-4 editIcon" />
            </button>
          )}
          {hasPermission("delete widget") && (
            <DeleteAction
              index={index}
              action={action}
              deleteIndex={deleteIndex}
              setDeleteIndex={setDeleteIndex}
              removeWidget={() => removeWidget(pageIndex, index)}
              ref={deleteRef}
              handleDelete={handleDelete}
            />
          )}
        </div>
      )}
    </div>
  );
}

const DeleteAction = React.forwardRef(
  (
    { index, deleteIndex, setDeleteIndex, removeWidget, handleDelete, action },
    ref
  ) => (
    <div className="relative">
      <Trash onClick={() => handleDelete(index)} className="deleteIcon" />
      {deleteIndex === index && (
        <div
          ref={(el) => (ref.current[index] = el)}
          className="fixed inset-0 flex items-center justify-center z-50 bg-gray-400 dark:bg-black/50 bg-opacity-75 transition-opacity"
        >
          <div className="bg-white p-6 rounded shadow-xl shadow-white/5">
            <div className="flex items-center text-primary gap-2 mb-2">
              <FileWarning className="w-5 h-5" />
              <h2 className="text-lg font-bold">Delete Confirmation</h2>
            </div>
            <p className="mb-6">Are you sure you want to delete this widget?</p>
            <div className="flex items-center justify-end mt-4">
              <button onClick={() => setDeleteIndex(null)} className="px-6">
                Cancel
              </button>
              <button onClick={removeWidget} className="btnPrimary bg-red-500">
                {action ? (
                  <LoaderCircle className="w-4 h-4 animate-spin" />
                ) : (
                  <Trash className="w-4 h-4" />
                )}
                {action ? <p>Deleting</p> : <p>Delete Widget</p>}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
);
