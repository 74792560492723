import React from "react";
import { Toaster } from "react-hot-toast";
import { ArticleProvider } from "./ArticleContext";
import ArticlesList from "./ArticlesList";
import usePermissions from "../../../../utils/userPermission";

export default function Articles() {
  const { hasPermission } = usePermissions();

  return (
    <ArticleProvider>
      <div className="h-[calc(100vh-6rem)] overflow-y-scroll px-8 py-6">
        <Toaster position="top-center" reverseOrder={false} />
        {hasPermission("View Project Articles") && <ArticlesList />}
      </div>
    </ArticleProvider>
  );
}
