import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import useApi from "../../utils/useApi";
import { AudioWaveform, Edit, PlusCircle, Trash } from "lucide-react";
import { DataTable, InputField } from "../../components";
import usePermissions from "../../utils/userPermission";
import toast, { Toaster } from "react-hot-toast";

export default function BuyType() {
  const [buyType, setBuyType] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const { isLoading, error, request } = useApi();
  const dropdownRef = useRef(null);
  const deleteRef = useRef({});
  const updateRef = useRef({});

  const getBuyType = async () => {
    try {
      const data = await request({
        method: "get",
        url: "masters/buy_type",
      });
      setBuyType(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getBuyType();
  }, []);

  const addBuyType = async (e) => {
    e.preventDefault();
    let value = e.currentTarget.input.value;
    try {
      const res = await request({
        method: "post",
        url: "masters/buy_type",
        data: { name: value },
      });
      if (res.status) {
        updateRecord(res.data);
        toast.success(`${value} buy type added.`);
        setDropdown(false);
      } else {
        toast.error(
          error || res?.message || `${value} type coludn't be added.`
        );
      }
    } catch (err) {
      console.log("🚀 ~ addBuyType ~ err:", err);
      toast.error(
        err?.response?.data?.message || `${value} type coludn't be added.`
      );
      console.error(err);
    }
  };

  const [deleteType, setDelete] = useState(null);
  const deleteBuyType = async (type) => {
    try {
      const res = await request({
        method: "delete",
        url: `masters/buy_type/${type._id}`,
      });
      if (res.status) {
        updateRecord(res.data);
        toast.success(`${type.name} buy type removed.`);
        setDelete(null);
      } else {
        toast.error(error || `${type.name} type coludn't be removed.`);
      }
    } catch (err) {
      toast.error(error || `${type.name} type coludn't be removed.`);
      console.error(err);
    }
  };
  const updateRecord = (newRecord) => {
    const newData = [...buyType];
    const index = newData.findIndex((item) => item._id === newRecord._id);
    if (index !== -1 && !newRecord.deleted) {
      newData[index] = newRecord;
    } else if (index !== -1 && newRecord.deleted) {
      newData.splice(index, 1);
    } else {
      newData.push(newRecord);
    }
    setBuyType(newData);
  };

  const [updateIndex, setUpdate] = useState(null);
  const updateBuyType = async (e, type) => {
    e.preventDefault();
    const newValue = e.target.elements.update.value;
    try {
      const res = await request({
        method: "put",
        url: `masters/buy_type/${type._id}`,
        data: { name: newValue },
      });
      if (res.status) {
        updateRecord(res.data);
        toast.success(`${type.name} buy type updated.`);
        setUpdate(null);
      } else {
        toast.error(
          error || res?.message || `${type.name} type coludn't be updated.`
        );
      }
      // getBuyType();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || `${type.name} type coludn't be updated.`
      );
      console.error(err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setDelete(null);
        }
      });
      Object.entries(updateRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setUpdate(null);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { hasPermission } = usePermissions();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const form = updateRef.current[updateIndex];
        if (form) {
          form.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [updateIndex]);

  return (
    hasPermission("Buy Type") && (
      <div>
        <Toaster />

        <DataTable
          searchBox
          title={
            <span className="flex items-center gap-2">
              <AudioWaveform className="w-6 h-6" />
              Buy Type
            </span>
          }
          isLoading={isLoading}
          heads={[
            "Sr#",
            ...(hasPermission("Edit Buy Type") ||
            hasPermission("Delete Buy Type")
              ? ["Actions"]
              : []),
            "Name",
            "Created At",
            "Updated At",
          ]}
          items={buyType?.map((type, index) => {
            const actions = (
              <div className="flex items-center gap-2">
                {hasPermission("Delete Buy Type") && (
                  <div className="relative">
                    <Trash
                      onClick={() => setDelete(index)}
                      className="deleteIcon"
                    />
                    {deleteType === index && (
                      <div
                        ref={(el) => (deleteRef.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
                      >
                        <p className="font-medium">
                          Please confirm to delete {type.name} buy type
                        </p>
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setDelete(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => deleteBuyType(type)}
                            className="btnPrimary bg-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {hasPermission("Edit Buy Type") && (
                  <div className="relative">
                    <Edit
                      onClick={() => setUpdate(index)}
                      className="editIcon"
                    />
                    {updateIndex === index && (
                      <form
                        ref={(el) => (updateRef.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-xl border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50 w-80"
                        onSubmit={(e) => updateBuyType(e, type)}
                      >
                        <InputField
                          name="update"
                          label="Name"
                          defaultValue={type.name}
                        />
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setUpdate(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btnPrimary bg-black">
                            Update
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>
            );

            const item = {
              serial: index + 1,
              ...(hasPermission("Edit Buy Type") ||
              hasPermission("Delete Buy Type")
                ? { actions }
                : {}),
              name: type.name,
              createdAt: dayjs(type.createdAt)?.format(
                "ddd, MMM D, YYYY h:mm A"
              ),
              updatedAt: dayjs(type.updatedAt)?.format(
                "ddd, MMM D, YYYY h:mm A"
              ),
            };

            return item;
          })}
          extras={
            hasPermission("Add Buy Type") && (
              <div className="relative text-black dark:text-white/80">
                {dropdown && (
                  <form
                    onSubmit={addBuyType}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
                  >
                    <InputField name="input" label="Name" />
                    <div className="flex items-center justify-end mt-3">
                      <button type="submit" className="btnPrimary bg-black">
                        Add
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdown(!dropdown)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add Buy Type
                </button>
              </div>
            )
          }
        />
      </div>
    )
  );
}
