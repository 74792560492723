import React from "react";
import { ProjectProvider } from "./ProjectContext";
import Project from "./Project";

export default function ManageProject({ children }) {
  return (
    <ProjectProvider>
      <Project children={children} />
    </ProjectProvider>
  );
}
