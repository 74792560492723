import React, { useEffect, useRef, useState } from "react";
import { useTemplateContext } from "../TemplateContext";
import useApi from "../../../../utils/useApi";
import { InputField } from "../../../../components";
import toast from "react-hot-toast";
import { Edit, Trash, Check, X } from "lucide-react";
import usePermissions from "../../../../utils/userPermission";

export default function Categories() {
  const { request } = useApi();
  const { industry_id, template_id } = useTemplateContext();
  const [categories, setCategories] = useState([]);
  const [input, setInput] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editInput, setEditInput] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(null);
  const deleteRef = useRef({});

  useEffect(() => {
    const getCategories = async () => {
      if (!template_id || !industry_id) return;
      try {
        const res = await request({
          method: "get",
          url: `industries/${industry_id}/templates/${template_id}/data/categories`,
        });
        setCategories(res?.data?.[0]?.value || []);
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };

    getCategories();
  }, [template_id, industry_id, request]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) setDeleteIndex(null);
      });
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updateCategoriesOnServer = async (updatedCategories) => {
    try {
      const formData = new FormData();
      formData.append("key", "categories");
      formData.append("value_type", "JSON");
      formData.append("value", JSON.stringify(updatedCategories));
      await request({
        method: "post",
        url: `industries/${industry_id}/templates/${template_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setCategories(updatedCategories);
      toast.success("Categories updated successfully");
    } catch (error) {
      toast.error("Failed to update categories");
    }
  };

  const addCategory = async (e) => {
    e.preventDefault();
    if (!categories.includes(input.trim())) {
      const updatedCategories = [...categories, input.trim()];
      setCategories(updatedCategories);
      setInput("");
      await updateCategoriesOnServer(updatedCategories);
    } else {
      toast.error("Category already exists");
    }
  };

  const removeCategory = async (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
    await updateCategoriesOnServer(updatedCategories);
    setDeleteIndex(null);
  };

  const startEditing = (index) => {
    setEditIndex(index);
    setEditInput(categories[index]);
  };

  const cancelEditing = () => {
    setEditIndex(null);
    setEditInput("");
  };

  const saveEdit = async (index) => {
    if (editInput.trim() !== "") {
      const updatedCategories = categories.map((category, i) =>
        i === index ? editInput.trim() : category
      );
      setCategories(updatedCategories);
      setEditIndex(null);
      setEditInput("");
      await updateCategoriesOnServer(updatedCategories);
    } else {
      toast.error("Category name cannot be empty");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim() !== "") {
      addCategory(e);
    }
  };

  const handleEditKeyDown = (e, index) => {
    if (e.key === "Enter" && editInput.trim() !== "") {
      saveEdit(index);
    } else if (e.key === "Escape") {
      cancelEditing();
    }
  };

  const { hasPermission } = usePermissions();

  return (
    <div className="py-6 px-8 flex flex-col items-center">
      <h3 className="mb-3">Article Categories</h3>
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-lg w-full">
        {hasPermission("Add Article Category") && (
          <div className="flex items-end gap-2">
            <InputField
              label="Category"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Add a category and press Enter"
            />
            <button onClick={addCategory} className="btnPrimary text-base">
              Add
            </button>
          </div>
        )}
        <div className="flex flex-col mt-5 gap-2">
          {categories?.map((category, index) => (
            <div
              key={index}
              className={`flex items-center justify-between dark:border-white/20 ${
                editIndex === index ? "py-2" : "border-b"
              }`}
            >
              {editIndex === index ? (
                <input
                  value={editInput}
                  onChange={(e) => setEditInput(e.target.value)}
                  onKeyDown={(e) => handleEditKeyDown(e, index)}
                  className="inputField py-[1px] mr-2 rounded-md"
                />
              ) : (
                <span>{category}</span>
              )}
              <div className="flex items-center justify-end gap-2">
                {editIndex === index ? (
                  <>
                    <button
                      onClick={() => saveEdit(index)}
                      className="btnPrimary py-[1px] gap-1 bg-secondary px-2"
                    >
                      <Check className="h-6 w-4 saveIcon" />
                      Update
                    </button>
                    <button
                      onClick={cancelEditing}
                      className="btnPrimary py-[1px] gap-1 bg-red-500 px-2"
                    >
                      <X className="h-6 w-4 cancelIcon" />
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    {hasPermission("Edit Article Category") && (
                      <button
                        onClick={() => startEditing(index)}
                        className="ml-2 focus:outline-none"
                      >
                        <Edit className="h-6 w-4 editIcon" />
                      </button>
                    )}
                    {hasPermission("delete article category") && (
                      <DeleteAction
                        index={index}
                        deleteIndex={deleteIndex}
                        setDeleteIndex={setDeleteIndex}
                        removeCategory={removeCategory}
                        ref={deleteRef}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const DeleteAction = React.forwardRef(
  ({ index, deleteIndex, setDeleteIndex, removeCategory }, ref) => (
    <div className="relative">
      <Trash onClick={() => setDeleteIndex(index)} className="deleteIcon" />
      {deleteIndex === index && (
        <div
          ref={(el) => (ref.current[index] = el)}
          className="p-5 w-72 absolute top-0 right-0 mr-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
        >
          <p className="font-medium">Please confirm to delete category</p>
          <div className="flex items-center justify-end mt-4">
            <button onClick={() => setDeleteIndex(null)} className="px-6">
              Cancel
            </button>
            <button
              onClick={() => removeCategory(index)}
              className="btnPrimary bg-red-500"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  )
);
