import React from "react";
import { useProjectContext } from "../ProjectContext";
import { Skeleton } from "../../../../components";
import usePermissions from "../../../../utils/userPermission";

export default function ProjectPreview() {
  const { projectToManage, isPhoneView, iframeKey, iframeURL } =
    useProjectContext();
  const { hasPermission } = usePermissions();

  return (
    <div className="project-preview-container flex flex-col items-center pt-7 pl-8 pr-7">
      {/* {iframeURL} */}
      {!projectToManage?.industry_template_id?.url ? (
        <Skeleton />
      ) : (
        hasPermission("project preview") && (
          <div
            className={
              isPhoneView
                ? "bg-white rounded-xl border-[6px] border-gray-400 overflow-hidden shadow-2xl"
                : "w-full"
            }
          >
            <div
              className={`project-preview-wrapper overflow-hidden ${
                isPhoneView ? "w-[330px] -mr-[14px] " : "w-full"
              }`}
              style={{
                height: isPhoneView ? "680px" : "calc(100vh - 7.8rem)",
              }}
            >
              <iframe
                key={iframeKey}
                title="Website Preview"
                src={iframeURL}
                className="project-preview-frame w-full h-full"
                style={{ border: "none" }}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
}
