import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { InputField } from "../../../../components";
import { Loader, Search, AlertCircle } from "lucide-react";

const MapGenerator = ({ onUpdateMapDetails, mapDetails }) => {
  const handleInputChange = (e) => {
    onUpdateMapDetails({ location: e.target.value });
  };

  const handleSearch = async () => {
    onUpdateMapDetails({ loading: true, error: "" });

    try {
      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${mapDetails.location}&key=${process.env.REACT_APP_MAP_API_KEY}`
      );

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        const mapUrl = `https://www.google.com/maps?q=${lat},${lng}`;

        onUpdateMapDetails({
          center: { lat, lng },
          mapUrl,
          loading: false,
        });

        // toast.success("Location found and updated successfully!");
      } else {
        throw new Error("Location not found");
      }
    } catch (error) {
      onUpdateMapDetails({ loading: false, error: error.message });
      toast.error(error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  return (
    <div className="flex flex-col">
      <Toaster />
      <div className="flex items-end w-full gap-2">
        <InputField
          label="Your Business Location"
          value={mapDetails?.location}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter location"
        />
        <button
          onClick={handleSearch}
          className="btnPrimary text-base"
          disabled={mapDetails?.loading}
        >
          {mapDetails?.loading ? (
            <Loader className="animate-spin mr-2 h-5 w-5 text-white" />
          ) : (
            <>
              <Search className="mr-2 h-5 w-5" />
              Search
            </>
          )}
        </button>
      </div>

      {mapDetails?.error && (
        <p className="text-red-500 mb-4 flex items-center">
          <AlertCircle className="mr-2 h-5 w-5" /> {mapDetails?.error}
        </p>
      )}

      {mapDetails?.mapUrl && (
        <div>
          {/* <p className="mb-4">
            Map URL:{" "}
            <a
              href={mapDetails?.mapUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              {mapDetails?.mapUrl}
            </a>
          </p> */}
          <h4 className="mb-2 mt-5">Your Location</h4>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
            <GoogleMap
              mapContainerClassName="h-[500px] w-full max-w-4xl rouded-md"
              center={mapDetails?.center}
              zoom={12}
            >
              <Marker position={mapDetails?.center} />
            </GoogleMap>
          </LoadScript>
        </div>
      )}
    </div>
  );
};

export default MapGenerator;
