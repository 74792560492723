import React, { useEffect, useState } from "react";
import useApi from "../../../../utils/useApi";
import { useProjectContext } from "../ProjectContext";
import toast from "react-hot-toast";
import { LoaderCircle, Save } from "lucide-react";
import { TextArea } from "../../../../components";

export default function Robots() {
  const { request } = useApi();
  const [robotxt, setRobotxt] = useState("loading..");
  const { project_id } = useProjectContext();

  // const [enabled, setEnabled] = useState(false);
  // const [isEnabling, setIsEnabling] = useState(false);
  // const handleEnabled = () => {
  //   const newEnabled = !enabled;
  //   setEnabled(newEnabled);
  //   setRobotxt(newEnabled ? "true" : "false");
  //   updateRobotxt(newEnabled);
  // };

  const getRobotxt = async () => {
    try {
      const res = await request({
        method: "get",
        url: `projects/${project_id}/data/${"robotxt"}`,
      });
      // if (res.data[0].value === "true") {
      //   setEnabled(true);
      // } else if (res.data[0].value === "false") {
      //   setEnabled(false);
      // }
      setRobotxt(res.data[0].value);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getRobotxt();
  }, [project_id]);

  const [isUpdatingRobot, setIsUpdatingRobot] = useState(false);
  const updateRobotxt = async (newEnabled) => {
    setIsUpdatingRobot(true);

    try {
      const formData = new FormData();
      formData.append("key", "robotxt");
      //   formData.append("value", newEnabled ? "true" : "false");
      formData.append("value", robotxt);
      await request({
        method: "post",
        url: `projects/${project_id}/data`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(`Robots.txt Updated`);
      //   toast.success(`Robots.txt ${newEnabled ? "enabled" : "disabled"}.`);
      getRobotxt();
    } catch (err) {
      toast.error("Operation could not be performed, some error occurred.");
    }

    setIsUpdatingRobot(false);
  };

  return (
    <div id="robots" className="px-7 py-6 bg-white dark:bg-gray-800 rounded-lg">
      <div className="flex items-center justify-between w-full">
        <h4 className="font-bold">Robots.txt</h4>
        <div className="flex items-center gap-5">
          <button
            onClick={updateRobotxt}
            className="btnPrimary bg-secondary py-1 px-3"
          >
            {isUpdatingRobot ? (
              <LoaderCircle className="w-4 animate-spin" />
            ) : (
              <Save className="w-4" />
            )}
            {isUpdatingRobot ? <p>Updating</p> : <p>Save & Update</p>}
          </button>
          {/* <span className="flex items-center gap-2">
          {isEnabling ? (
            <p className="text-gray-400 text-sm">
              {enabled ? "Enabling" : "Disabling"}
            </p>
          ) : (
            <p className="text-gray-400 text-sm">
              {enabled ? "Enabled" : "Disabled"}
            </p>
          )}
          {isEnabling ? (
            <LoaderCircle className="w-5 text-primary animate-spin" />
          ) : (
            <SwitchToggle
              enabled={enabled}
              handleEnabled={handleEnabled}
            />
          )}
        </span> */}
        </div>
      </div>

      <TextArea
        className="mt-3"
        label="Your current robots.txt file"
        inputStyle="h-28"
        value={robotxt}
        onChange={(e) => setRobotxt(e.target.value)}
      />
    </div>
  );
}
