import React, { createContext, useContext, useEffect, useState } from "react";
import useApi from "../../../utils/useApi";
import { useParams } from "react-router-dom";
import { LaptopMinimalIcon, Smartphone } from "lucide-react";

const TemplateContext = createContext();

export const TemplateProvider = ({ children }) => {
  const { request, isLoading } = useApi();
  const [loading, setLoading] = useState(true);
  const [isPhoneView, setIsPhoneView] = useState(false);
  const { template } = useParams();

  const currentUrl = window.location.href;
  const params = new URLSearchParams(new URL(currentUrl).search);
  const currentSection = params.get("section");

  // Templates List
  const [templates, setTemplates] = useState([]);
  const getTemplates = async () => {
    try {
      const data = await request({
        method: "get",
        url: `industries/All/templates`,
      });
      setTemplates(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getTemplates();
    setLoading(false);
  }, []);

  // Find Template
  const [temp, setTemp] = useState({});
  const findTemplate = () => {
    const pageTemplate = templates.find(
      (item) =>
        item.template_name.toLowerCase() === template.replaceAll("-", " ")
    );
    setTemp(pageTemplate);
  };
  useEffect(() => {
    if (templates?.length > 0) {
      findTemplate();
    }
  }, [templates]);

  const ViewSwitch = () => (
    <div className="flex items-center justify-center border-primary dark:border-white/40 border rounded-md p-[2px]">
      <button
        className={`h-7 px-3 flex items-center justify-center transition-all rounded ${
          !isPhoneView
            ? "bg-primary text-white dark:bg-primary/80"
            : "border-white dark:border-gray-800 hover:border-gray-200 dark:hover:bg-gray-700 hover:bg-gray-200 dark:hover:border-gray-700"
        }`}
      >
        <LaptopMinimalIcon
          className="w-4"
          onClick={() => setIsPhoneView(false)}
        />
      </button>
      <button
        className={`h-7 px-3 flex items-center justify-center transition-all rounded ${
          isPhoneView
            ? "bg-primary text-white dark:bg-primary/80"
            : "border-white dark:border-gray-800 hover:border-gray-200 dark:hover:bg-gray-700 hover:bg-gray-200 dark:hover:border-gray-700"
        }`}
        onClick={() => setIsPhoneView(true)}
      >
        <Smartphone className="w-4" />
      </button>
    </div>
  );

  return (
    <TemplateContext.Provider
      value={{
        isPhoneView,
        setIsPhoneView,
        industry_id: temp?.industry_id?._id,
        template_id: temp?._id,
        loading,
        currentSection,
        ViewSwitch,
        template,
        temp,
        isLoading,
        templates,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => useContext(TemplateContext);
