import React, { useEffect, useRef, useState } from "react";
import { DataTable, InputField } from "../../components";
import useApi from "../../utils/useApi";
import dayjs from "dayjs";
import { CloudCog, PlusCircle, Trash } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";

export default function CDN() {
  const [cloudFlairs, setCloudFlairs] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const { isLoading, request } = useApi();
  const dropdownRef = useRef(null);
  const deleteRef = useRef({});

  const getCloudFlairs = async () => {
    try {
      const data = await request({
        method: "get",
        url: "masters/cloudflair",
      });
      setCloudFlairs(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCloudFlairs();
  }, []);

  const addCloudFlair = async (e) => {
    e.preventDefault();
    let auth_email = e.currentTarget.auth_email.value;
    let auth_key = e.currentTarget.auth_key.value;
    let account_id = e.currentTarget.account_id.value;

    try {
      await request({
        method: "post",
        url: "masters/cloudflair",
        data: { auth_email, auth_key, account_id },
      });
      toast.success(`Cloud flair added.`);
      getCloudFlairs();
      setDropdown(false);
      auth_email = "";
      auth_key = "";
      account_id = "";
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const [deleteInd, setDelete] = useState(null);
  const deleteCloudFlair = async (flair) => {
    try {
      await request({
        method: "delete",
        url: `masters/cloudflair/${flair._id}`,
      });

      toast.success(`Cloud flair removed.`);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
      Object.entries(deleteRef.current).forEach(([key, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setDelete(null);
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { hasPermission } = usePermissions();

  return (
    hasPermission("View CDN") && (
      <div>
        <Toaster />
        <DataTable
          searchBox
          title={
            <span className="flex items-center gap-2">
              <CloudCog className="w-6 h-6" /> CDN
            </span>
          }
          isLoading={isLoading}
          heads={[
            "Sr#",
            hasPermission("Delete CDN") && "Actions",
            "Email",
            "Auth Key",
            "Account Id",
            "Total Domains",
            "Created At",
            "Updated At",
          ]}
          items={cloudFlairs?.map((flair, index) => ({
            serial: index + 1,
            actions: (
              <div className="flex items-center gap-2">
                {hasPermission("Delete CDN") && (
                  <div className="relative">
                    <Trash
                      onClick={() => setDelete(index)}
                      className="w-4 cursor-pointer text-red-400 hover:text-red-600"
                    />
                    {deleteInd === index && (
                      <div
                        ref={(el) => (deleteRef.current[index] = el)}
                        className="p-5 absolute top-0 left-0 ml-5 rounded-md shadow-2xl shadow-black/30 border-2 border-gray-200 dark:border-white/20 bg-white dark:bg-gray-800 z-50"
                      >
                        <p className="font-medium">
                          Please confirm to delete this vlaue.
                        </p>
                        <div className="flex items-center justify-end mt-4">
                          <button
                            onClick={() => setDelete(null)}
                            className="px-6"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => deleteCloudFlair(flair)}
                            className="btnPrimary bg-red-500"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ),
            email: flair.auth_email,
            auth_key: flair.auth_key,
            account_id: flair.account_id,
            total_domains: flair.total_domains,
            createdAt: dayjs(flair.createdAt)?.format(
              "ddd, MMM D, YYYY h:mm A"
            ),
            updatedAt: dayjs(flair.updatedAt)?.format(
              "ddd, MMM D, YYYY h:mm A"
            ),
          }))}
          extras={
            hasPermission("add cdn") && (
              <div className="relative text-black dark:text-white/80">
                {dropdown && (
                  <form
                    onSubmit={addCloudFlair}
                    ref={dropdownRef}
                    className="flex-col w-[300px] z-50 items-center absolute top-0 right-0 mt-10 p-5 bg-white dark:bg-gray-800 rounded-md capitalize shadow-xl"
                  >
                    <InputField
                      label="Auth Email"
                      name="auth_email"
                      placeholder="Enter auth email"
                    />
                    <InputField
                      label="Auth Key"
                      name="auth_key"
                      placeholder="Enter auth key"
                      className="mt-3"
                    />
                    <InputField
                      label="account id"
                      name="account_id"
                      placeholder="Enter auth key"
                      className="mt-3"
                    />
                    <div className="flex items-center justify-end mt-3">
                      <button type="submit" className="btnPrimary bg-black">
                        Add
                      </button>
                    </div>
                  </form>
                )}
                <button
                  onClick={() => setDropdown(!dropdown)}
                  className="btnPrimary px-3"
                >
                  <PlusCircle className="h-4 w-4" /> Add CDN
                </button>
              </div>
            )
          }
        />
      </div>
    )
  );
}
