import React, { useState } from "react";
import { LoaderCircle, Plus, Save, X } from "lucide-react";
import useApi from "../../utils/useApi";
import { InputField } from "../../components";

export default function AddUpdatePermission({
  permission,
  setPermission,
  sub_permissions,
  setSubPermissions,
  getPermissions,
  permission_id,
  setIsLoading,
  onClose,
  toast,
  showAddUpdatePermission,
  modalType,
}) {
  const [subPermission, setSubPermission] = useState("");
  const [isSubPermission, setIsSubPermission] = useState("");
  const [subPermissionValue, setSubPermissionValue] = useState("");
  const [updating, setUpdting] = useState(false);

  const { isLoading, request } = useApi();

  const addNewPermission = async (e) => {
    e.preventDefault();

    setUpdting(true);
    try {
      if (modalType === "edit") {
        await request({
          method: "post",
          url: "permissions",
          data: {
            permission_id: permission_id,
            name: permission,
            sub_permissions: sub_permissions,
          },
        });
        toast.success(`Permissions Updated.`);
      } else {
        await request({
          method: "post",
          url: "permissions",
          data: {
            name: permission,
            sub_permissions: sub_permissions,
          },
        });
        toast.success(`New Permission added.`);
      }

      showAddUpdatePermission(false);
      setSubPermission("");
      setSubPermissionValue("");
      setPermission("");
      getPermissions();
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "Operation could not be performed, some error occurred."
      );
    }
    setUpdting(false);
  };

  const addSubPermission = (e) => {
    e.preventDefault();
    setSubPermissions((prevState) => [
      ...prevState,
      { name: subPermission, sub_permissions: null },
    ]);
    setSubPermission("");
  };

  const addSubPermissions = (e) => {
    e.preventDefault();
    setSubPermissions((prevState) => {
      const updatedState = prevState.map((permission) => {
        if (permission?.name === isSubPermission) {
          // If the name matches, add another object in sub_permissions
          return {
            ...permission,
            sub_permissions: [
              ...(permission.sub_permissions || []), // Ensure sub_permissions is an array
              { name: subPermissionValue, sub_permissions: null },
            ],
          };
        }
        return permission;
      });
      return updatedState;
    });
    setIsSubPermission(null);
    setSubPermissionValue("");
  };

  const removeSubPermission = (index) => {
    setSubPermissions((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };

  const handleRemoveSubPermission = (permissionName, subPermissionToRemove) => {
    setSubPermissions((prevState) => {
      return prevState.map((permission) => {
        if (permission.name === permissionName) {
          // If the permission name matches, filter out the sub-permission to remove
          const updatedSubPermissions = (
            permission.sub_permissions || []
          ).filter(
            (subPermission) => subPermission.name !== subPermissionToRemove
          );
          return {
            ...permission,
            sub_permissions: updatedSubPermissions,
          };
        }
        return permission;
      });
    });
  };

  const handleKeyDown = (e, callback) => {
    if (e.key === "Enter") {
      e.preventDefault();
      callback(e);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h3 className="elementHeading4">
          {modalType === "edit" ? "Edit Permission" : "Add New Permission"}
        </h3>
        <div className="flex items-center justify-end">
          <button onClick={onClose} className="text-red-500 px-5 font-medium">
            Cancel
          </button>
          <button
            onClick={addNewPermission}
            className="btnPrimary bg-green-600 py-1 px-3"
          >
            {updating ? (
              <LoaderCircle className="w-4 animate-spin" />
            ) : (
              <Save className="w-4" />
            )}
            {updating ? <p>Updating Permissions</p> : <p>Save & Update</p>}
          </button>
        </div>
      </div>
      <div className="p-6 rounded-lg bg-white dark:bg-gray-800 dark:border-white/10 border">
        <InputField
          name="permissionName"
          label="Permission Name"
          placeholder="Enter Permission Name"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
        />
        <div className="flex items-end gap-2">
          <InputField
            disabled={!permission}
            name="subPermission"
            label="Sub Permission"
            placeholder="Sub permission"
            className="mt-5 flex-1"
            value={subPermission}
            onChange={(e) => setSubPermission(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, addSubPermission)}
          />
          <button
            disabled={!subPermission}
            className="btnPrimary text-base"
            onClick={(e) => addSubPermission(e)}
          >
            Add
          </button>
        </div>

        <div className="flex flex-col gap-1 mt-3">
          {sub_permissions.map((item, index) => (
            <div className="flex flex-col" key={index}>
              <div className="flex items-center justify-between border-b dark:border-white/20">
                <p>{item.name}</p>
                <div className="flex items-center gap-3">
                  <button
                    className="flex text-xs gap-0.5 cursor-pointer"
                    onClick={() => setIsSubPermission(item.name)}
                  >
                    <Plus className="w-4 h-4 text-primary" />
                    Add
                  </button>
                  <X
                    className="w-4 text-red-400 cursor-pointer"
                    onClick={() => removeSubPermission(index)}
                  />
                </div>
              </div>
              {(item?.sub_permissions || []).map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  className="flex items-center justify-between border-b dark:border-white/20 ml-4"
                >
                  <p>{subItem.name}</p>
                  <div className="flex gap-3">
                    <X
                      className="w-4 text-red-400 cursor-pointer"
                      onClick={() =>
                        handleRemoveSubPermission(item.name, subItem.name)
                      }
                    />
                  </div>
                </div>
              ))}
              {isSubPermission === item.name ? (
                <div className="flex items-end gap-2 ml-4 mt-2">
                  {/* {`Sub Permission inside ${item.name}`} */}
                  <input
                    type="text"
                    name="subPermissionValue"
                    placeholder="Sub permission"
                    value={subPermissionValue}
                    className="inputField py-1 border-2 !border-primary"
                    onChange={(e) => setSubPermissionValue(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, addSubPermissions)}
                  />
                  <button
                    disabled={!subPermissionValue}
                    className="btnPrimary text-base py-1"
                    onClick={addSubPermissions}
                  >
                    Add
                  </button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
