import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Group,
  LayoutTemplate,
  LoaderCircle,
  Minimize,
  Sparkles,
  SquareGanttChart,
  Users,
} from "lucide-react";
import useApi from "../../utils/useApi";
import { Breadcrumbs } from "../../components";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../../utils/userPermission";
import ReportDomainCard from "./ReportDomainCard";

export default function Reports() {
  const { request, isLoading } = useApi();

  const [niches, setNiches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(250);
  const [expandedCards, setExpandedCards] = useState({});
  const [isAllExpanded, setIsAllExpanded] = useState(true);
  const [selectedNiche, setSelectedNiche] = useState(null);

  useEffect(() => {
    const fetchNiches = async () => {
      setLoading(true);
      try {
        const data = await request({
          method: "get",
          url: "masters/niche",
        });
        setNiches(data.data);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };
    fetchNiches();
  }, []);

  const handleNiche = async (e, niche) => {
    e.preventDefault();
    setSelectedNiche(niche);
    try {
      const res = await request({
        method: "post",
        url: "domains/report",
        data: {
          page: "",
          size: "",
          filters: {
            groupOp: "AND",
            rules: [{ field: "niche_id", op: "eq", data: niche._id }],
          },
          sort_field: "",
          sort_by: "",
        },
      });
      setData(res.data);
      setExpandedCards(
        res.data?.reduce((acc, _, index) => ({ ...acc, [index]: true }), {})
      );
      setIsAllExpanded(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const { hasPermission } = usePermissions();

  const handleCollapseAll = useCallback(() => {
    setExpandedCards((prev) =>
      Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );
    setIsAllExpanded(false);
  }, []);

  const handleExpandAll = useCallback(() => {
    setExpandedCards((prev) =>
      Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: true }), {})
    );
    setIsAllExpanded(true);
  }, []);

  const toggleExpandCollapse = useCallback(() => {
    isAllExpanded ? handleCollapseAll() : handleExpandAll();
  }, [isAllExpanded, handleCollapseAll, handleExpandAll]);

  return (
    <div>
      <h4 className="bg-warmGray dark:bg-gray-800 text-white py-2 px-6">
        Reports
      </h4>
      <Toaster />
      <div className="p-6">
        <Breadcrumbs />{" "}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 mt-5">
          {[
            { title: "Projects", to: "/projects", Icon: SquareGanttChart },
            { title: "Project Categories", to: "/categories", Icon: Sparkles },
            { title: "Templates", to: "/templates", Icon: LayoutTemplate },
            { title: "Domains", to: "/domains", Icon: Box },
            { title: "Users", to: "/users", Icon: Users },
            { title: "Merchants", to: "/merchants", Icon: Group },
          ].map((card, index) => (
            <Card key={index} {...card} isLoading={isLoading} />
          ))}
        </div>
        <p className="text-xl mt-10 border-b border-gray-400 font-bold">
          Niches
        </p>
        {loading ? (
          <div className="flex flex-col items-center justify-center p-16 mt-2">
            <LoaderCircle className="h-14 w-14 animate-spin text-primary" />
            <p className="text-center">Loading Niches</p>
          </div>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-3 mt-3 mb-6">
            {niches?.map((item, index) => (
              <NicheCard
                key={index}
                title={item.name}
                isLoading={isLoading}
                totalItems={item.domains?.length}
                onClick={(e) => handleNiche(e, item)}
              />
            ))}
          </div>
        )}
        {selectedNiche && (
          <div className="flex items-end justify-between mt-10 border-b border-gray-400 py-2">
            <p className="text-xl font-bold">
              Domain in {selectedNiche?.name} niche
            </p>
            <button onClick={toggleExpandCollapse} className="btnWhite px-3">
              <Minimize className="w-4 h-4" />
              {isAllExpanded ? "Collapse All" : "Expand All"}
            </button>
          </div>
        )}
        <div className="mt-2">
          {isLoading ? (
            <div className="flex justify-center items-center text-center h-64 flex-col gap-3">
              <LoaderCircle className="animate-spin h-12 w-12 text-primary" />
              Loading Domains..
            </div>
          ) : selectedNiche?.domains?.length === 0 ? (
            <div className="text-center text-gray-600 dark:text-gray-300 mt-36">
              No domains available for {selectedNiche?.name} niche
            </div>
          ) : (
            hasPermission("my domains") &&
            data?.map((item, index) => (
              <ReportDomainCard
                key={index}
                item={item}
                index={
                  pageSize === "all"
                    ? index + 1
                    : (page - 1) * pageSize + index + 1
                }
                isOpen={expandedCards[index]}
                setIsOpen={() =>
                  setExpandedCards((prev) => ({
                    ...prev,
                    [index]: !prev[index],
                  }))
                }
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

function Card({ to, title, Icon, isLoading }) {
  return (
    <Link
      to={to || ""}
      className="rounded-xl flex items-center gap-3 p-4 shadow-xl bg-warmGray dark:bg-gray-800 dark:hover:bg-white hover:bg-white text-white hover:text-black transition-all"
    >
      {Icon && (
        <div className="rounded-full w-10 h-10 bg-secondary flex items-center justify-center text-white">
          <Icon className="w-5 h-5" />
        </div>
      )}
      <p className="font-semibold">{title}</p>
    </Link>
  );
}

function NicheCard({ totalItems, onClick, title }) {
  return (
    <button
      onClick={onClick}
      className="rounded-md flex items-center justify-between gap-3 px-4 py-2 shadow-md bg-white dark:bg-gray-800 hover:text-white hover:bg-warmGray dark:hover:bg-white dark:hover:text-black transition-all"
    >
      <p className="text-sm font-semibold">{title}</p>
      <p className="font-bold text-2xl">{totalItems ? totalItems : 0}</p>
    </button>
  );
}
